import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { List } from 'semantic-ui-react'
import UtilityTime from 'utility/UtilityTime'
import * as actionsGroupChannelsState from 'state/groupChannelsState/actions'
import AvatarWrapperStatus from 'containers/ChatApp/Components/AvatarWrapperStatus'
import client from 'Client'
import config from 'config'
import * as Debug from 'debug';
const debug = Debug('chatcamp:RosterUsers')

class RosterUsers extends Component {

    onUserClick = (id) => {
        client.GroupChannel.create("New Group", [id, this.props.user.get("id")], true ,function(error, newGroupChannel) {
            window.ChatCampUIKit.startChat(newGroupChannel.id, "group")
        })
    }

    render () {
        let roster = []
        let inlineStyleHeightImage ={
            lineHeight: "36px",
            height: "36px",
            position: "relative",
            marginTop: '5px'
        }
        
        this.props.userList.get('roster').map((rosterItem) => {
            let showStatus = true
            if(rosterItem.getIn(['data']) === ""){
                showStatus =  false
            }
            if(rosterItem.getIn(['id']) !== this.props.user.getIn(['id'])){
                roster.push(
                    <List.Item className={"list-users"} key={"roster-key-" + rosterItem.getIn(['id']) } onClick={() => this.onUserClick(rosterItem.getIn(['id']))}>
                        <List.Content className="cc-user-list-time-text" floated='right' verticalAlign='middle'>
                          {rosterItem.getIn(['isOnline'])? config.getTranslation().online : <div>{rosterItem.getIn(['lastSeen']) === 0? "Not Joined":UtilityTime.getTime('3', rosterItem.getIn(['lastSeen'])*1000)}</div>}
                        </List.Content>

                        <List.Content style={inlineStyleHeightImage} floated='left' verticalAlign='middle'>
                            <AvatarWrapperStatus className={"cc-current-profile-avatar-status"} name={rosterItem.getIn(['displayName'])} src = {rosterItem.getIn(['avatarUrl'])} size={36} isOnline={rosterItem.getIn(['isOnline'])}/>
                        </List.Content>

                        {showStatus && <List.Content className="cc-user-list-name cc-user-list-name-with-status" verticalAlign='middle'>
                            <List.Header>{rosterItem.getIn(['displayName'])}</List.Header>
                            <List.Description>{rosterItem.getIn(['data'])}</List.Description>
                        </List.Content>}

                        {!showStatus && <List.Content className="cc-user-list-name" verticalAlign='middle'>
                            <List.Header>{rosterItem.getIn(['displayName'])}</List.Header>
                        </List.Content>}

                    </List.Item>
                )
            }
        })

        return (
            <List selection verticalAlign='middle' relaxed="very" size="medium">
                {roster}
            </List>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actionsGroupChannelsState: bindActionCreators(actionsGroupChannelsState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterUsers)
