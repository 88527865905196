import React, { Component } from 'react'
import ContentLoader from "react-content-loader"
import * as Debug from 'debug';
const debug = Debug('chatcamp:RosterLoader')

class RosterLoader extends Component {

  render () {
    return (
        <div className="cc-roster-loader">
        <ContentLoader
		height={300}
		width={280}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
	>
		<rect x="70" y="15" rx="4" ry="4" width="200" height="6" /> 
		<rect x="70" y="35" rx="3" ry="3" width="120" height="6" /> 
		<circle cx="30" cy="30" r="22" />

        <rect x="70" y="90" rx="4" ry="4" width="200" height="6" /> 
		<rect x="70" y="110" rx="3" ry="3" width="120" height="6" /> 
		<circle cx="30" cy="105" r="22" />

        <rect x="70" y="170" rx="4" ry="4" width="200" height="6" /> 
		<rect x="70" y="190" rx="3" ry="3" width="120" height="6" /> 
		<circle cx="30" cy="185" r="22" />

        <rect x="70" y="250" rx="4" ry="4" width="200" height="6" /> 
		<rect x="70" y="270" rx="3" ry="3" width="120" height="6" /> 
		<circle cx="30" cy="265" r="22" />

	    </ContentLoader>
    </div>
    )
  }

}

export default RosterLoader
