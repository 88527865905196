import {Map} from 'immutable'
import {
	SET_APP_TYPE,
  CHAT_CONNECT_SUCCESS
} from 'state/action-types'


export const initialState = Map({
  appId: null,
  type: ""
})

export function app (state = initialState, action) {
  switch (action.type) {
    case CHAT_CONNECT_SUCCESS:
      return state.set('appId', action.user.appId)
    case SET_APP_TYPE:
      if(action.data.type === "embed") {
        return state
          .set('type', action.data.type)
          .set('channelId', action.data.channelId)
          .set('channelType', action.data.channelType)
      }
      else {
        return state.set('type', action.data.type)
      }
      
    default:
      return state
  }
}
