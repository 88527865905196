import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as uiActions from 'state/ui/actions'
import * as groupChannelsListactions from 'state/groupChannelsList/actions'
import Roster from 'containers/ChatApp/Components/Roster'
import ProductHead from 'containers/ChatApp/Components/ProductHead'
import { Icon } from 'semantic-ui-react'
import * as Debug from 'debug';
const debug = Debug('chatcamp:ProductTabs')

class ProductInbox extends Component {

    onBackClick = () => {
        this.props.uiActions.productListOpen()
        this.props.groupChannelsListactions.resetGroupChannelList()
    }

    render () {
        let widthClass = "chatcamp-list-roster-horizontal"
        return (
            <div className={widthClass}>
                <div className="cc-product-inbox">
                    <Icon className="cc-product-list-back" link name='angle left' onClick={() => this.onBackClick()}/>
                    <ProductHead className="cc-product-inbox-header" />
                </div>    
                <Roster className="chatcamp-list-roster-horizontal-item cc-product-inbox-item" type="inbox"/>
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch),
    groupChannelsListactions: bindActionCreators(groupChannelsListactions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInbox)
