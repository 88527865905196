import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/groupChannelsState/actions'
import * as actionsGroupChannels from 'state/groupChannels/actions'
import * as actionsUserList from 'state/userList/actions'
import * as actionsBlockList from 'state/blockList/actions'
import * as actionsUser from 'state/user/actions'
import * as uiActions from 'state/ui/actions'
import { Icon, Header, Popup, Modal, Form, Label, Dropdown, Button, Message} from 'semantic-ui-react'
import Utility from 'utility/Utility';
import GroupParticipantsList from 'containers/ChatApp/Components/GroupParticipantsList'
import ChatCampIcon from 'containers/ChatApp/Components/ChatCampIcon'
import {ICONS} from 'constants/icons'
import config from 'config'

class WindowHeader extends Component {
  state = {
    groupName: "",
    groupParticipants: [],
    showGroupNameError: false,
    showGroupParticipantsError: false,
    modalOpen: false,
    modalMountNode: ""
  }

  componentDidMount(){
    let node = document.getElementById("ifc-app")
    this.setState({modalMountNode: node})
  }

  findFirstHidden(){
    let first = false;
    this.props.groupChannels.map((window, id) => {
      if(this.props.groupChannelsState.getIn([window.get('id'), "state"]) === "HIDDEN"){
        if(!first){
          first = window.get('id')
        }
      }
    })
    if(!first){
      this.props.openChannels.map((window, id) => {
        if(this.props.groupChannelsState.getIn([window.get('id'), "state"]) === "HIDDEN"){
          if(!first){
            first = window.get('id')
          }
        }
      })
    }
    return first
  }

  handleSettingsClick = () => {
    if(this.props.type === "group"){
      this.props.actionsGroupChannels.syncGroup(this.props.id)
    }
  }
   
  handleItemClick = () => {
    if(this.ifBlocked()){
      this.props.uiActions.userBlockAlertOpen(this.props.id)
    }
    else{
      this.props.fileRef.click()
    }
  }

  openChannel = () => {
    if(this.props.type === "group"){
      this.props.actions.groupChannelsOpen(this.props.id)
    }
    else if(this.props.type === "open"){
      this.props.actions.openChannelsOpen(this.props.id)
    }
  }

  closeChannel = () => {
    if(this.props.type === "group"){
      this.props.actions.groupChannelsClose(this.props.id)
    }
    else if(this.props.type === "open"){
      this.props.actions.openChannelsClose(this.props.id)
    }
    if(Utility.mobileCheck() && (config.getListPanelShow() === false)){
      var el = document.getElementById('cc-app-inner');
      if(el) {
        el.className += ' cc-app-mobile-min';
      }
    }
    if(this.findFirstHidden()){
      if(this.props.groupChannelsState.getIn([this.findFirstHidden(), "type"]) === "group"){
        this.props.actions.groupChannelsOpen(this.findFirstHidden())
      }
      else if(this.props.groupChannelsState.getIn([this.findFirstHidden(), "type"]) === "open"){
        this.props.actions.openChannelsOpen(this.findFirstHidden())
      }
    }
  }
  minimizeChannel = () => {
    if(this.props.type === "group"){
      this.props.actions.groupChannelsMinimize(this.props.id)
    }
    else if(this.props.type === "open"){
      this.props.actions.openChannelsMinimize(this.props.id)
    }
  }

  p2pOtherParticipant = () => {
    let id = this.props.user.get("id")
    let participants = this.props.groupChannels.getIn([this.props.id, 'participants'])
    for(let i in participants){
      if(participants[i].id !== id){
          return participants[i]
      }
    }
  }

  ifPopUpOrInbox = () => {
    if (this.props.app.get("type") === "smartChat" || this.props.app.get("type") === "inbox"){
      return true
    }
    else{
      return false
    }
  }

  ifPopUpOrSideChat = () => {
    if (this.props.app.get("type") === "smartChat" || this.props.app.get("type") === "sideChat"){
      return true
    }
    else{
      return false
    }
  }

  ifPopUp = () => {
    if (this.props.app.get("type") === "smartChat"){
      return true
    }
    else{
      return false
    }
  }

  ifSmallInbox = () => {
    let small = document.getElementsByClassName("cc-inbox-app-small");
    if(small.length > 0){
      return true 
    }
    else{
      return false
    }
  }

  ifP2P = () => {
    if(this.props.groupChannels.getIn([this.props.id, 'participantsCount'], "0") === 2){
      return true
    }
    else{
      return false
    }
  }

  ifBlocked = () => {
   if(this.ifP2P()){
    let other = this.p2pOtherParticipant()
    return other.isBlockedByMe
   }
   else{
     return false
   }
  }

  ifPopUpOpen = () => {
    if (this.props.groupChannelsState.getIn([this.props.id, "state"]) === "OPEN"){
      return true
    }
    else{
      return false
    }
  }

  ifPopUpMinimize = () => {
    if (this.props.groupChannelsState.getIn([this.props.id, "state"]) === "MINIMIZE"){
      return true
    }
    else{
      return false
    }
  }

  checkLoadMore = (e) => {
    let parentNode = ReactDOM.findDOMNode(this.handleContextRef);
    let node = parentNode.querySelector('.menu')
    if(node.clientHeight === (node.scrollHeight - node.scrollTop)) {
      let options = {
        roster: false,
        isOnlineFilter: "ALL"
      };
      this.props.actionsUserList.getUserList(20, this.props.userList.get('participants').last().get("id"), options)
    }
    return false
  }

  handleSubmit = (e) => {
    let { groupParticipants} = this.state
    // if(groupName !== ""){
      if(groupParticipants.length > 0){
        // groupParticipants.push(this.props.user.getIn(['id']))
        this.props.actionsGroupChannels.addParticipants({id: this.props.id,  groupParticipants: groupParticipants})
        this.setState({groupParticipants: []})
        this.setState({modalOpen: false})
      }
      else{
        this.setState({showGroupParticipantsError: true})
      }
    // }
    // else{
    //   this.setState({showGroupNameError: true})
    // }
  }

  leaveGroup = () => {
    this.props.actionsGroupChannels.leaveParticipant({id: this.props.id})
  }

  leaveOpen = () => {
    this.props.actions.openChannelsLeave(this.props.id)
  }

  blockUser = () => {
    let other = this.p2pOtherParticipant()
    this.props.actionsBlockList.blockUser(other.id)
    this.props.groupChannelsState.map((chatWindow, id) => {
      this.props.actionsGroupChannels.syncGroup(id)
    })
  }

  unblockUser = () => {
    let other = this.p2pOtherParticipant()
    this.props.actionsBlockList.unblockUser(other.id)
    this.props.groupChannelsState.map((chatWindow, id) => {
      this.props.actionsGroupChannels.syncGroup(id)
    })
  }

  handleGroupChange = (event) => {
    this.setState({showGroupNameError: false})
    this.setState({ [event.target.name]: event.target.value })
  }

  handleParticipantChange = (event, data) => {
    this.setState({showGroupParticipantsError: false})
    this.setState({ [data.name]: data.value })
  }

  handleClose = (event, data) => {
    this.setState({groupName: "", groupParticipants: []})
  }
  buttonClick= () => {
    this.props.actionsUserList.getUserList(20, undefined, {roster: false})
    this.setState({modalOpen: true})
  }
  handleCancel = () => {
    this.setState({groupName: "", groupParticipants: []})
    this.setState({modalOpen: false})
  }

  render () {

    let triggerComponent = <Header.Subheader as='div'>
      {this.props.groupChannels.getIn([this.props.id, 'participantsCount'], "0")} Participants
    </Header.Subheader>
    let embedParticipants = <Popup
      trigger={triggerComponent}
      hideOnScroll
      position='bottom left'
      on='click'
      className="cc-theme">
      <Popup.Header>{config.getTranslation().participantsListHeader}</Popup.Header>
      <Popup.Content>
        <GroupParticipantsList id={this.props.id} groupChannels={this.props.groupChannels}/>
      </Popup.Content>
    </Popup>

    let triggerComponentPopup = <span className="header-count" >|&nbsp;&nbsp;&nbsp;&nbsp;<Icon name="user outline"/>{this.props.groupChannels.getIn([this.props.id, 'participantsCount'], "0")}</span>
    let participantsCount = <Popup
      trigger={triggerComponentPopup}
      basic
      hideOnScroll
      position='bottom left'
      on='click'
      className="cc-theme">
      <Popup.Header>{ this.props.groupChannels.getIn([this.props.id, 'participantsCount'], "0") + " " + config.getTranslation().participantsListHeader}</Popup.Header>
      <Popup.Content>
        <GroupParticipantsList id={this.props.id} groupChannels={this.props.groupChannels}/>
      </Popup.Content>
    </Popup>
    let source_status = <ChatCampIcon icon={ICONS.STATUS} height="16px" width="16px" viewBox="0 0 50 50"/>
    let source_close =  <ChatCampIcon icon={ICONS.CLOSE} height="24px" width="24px" viewBox="0 0 50 50"/>
    let source_minus =  <ChatCampIcon icon={ICONS.MINIMIZE} height="24px" width="19px" viewBox="0 0 22 24"/>
    let source_max =  <ChatCampIcon icon={ICONS.MAXIMIZE} height="19px" width="19px" viewBox="0 0 24 24"/>
    let source_hash =  <ChatCampIcon icon={ICONS.HASHTAG} height="23px" width="23px" viewBox="0 0 50 50"/>

    let status = <div className= "cc-window-header-hash" >{source_hash}</div>
    let groupChannelName
    if(this.props.type === "open"){
      groupChannelName = this.props.openChannels.getIn([this.props.id, 'name'], "Name")
    }
    else if(this.props.type === "group"){
      groupChannelName = this.props.groupChannels.getIn([this.props.id, 'name'], "Name")
    }

    if((this.ifPopUpOrInbox() || this.ifPopUpOrSideChat()) && this.ifP2P()){
      let other = this.p2pOtherParticipant()
      if(other){
        groupChannelName = other.displayName
      }
      if(other && other.isOnline){
        status = <div className= "cc-window-header-status cc-online">{source_status}</div>
      }
      else {
        status = <div className= "cc-window-header-status cc-offline">{source_status}</div>
      }

    }

    let options = []
    let { groupParticipants, modalOpen, showGroupParticipantsError } = this.state
    let participantsError = <Message error size={"tiny"}
      header='Atleast one participant must be added to the group'/>
    this.props.userList.get('participants').map((rosterItem) => {
      if(rosterItem.getIn(['id']) !== this.props.user.getIn(['id'])){
          let id = rosterItem.getIn(['id'])
          let name = rosterItem.getIn(['displayName'])
          let participants = this.props.groupChannels.getIn([this.props.id, 'participants'])
          let alreadyMember = false;
          for(let i in participants){
            if(participants[i].id === id){
              alreadyMember = true;
            }
          }
          if(!alreadyMember){
            options.push({key: id, value: id, text: name  })
          }
      }
    })

    return (
      <div className="cc-window-header">
          <div className="cc-chat-window-header">
            <div className="cc-window-header-status-main">
              {status}
            </div>

            <div className="cc-window-header-name">
              <div className="cc-window-header-name-inner">
                <span className={"cc-window-header-name-text"}>{groupChannelName}</span>
                { (this.props.type === "group") && (this.ifPopUpOrInbox() || this.ifPopUpOrSideChat()) && !this.ifP2P() && participantsCount}
                {/* {!this.ifPopUp()  && embedParticipants} */}
                {/* {this.ifPopUp() && !this.ifP2P() && embedParticipants} */}
                {/* {this.ifPopUp() && this.ifP2P() && onlineStatus} */}
              </div>
            </div>
            { (this.ifPopUpOrSideChat() || (!this.ifPopUpOrSideChat() && this.ifSmallInbox())) && <div className={"header-actions header-actions-last"}>

                  <Popup className="headerSettings"
                    trigger={<div onClick={() => {this.closeChannel()}} className= "cc-window-header-close">{source_close}</div>}
                    hideOnScroll
                    position='bottom right'
                    on='hover' className="cc-theme cc-tooltips">
                    <Popup.Content>
                      {config.getTranslation().close}
                    </Popup.Content>
                  </Popup>
            </div>}

            

            <div className={"header-actions"}>
                          <Dropdown onClick={() => {this.handleSettingsClick()}} icon='setting' floating className='icon cc-window-header-settings'>
                            <Dropdown.Menu direction="left" className="left cc-window-header-settings-options cc-theme">
                              {config.getChannelShowAttachFile() && <Dropdown.Item onClick={this.handleItemClick} icon='attach' text={config.getTranslation().attachFile} />}
                              {(this.props.type === "group") && !this.ifP2P() && <Dropdown.Item onClick={this.buttonClick.bind()} icon='add square' text={config.getTranslation().addParticipants} />}
                              {(this.props.type === "group") && !this.ifP2P() &&<Dropdown.Item onClick={this.leaveGroup.bind()} icon='sign out' text={config.getTranslation().leaveChat} />}
                              {(this.props.type === "group") && !this.ifBlocked() && this.ifP2P() &&<Dropdown.Item onClick={this.blockUser.bind()} icon='ban' text={config.getTranslation().blockUser} />}
                              {(this.props.type === "group") && this.ifBlocked() && this.ifP2P() &&<Dropdown.Item onClick={this.unblockUser.bind()} icon='ban' text={config.getTranslation().unblockUser} />}
                              {(this.props.type === "open") &&<Dropdown.Item onClick={this.leaveOpen.bind()} icon='sign out' text={config.getTranslation().leaveChat} />}
                            </Dropdown.Menu>
                          </Dropdown>
                  <Modal mountNode= {this.state.modalMountNode} className="cc-create-group-modal cc-theme" open={modalOpen} size="tiny">
                   <Modal.Header>Add Participants</Modal.Header>
                     <Modal.Content>
                       <Modal.Description>
                         {showGroupParticipantsError && participantsError}
                         <Form>
                           <Form.Field>
                             <Label>Participants</Label>
                             <Dropdown ref={node => this.handleContextRef = node} onScroll={this.checkLoadMore.bind(this)} placeholder='Add Participants' fluid multiple selection options={options} name= 'groupParticipants' value={groupParticipants} onChange={this.handleParticipantChange.bind(this)} />
                           </Form.Field>
                           <br/>
                           <Button.Group>
                             <Form.Button onClick={this.handleCancel.bind()}>
                               Cancel
                             </Form.Button>
                             <Button.Or />
                             <Form.Button primary onClick={this.handleSubmit.bind(this)}>
                               Add
                             </Form.Button>
                           </Button.Group>
                           <br/>
                           <br/>
                         </Form>
                       </Modal.Description>
                     </Modal.Content>
                   </Modal>
            </div>

            { !Utility.mobileCheck() && this.ifPopUp() && this.ifPopUpOpen() && <div className={"header-actions"}>
              <Popup className="headerSettings"
                    trigger={<div onClick={() => {this.minimizeChannel()}} className= "cc-window-header-minus">{source_minus}</div>}
                    hideOnScroll
                    position='bottom right'
                    on='hover' className="cc-theme cc-tooltips">
                <Popup.Content>
                  {config.getTranslation().minimize}
                </Popup.Content>
              </Popup>
            </div>}

            { this.ifPopUp() && (this.ifPopUpMinimize()) && <div className={"header-actions"}>
                  <Popup className="headerSettings"
                    trigger={<div onClick={() => {this.openChannel()}} className= "cc-window-header-max">{source_max}</div>}
                    hideOnScroll
                    position='bottom right'
                    on='hover' className="cc-theme cc-tooltips">
                    <Popup.Content>
                      {config.getTranslation().maximize}
                    </Popup.Content>
                  </Popup>
            </div>}
            
          </div>
    </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch) ,
    actionsGroupChannels: bindActionCreators(actionsGroupChannels, dispatch),
    actionsUserList: bindActionCreators(actionsUserList, dispatch),
    actionsUser: bindActionCreators(actionsUser, dispatch),
    actionsBlockList: bindActionCreators(actionsBlockList, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowHeader)
