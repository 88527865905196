import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form } from 'semantic-ui-react'
import * as actions from 'state/auth/actions'
import client from 'Client'
import * as Debug from 'debug'
import config from 'config'

const debug = Debug('chatcamp:LoginSsoScreen')

class LoginSsoScreen extends Component {
    state = { }
  
    handleSubmit = () => {
        let userId = this.props.user.get("id")
        let displayName = this.props.user.get("displayName")
        this.props.actions.chatConnecting()
    client.connect(userId, config.host, config.port, function(error, user) {
        debug("connected", error, user)
        if(displayName) {
            client.updateUserDisplayName(displayName, function(e, u){
            debug("name updated", e, u)
            })
        }
        })
    }

    render () {
        // let finalClass = "cc-login-screen cc-user-settings"
        // if(this.props.className !== undefined){
        //     finalClass = finalClass + " " + this.props.className
        // }

        return (
            <div className="cc-login-screen">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Button style={{textAlign: "center"}} primary type='submit'>Go Online</Form.Button>
                </Form>
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSsoScreen)
