import Immutable, {Map, OrderedMap} from 'immutable'
import {
    USER_BLOCK_LIST_SUCCESS,
    USER_UNBLOCK_SUCCESS
} from 'state/action-types'


export const initialState = Map({
    roster: OrderedMap()
})

export function blockList (state = initialState, action) {
  let oldUser;
  switch (action.type) {
    case USER_BLOCK_LIST_SUCCESS:
        let channels =  OrderedMap()
        for(let i in action.blockList) {
            let g = OrderedMap(action.blockList[i])
            oldUser = state.getIn([action.blockList[i].id], Immutable.Map())
            g = g.merge(oldUser)
            channels = channels.setIn([action.blockList[i].id], g)
        }

        let channelMap = channels;

        let oldChannels = state.get('roster')
        return state.set('roster', oldChannels.merge(channelMap))
    case USER_UNBLOCK_SUCCESS:
        return state.deleteIn(["roster", action.unblockId])
    default:
      return state
  }
}
