export const CHAT_CONNECTING = 'CHAT_CONNECTING';
export const CHAT_CONNECT_SUCCESS = 'CHAT_CONNECT_SUCCESS';
export const CHAT_STATE_LOADED_SUCCESS = 'CHAT_STATE_LOADED_SUCCESS';
export const CHAT_DISCONNECT_SUCCESS = 'CHAT_DISCONNECT_SUCCESS';
export const CHAT_RECONNECTING = 'CHAT_RECONNECTING';
export const GROUP_CHANNELS_ATTACHMENT_PROGRESS = 'GROUP_CHANNELS_ATTACHMENT_PROGRESS';
export const GROUP_CHANNELS_ATTACHMENT_RESET = 'GROUP_CHANNELS_ATTACHMENT_RESET';
export const GROUP_CHANNELS_GET_ERROR = 'GROUP_CHANNELS_GET_ERROR';
export const GROUP_CHANNELS_GET_SUCCESS = 'GROUP_CHANNELS_GET_SUCCESS';
export const GROUP_CHANNELS_GET_HISTORY_SUCCESS = 'GROUP_CHANNELS_GET_HISTORY_SUCCESS';
export const GROUP_CHANNELS_INVALID_PARTICIPANT = 'GROUP_CHANNELS_INVALID_PARTICIPANT';
export const GROUP_CHANNELS_INVITE_ACCEPTANCE_REQUIRED = 'GROUP_CHANNELS_INVITE_ACCEPTANCE_REQUIRED';
export const GROUP_CHANNELS_INVITE_ACCEPTED_SUCCESS = 'GROUP_CHANNELS_INVITE_ACCEPTED_SUCCESS';
export const GROUP_CHANNELS_MESSAGE_RECEIVED_SUCCESS = 'GROUP_CHANNELS_MESSAGE_RECEIVED_SUCCESS';
export const OPEN_CHANNELS_ATTACHMENT_PROGRESS = 'OPEN_CHANNELS_ATTACHMENT_PROGRESS';
export const OPEN_CHANNELS_ATTACHMENT_RESET = 'OPEN_CHANNELS_ATTACHMENT_RESET';
export const OPEN_CHANNELS_LIST_SUCCESS = 'OPEN_CHANNELS_LIST_SUCCESS';
export const OPEN_CHANNELS_GET_SUCCESS = 'OPEN_CHANNELS_GET_SUCCESS';
export const OPEN_CHANNELS_GET_HISTORY_SUCCESS = 'OPEN_CHANNELS_GET_HISTORY_SUCCESS';
export const OPEN_CHANNELS_REMOVE_HISTORY = 'OPEN_CHANNELS_REMOVE_HISTORY';
export const OPEN_CHANNELS_CREATE = 'OPEN_CHANNELS_CREATE';
export const OPEN_CHANNELS_CLOSE = 'OPEN_CHANNELS_CLOSE';
export const OPEN_CHANNELS_MINIMIZE = 'OPEN_CHANNELS_MINIMIZE';
export const OPEN_CHANNELS_OPEN = 'OPEN_CHANNELS_OPEN';
export const OPEN_CHANNELS_HIDE = 'OPEN_CHANNELS_HIDE';
export const OPEN_CHANNELS_MESSAGE_RECEIVED_SUCCESS = 'OPEN_CHANNELS_MESSAGE_RECEIVED_SUCCESS';
export const SMART_CHAT_CLOSE = 'SMART_CHAT_CLOSE';
export const SMART_CHAT_OPEN = 'SMART_CHAT_OPEN';
export const SMART_CHAT_LIST_MINIMIZE = 'SMART_CHAT_LIST_MINIMIZE';
export const SMART_CHAT_LIST_OPEN = 'SMART_CHAT_LIST_OPEN';
export const SET_APP_TYPE = 'SET_APP_TYPE';
export const GROUP_CHANNELS_CREATE = 'GROUP_CHANNELS_CREATE';
export const GROUP_CHANNELS_CLOSE = 'GROUP_CHANNELS_CLOSE';
export const GROUP_CHANNELS_MINIMIZE = 'GROUP_CHANNELS_MINIMIZE';
export const GROUP_CHANNELS_OPEN = 'GROUP_CHANNELS_OPEN';
export const GROUP_CHANNELS_HIDE = 'GROUP_CHANNELS_HIDE';
export const GROUP_CHANNELS_MY_LIST_SUCCESS = 'GROUP_CHANNELS_MY_LIST_SUCCESS';
export const GROUP_CHANNELS_LIST_SUCCESS = 'GROUP_CHANNELS_LIST_SUCCESS';
export const GROUP_CHANNELS_LEAVE_SUCCESS = 'GROUP_CHANNELS_LEAVE_SUCCESS';
export const GROUP_CHANNELS_LIST_RESET = 'GROUP_CHANNELS_LIST_RESET';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_SETTINGS_OPEN = 'USER_SETTINGS_OPEN';
export const USER_SETTINGS_CLOSE = 'USER_SETTINGS_CLOSE';
export const USER_PUSH_NOTIFICATION_SOUND_UPDATE = 'USER_PUSH_NOTIFICATION_SOUND_UPDATE';
export const USER_LOCAL_TITLE_ALERT_SHOW = 'USER_LOCAL_TITLE_ALERT_SHOW';
export const USER_LOCAL_TITLE_ALERT_HIDE = 'USER_LOCAL_TITLE_ALERT_HIDE';
export const USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE = 'USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE';
export const USER_BLOCK_LIST_OPEN = 'USER_BLOCK_LIST_OPEN';
export const USER_BLOCK_LIST_CLOSE = 'USER_BLOCK_LIST_CLOSE';
export const USER_BLOCK_LIST_SUCCESS = 'USER_BLOCK_LIST_SUCCESS';
export const USER_UNBLOCK_SUCCESS = 'USER_UNBLOCK_SUCCESS';
export const USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE = 'USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE';
export const USER_PUSH_NOTIFICATION_SNOOZE_UPDATE = 'USER_PUSH_NOTIFICATION_SNOOZE_UPDATE';
export const USER_LOCAL_PUSH_NOTIFICATION_ENABLE = 'USER_LOCAL_PUSH_NOTIFICATION_ENABLE';
export const USER_LOCAL_PUSH_NOTIFICATION_DISABLE = 'USER_LOCAL_PUSH_NOTIFICATION_DISABLE';
export const USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE = 'USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE';
export const USER_BLOCK_ALERT_OPEN = 'USER_BLOCK_ALERT_OPEN';
export const USER_BLOCK_ALERT_CLOSE = 'USER_BLOCK_ALERT_CLOSE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const CHAT_SSO_TRUE = 'CHAT_SSO_TRUE';
export const CHAT_SSO_FALSE = 'CHAT_SSO_FALSE';
export const USER_TOTAL_GROUP_CHANNEL_UPDATE = 'USER_TOTAL_GROUP_CHANNEL_UPDATE';
export const GROUP_CHANNEL_CREATE_OPEN = 'GROUP_CHANNEL_CREATE_OPEN';
export const GROUP_CHANNEL_CREATE_CLOSE = 'GROUP_CHANNEL_CREATE_CLOSE';
export const PRODUCT_LIST_OPEN = 'PRODUCT_LIST_OPEN';
export const PRODUCT_LIST_CLOSE = 'PRODUCT_LIST_CLOSE';
export const UPDATE_USER_SEARCH_VALUE = 'UPDATE_USER_SEARCH_VALUE';

