import React, { Component } from 'react'
import Avatar from 'react-avatar';
import { Image } from 'semantic-ui-react'
import {isEqual} from 'lodash'

class AvatarWrapper extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps);
  }

  render () {
    let avatar;
    let size;
    if(this.props.size){
      size = this.props.size
    }
    else{
      size = 36
    }
    let style = {
      width: size + "px",
      height: size + "px",
      display: "initial"
    }
    if(this.props.src){
      avatar = <Image avatar src={this.props.src} className={"cc-avatar-size-" + size} circular/>
    }
    else{
      avatar = <Avatar style={this.props.style} color={this.props.color} name={this.props.name} size={this.props.size || 36} round className={this.props.className}/>
    }
    return (
      <div style={style} className={"cc-avatar-wrapper " + this.props.customClass}>
        {avatar}
      </div>
    )
  }

}

export default AvatarWrapper
