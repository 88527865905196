import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  GROUP_CHANNELS_INVITE_ACCEPTANCE_REQUIRED,
  GROUP_CHANNELS_INVALID_PARTICIPANT
} from 'state/action-types'
import * as actions from 'state/groupChannels/actions'
import * as openChannelsActions from 'state/openChannels/actions'
import * as groupChannelsStateActions from 'state/groupChannelsState/actions'
import {Map} from 'immutable'
import { Segment, Comment, Confirm, Message, Image, Popup, Modal } from 'semantic-ui-react'
import {Loader} from 'react-loaders'
import RosterLoader from 'containers/ChatApp/Components/RosterLoader'
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import ProfileCard from 'containers/ChatApp/Components/ProfileCard'
import SoundNotification from 'containers/ChatApp/Components/SoundNotification'
import TitleAlert from 'containers/ChatApp/Components/TitleAlert'
import DesktopNotification from 'containers/ChatApp/Components/DesktopNotification'
import ReadReceipt from 'containers/ChatApp/Components/ReadReceipt'
import AudioPlayer from 'containers/ChatApp/Components/AudioPlayer'
import UnicodeToImg from 'utility/UnicodeToImg'
import UtilityTime from 'utility/UtilityTime'
import ProcessMessage from 'utility/ProcessMessage';
import client from 'Client'
import config from 'config'
import {isEmpty, isEqual, clone} from 'lodash'
import MessageActionCard from '../MessageActionCard'
import * as Debug from 'debug'
const debug = Debug('chatcamp:WindowContent')


// import ReactList from 'react-list';
// import { Scrollbars } from 'react-custom-scrollbars';
// import './style.css'

class WindowContent extends Component {

  updateNodeInfo = true

  state = {
    cacheMessages : {},
    isLoading: false,
    isHover: false,
    cardState: {"$id": "2",
    "ProductID": 44,
    "Name": "Streaker Fitbit",
    "Code": "SF001",
    "ImageURL": "[\"https://pbs.twimg.com/profile_images/621318447335665664/_cRrRqZZ_400x400.jpg\"]",
    "ShortDescription": "Streaker Fitbit",
    "LongDescription": "Streaker Fitbit",
    "CategoryID": 45,
    "ShippingCost": 0,
    "Status": 1,
    "BrandID": 40}
  }

  handleInviteCancel() {

  }

  handleInviteConfirm() {
    this.props.actions.acceptInvitation(this.props.id)
  }

  handleClubbing = (currentMessage, oldMessage) => {
    //send true if message needs to be clubbed. Also, send color as #FFF to insert dummy white avatar.
    //TODO: Dummy white avatar can be replaced with class
    if(isEmpty(oldMessage)){
      return {info: false, color: ""}
    }
    else if((oldMessage.getIn(['user', 'id']) === currentMessage.getIn(['user', 'id'])) && (oldMessage.getIn(['type']) !== "announcement")){
      if(currentMessage.get('insertedAt') - oldMessage.get('insertedAt') < 300){
        return {info: true, color: "#FFF"}
      }
      else{
        return {info: false, color: ""}
      }
    }
    else{
      return {info: false, color: ""}
    }
  }

  checkLoadMore = (e) => {
    let node = ReactDOM.findDOMNode(this);
    if(node.scrollTop === 0) {
      if(this.props.type === "group") {
        let ref = null
        if(this.props.groupChannels.getIn([this.props.id, 'messages'], []).size > 0){
          ref = this.props.groupChannels.getIn([this.props.id, 'messages'], false).first().id
        }
        this.props.actions.getHistory(this.props.id, ref)
      }
      else if(this.props.type === "open") {
        let ref = null
        if(this.props.openChannels.getIn([this.props.id, 'messages']).size > 0){
          ref = this.props.openChannels.getIn([this.props.id, 'messages'], false).first().id
        }
        this.props.openChannelsActions.getHistory(this.props.id, ref)
      }
    }
    // send read if scrolled to bottom but not when new message is received and scroll was already at bottom.
    if((node.clientHeight === (node.scrollHeight - node.scrollTop)) && (node.clientHeight !== (this.scrollHeight - this.scrollTop)) ) {
      if(this.props.type === "group") {
        this.props.actions.read(this.props.id)
      }
    }
    return false
  }

  checkHover = (e) => {
    // this.overFlowY = window.getComputedStyle(document.body).getPropertyValue("overflow-y")
    // this.position = window.getComputedStyle(document.body).getPropertyValue("position")
    // document.body.style.overflowY='scroll'
    // document.body.style.position = 'fixed'
    this.setState({isHover: true});
  }

  checkHoverLeave = (e) => {
    // document.body.style.overflowY=this.overFlowY
    // document.body.style.position=this.position
    this.setState({isHover: false});
  }

  // shouldComponentUpdate(nextProps) {
  //   return this.props.groupChannels !== nextProps.groupChannels
  // }
  // componentWillMount(){
   
  // }

  shouldComponentUpdate(nextProps, nextState) {
    let change = false;
    if(this.props.type === "group") {
      change = !isEqual(this.props.groupChannels.get(this.props.id, false), nextProps.groupChannels.get(this.props.id, false));
    }
    else {
      change = !isEqual(this.props.openChannels.get(this.props.id, false), nextProps.openChannels.get(this.props.id, false));
    }
    return change;
  }

  componentWillUpdate() {
    if(this.updateNodeInfo) {
      let node = ReactDOM.findDOMNode(this);
      let contentHeight = node.parentNode
      let content = node.parentNode.getElementsByClassName("cc-window-description")[0]
      if(content && contentHeight){
        node.style.height = (contentHeight.clientHeight - 101 - content.clientHeight) + "px";
        let loader = node.getElementsByClassName("cc-roster-loader")[0]
        if(loader){
          loader.style.height = node.style.height
        }
      }
      this.scrollHeight = node.scrollHeight;
      this.scrollTop = node.scrollTop;
    }
    this.updateNodeInfo = true
  }

  componentDidUpdate(prevProps, prevState) {
    let node = ReactDOM.findDOMNode(this);
    if(node) {
      node.scrollTop = this.scrollTop + (node.scrollHeight - this.scrollHeight);
    }
  }

  componentWillMount(){
    let modalMountNode = document.getElementById("ifc-app")
    this.setState({modalMountNode: modalMountNode})
  }

  componentDidMount() {
    if(this.props.type === "group"){
      let isCurrentUserAcceptedParticipant = false;
      let isCurrentUserParticipant = false;
      let that = this
      client.GroupChannel.get(this.props.id, function(error, groupChannel) {
        if(error==null) {
          for (let i in groupChannel.participants) {
            let participant = groupChannel.participants[i]
            if(participant.id === client.user.id) {
              isCurrentUserParticipant = true
              if(participant.status === "accepted") {
                isCurrentUserAcceptedParticipant = true
              }
            }
          }

          if(isCurrentUserParticipant && isCurrentUserAcceptedParticipant) {
            let messages = that.props.groupChannels.getIn([groupChannel.id, "messages"])
            if(!messages){
              that.props.actions.getHistory(groupChannel.id, null)
            }
          }
          else if(isCurrentUserParticipant && !isCurrentUserAcceptedParticipant){
            this.props.groupChannelsStateActions.groupChannelInviteAcceptanceRequired()
          }
          else {
            this.props.groupChannelsStateActions.groupChannelsInvalidParticipant()
          }

        }
        else {
          that.props.groupChannelsStateActions.groupChannelsClose(that.props.id)
          that.props.actions.groupChannelError(that.props.id, error)
        }

      })
    }
    else if(this.props.type === "open"){
      let that = this
      client.OpenChannel.get(this.props.id, function(error, openChannel) {
        if(error==null) {
          openChannel.join(function(error, message) {
            let messages = that.props.openChannels.getIn([openChannel.id, "messages"])
            if(!messages){
              that.props.openChannelsActions.getHistory(openChannel.id, null)                
            }
          })
        }
      });
    }

    if(this.updateNodeInfo) {
      let node = ReactDOM.findDOMNode(this);
      let contentHeight = node.parentNode
      let content = node.parentNode.getElementsByClassName("cc-window-description")[0]
      if(content && contentHeight){
        node.style.height = (contentHeight.clientHeight - 101 - content.clientHeight) + "px";
        let loader = node.getElementsByClassName("cc-roster-loader")[0]
        if(loader){
          loader.style.height = node.style.height
        }
      }
      this.scrollHeight = node.scrollHeight;
      this.scrollTop = node.scrollTop;
    }
    

    this.updateNodeInfo = true

    let node = ReactDOM.findDOMNode(this);
    if(node) {
      node.scrollTop = node.scrollHeight
      this.scrollHeight = node.scrollHeight
      this.scrollTop = node.scrollTop
    }

  }

  ifPopUp = () => {
    if (this.props.app.get("type") === "smartChat" || this.props.app.get("type") === "inbox"){
      return true
    }
    else{
      return false
    }
  }

  render () {
    const contextWindow = document.getElementById("ifc-" + this.props.id)
    const { contextRef, isHover } = this.state

    let messages = [];
    let oldMessage = {}
    let windowContent = "cc-window-content"
    if(!isHover){
      windowContent = windowContent + " cc-scroll-hover"
    }

    if(this.props.groupChannels.getIn([this.props.id, 'errorType'], false) === GROUP_CHANNELS_INVITE_ACCEPTANCE_REQUIRED) {
      messages.push(<Confirm
          className="cc-theme"
          key={"confirm-modal-"+this.props.id}
          mountNode= {this.state.modalMountNode}
          open={true}
          header={'Join ' + this.props.groupChannels.getIn([this.props.id, 'name'])}
          content={'Would you like to join ' + this.props.groupChannels.getIn([this.props.id, 'name']) + '?'}
          onCancel={this.handleInviteCancel.bind(this)}
          onConfirm={this.handleInviteConfirm.bind(this)}
        />)
    }

    if(this.props.groupChannels.getIn([this.props.id, 'errorType'], false) === GROUP_CHANNELS_INVALID_PARTICIPANT) {
      messages.push(
        <Message negative>
          <Message.Header>Unauthorized Access</Message.Header>
          <p>User is not a participant of this group</p>
        </Message>
      )
    }

    if(this.props.groupChannels.getIn([this.props.id, 'errorCode'], "").length>0) {
      messages.push(
        <Message negative>
          <Message.Header>{this.props.groupChannels.getIn([this.props.id, 'errorCode'], "")}</Message.Header>
          <p>{this.props.groupChannels.getIn([this.props.id, 'errorMessage'], "")}</p>
        </Message>
      )
    }
    // Iterate in Messages from props and Display them
    let messageList;
    if(this.props.type === "group"){
      messageList = this.props.groupChannels.getIn([this.props.id, 'messages'], false)
    }
    else if(this.props.type === "open"){
      messageList = this.props.openChannels.getIn([this.props.id, 'messages'], false)
    }

    if(messageList){
    messageList.map((message, key) => {
      message = Map(message)
      //handle message clubbing
      let messageClubbing = this.handleClubbing(message,oldMessage)
      let oldMessageTime;

      //Time Divider Logic
      if(isEmpty(oldMessage)){
        oldMessageTime = (message.get('insertedAt') - 172800)*1000
      }
      else{
        oldMessageTime = oldMessage.get('insertedAt')*1000
      }

      var currentReadableDate = UtilityTime.getReadableDate()
      var messageReadableDate = UtilityTime.getReadableDate(message.get('insertedAt')*1000)
      var oldMessageReadableDate = UtilityTime.getReadableDate(oldMessageTime)
      let timeDivider = false;
      if(currentReadableDate.fullDate ===  messageReadableDate.fullDate){
        if(messageReadableDate.fullDate !== oldMessageReadableDate.fullDate){
          timeDivider = config.getTranslation().today
        }
      }
      else{
        if(messageReadableDate.fullDate !== oldMessageReadableDate.fullDate){
            if((messageReadableDate.date - currentReadableDate.date === -1) && (messageReadableDate.month === currentReadableDate.month) && (messageReadableDate.year === currentReadableDate.year)){
              timeDivider = config.getTranslation().yesterday
            }
            else{
              timeDivider = messageReadableDate.fullDate
            }
        }
      }
      //for next iteration, store old Message
      oldMessage = clone(message)

      //Message prep
      let text = null
      if(message.get('type') === "attachment") {
        let attachment = message.getIn(['attachment'])
        // if(!message.getIn(['attachment', 'url'], false)) {
          text = <div className="message-bubble" dangerouslySetInnerHTML={{ __html: ProcessMessage.MediaRender(attachment.get('url'))}}></div>
          if(attachment.get('type').substring(0,5) === "image") {
            text = <Image
               className="message-bubble" src={attachment.get('url')} onLoad={(e) => {
                  this.updateNodeInfo = false
                  this.forceUpdate()
                }} />
            let modal = <Modal mountNode= {this.state.modalMountNode} className="cc-theme" trigger={text} closeIcon>
              <Modal.Header>Photo</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  {text}
                </Modal.Description>
              </Modal.Content>
            </Modal>
            text = modal
          }
          if(attachment.get('type').substring(0,5) === "video"){
            text = <video className="message-bubble" controls><source src={attachment.get('url')} />Your browser does not support the video tag.</video>
            let modal = <Modal mountNode= {this.state.modalMountNode} trigger={text} closeIcon>
              <Modal.Header>Video</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  {text}
                </Modal.Description>
              </Modal.Content>
            </Modal>
            text = modal
          }
          if(attachment.get('type').substring(0,5) === "audio"){
            text = <AudioPlayer key={"player_" + message.get('id')} id={message.get('id')} url={attachment.get('url')}/>
          }
      }
      else if(message.get('type') === "announcment"){
        text = ""
      }
      else {
        // if(!message.getIn(['text'], false)) {
          text = <div className="message-bubble" dangerouslySetInnerHTML={{ __html: UnicodeToImg.unicodeToImgTag(ProcessMessage.MediaRender(message.getIn(['text'], "hello")), undefined, true)}}></div>
        // }
      }

      let customType = message.get('customType')
      let metadata = message.get('metadata')

      let role = false

      // user roles
      if(message.getIn(["user", "metadata"], false)) {
        let metadata = message.getIn(["user", "metadata"], false)
        if(metadata.roles) {
          role = JSON.parse(metadata.roles)[0]
        }
      }

      //user avatar
      let messageAvatar;
      messageAvatar = <Comment.Avatar as={() => <AvatarWrapper className="avatar" name={message.getIn(['user', 'displayName']) } size={30} src={message.getIn(['user', 'avatarUrl'])}/>} />

      let classes = "window-message window-message-receive"

      if(message.getIn(['user', 'id']) === this.props.user.get('id')){
        classes = "window-message window-message-self"
      }

      if(messageClubbing.info){
        classes = classes + " cc-window-message-clubbing"
      }
      else{
        classes = classes + " cc-window-message-first"
      }

      if(timeDivider){
        messages.push(<div key={"window-message-" + message.get('id') + timeDivider} className="window-message cc-window-content-announcment">{timeDivider}</div>)
      }
      //message object
      if(text!=null && message.get('type') !== "announcement"){
        messages.push(
          <Comment.Group key={"window-message-" + message.get('id')} className={classes + " window-message-" + message.get('id')}>
            <Comment>
              {!messageClubbing.info && messageAvatar}
              <Comment.Content>
                {/*  message author*/}
                {!messageClubbing.info && <Popup
                  trigger={<Comment.Author as='a'>{message.getIn(['user', 'displayName'])}</Comment.Author>}
                  hideOnScroll
                  position='right center'
                  on='click'
                  basic
                  className="cc-theme cc-user-profile">
                  <Popup.Content>
                    <ProfileCard userM={message.getIn(['user'])} id={this.props.id} type={this.props.type} />
                  </Popup.Content>
                </Popup>}

                {/*  message role*/}
                {false && role && <Comment.Metadata className="role">{role}</Comment.Metadata>}
                {false && !messageClubbing.info && <Comment.Metadata>
                  <div>{UtilityTime.getTime('1', message.get('insertedAt')*1000)}</div>
                </Comment.Metadata>}

                <Comment.Text>
                  <div className="message-content">
                    {text}
                    {this.props.type === "group" && <ReadReceipt groupChannelId ={this.props.id} message={message} />}
                    {<div className="message-time">{UtilityTime.getTime('5', message.get('insertedAt')*1000)}</div>}
                  </div>
                  {/* <ReadReceipt groupChannelId ={this.props.id} message={message} /> */}
                  {/* {customType==="action_link" && <FlightCard product={JSON.parse(metadata.product)} />} */}
                </Comment.Text>
                {/* <Comment.Actions>
                  <div>{"16:40"}</div>
                </Comment.Actions> */}
              </Comment.Content>
            </Comment>
          </Comment.Group>
        )
      }
      else if(message.get('type') === "announcement"){
        messages.push(<div key={"window-message-" + message.get('id')} className="window-message cc-window-content-announcment">{message.get('text')}</div>)
      }
    })


    //Show if someone is typing
    if(this.props.groupChannels.getIn([this.props.id, 'typing'], false)
    && this.props.groupChannels.getIn([this.props.id, 'typingParticipants'])[0]['id'] !== this.props.user.get('id')) {
      messages.push(
        <Comment.Group className={"window-message window-message-receive cc-window-message-first"} key={"window-message-typing-" + (new Date()).getTime()}>
          <Comment>
            <Comment.Avatar as={() => <AvatarWrapper className="avatar" name={this.props.groupChannels.getIn([this.props.id, 'typingParticipants'])[0]['displayName']} size={30} src={this.props.groupChannels.getIn([this.props.id, 'typingParticipants'])[0]['avatarUploadUrl'] || this.props.groupChannels.getIn([this.props.id, 'typingParticipants'])[0]['avatarUrl']}/>} />

            <Comment.Content>
              {/* <Popover frame={"ifc-chat-frame-window"} position={"top left"} trigger={<Comment.Author as='a'>{message.name}</Comment.Author>} content={<ProfileCard/>}/> */}
              <Comment.Author as='a'>{this.props.groupChannels.getIn([this.props.id, 'typingParticipants'])[0]['displayName']}</Comment.Author>
              <Comment.Metadata>
                <div></div>
              </Comment.Metadata>
              <Comment.Text>
                <div className="message-content">
                  <Loader type="ball-beat" color="#222" size="sm" />
                </div>
              </Comment.Text>
              <Comment.Actions>
                {/* <Popover trigger={<Comment.Action>Settings</Comment.Action>} content={<MessageSettings/>}/> */}
              </Comment.Actions>
            </Comment.Content>
          </Comment>
        </Comment.Group>
      )
    }
  }
  
    return (
      <Segment ref={node => this.handleContextRef = node} onMouseEnter={this.checkHover.bind(this)} onMouseLeave={this.checkHoverLeave.bind(this)} onScroll={this.checkLoadMore.bind(this)} className={windowContent}>

        { !this.props.groupChannels.getIn([this.props.id, 'initialMessageLoading']) && messages} 
        
        { this.props.groupChannels.getIn([this.props.id, 'initialMessageLoading'], true) && this.props.type === "group" && <RosterLoader/> }

        { this.props.openChannels.getIn([this.props.id, 'initialMessageLoading'], true) && this.props.type === "open" && <RosterLoader/> }

        {/* <FlightCard product={this.state.cardState} /> */}
        <SoundNotification groupChannelId = {this.props.id}/>
        <TitleAlert groupChannelId = {this.props.id}/>
        <DesktopNotification groupChannelId = {this.props.id}/>
      </Segment>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    openChannelsActions: bindActionCreators(openChannelsActions, dispatch),
    groupChannelsStateActions: bindActionCreators(groupChannelsStateActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowContent)
