import {
  GROUP_CHANNELS_MY_LIST_SUCCESS,
  GROUP_CHANNELS_LIST_SUCCESS,
  GROUP_CHANNELS_LIST_RESET
} from 'state/action-types'

import client from 'Client'

export const getList = (reference, customFilter) => dispatch => {
  var groupListQuery = client.GroupChannel.createGroupChannelListQuery();
  groupListQuery.reference = reference
  if(customFilter.length > 0){
    groupListQuery.customFilter = customFilter
  }
  groupListQuery.load(function(error, groupChannelList){
     if(error == null){
       dispatch({
         type: GROUP_CHANNELS_LIST_SUCCESS,
         groupChannels: groupChannelList
       });
       dispatch({
         type: GROUP_CHANNELS_MY_LIST_SUCCESS,
         groupChannels: groupChannelList
       });
      }

  })
}

export const resetGroupChannelList = () => dispatch => {
  dispatch({
    type: GROUP_CHANNELS_LIST_RESET
  })
}
