import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import client from 'Client'
import Utility from 'utility/Utility'


class SoundNotification extends Component {

  checkEnableNotifications(){
    return this.props.userLocalData.get("enablePushNotifications")
  }

  checkSnooze(){
    return !(this.props.user.get('pushSnooze') > 0)
  }

  checkSchedule(){
    if(this.props.user.get('dnd')){
      let dndTimezone = this.props.user.get("timezone")
      let currentTimeZone = new Date();
      let utc = currentTimeZone.getTime() + (currentTimeZone.getTimezoneOffset() * 60000);
      let currentTime = new Date(utc + (3600000*dndTimezone)); //current time in dnd timezone
      let currentHour = currentTime.getHours()
      let currentMin = currentTime.getMinutes()
      let currentTimezone = currentTime.getTimezoneOffset()/-60;
      let dndStartHour = Number(this.props.user.get('startDnd').split(":")[0])
      let dndStartMin = Number(this.props.user.get('startDnd').split(":")[1])
      let dndEndHour = Number(this.props.user.get('endDnd').split(":")[0])
      let dndEndMin = Number(this.props.user.get('endDnd').split(":")[1])
      

      if(currentHour < dndStartHour){
        if(dndEndHour > dndStartHour){
          return true
        }
        else if( dndEndHour === dndStartHour){
          return true
        }
        else{
          if(dndEndHour < currentHour){
            return true
          }
          else if(dndEndHour === currentHour){
            if(dndEndMin > currentMin){
              return false
            }
            else{
              return true
            }
          }
          else{
            return false
          }
        }
      }
      else if(currentHour === dndStartHour){
        if(currentMin < dndStartMin){
          return true
        }
        else{
          if(dndEndHour > dndStartHour){
            return false
          }
          else if(dndEndHour === dndStartHour){
            if(currentMin < dndEndMin){
              return false
            }
            else{
              return true
            }
          }
          else{
            return false
          }
        }
      }
      else{
        if(dndEndHour > dndStartHour){
          if(currentHour < dndEndHour ){
              return false
          }
          else if(currentHour ===  dndEndHour ){
            if(currentMin < dndMin){
              return false
            }
            else{
              return true
            }
          }
          else{
              return true
          }
        }
        else if(dndEndHour === dndStartHour){
          if(currentMin < dndEndMin){
            return false
          }
          else{
            return true
          }
        }
        else{
          return false
        }
      }
    }
    else{
     return true 
    }
    
  }

  render() {
    let {groupChannelId, user} =  this.props
    let channelListener = new client.ChannelListener();
    let that = this;
    channelListener.onGroupChannelMessageReceived = function(groupChannel, message) {
      if(groupChannelId === groupChannel.id){
        if(user.get("id") !== message.user.id && user.get("sound") !== "OFF"  && that.checkSnooze() && that.checkSchedule()){
          console.log("Playing sound", user.get("sound"))
          Utility.playSound("1");
        }
      }
    }
    client.addChannelListener("SoundNotification", channelListener)

    return (
      null
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SoundNotification)
