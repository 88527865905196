import React, { Component } from 'react'
import { connect } from 'react-redux'
import AvatarWrapperStatus from 'containers/ChatApp/Components/AvatarWrapperStatus'
import AvatarUploadEditor from 'containers/ChatApp/Components/AvatarUploadEditor'
import config from 'config'

class CurrentProfile extends Component {
  state = {

  }
  constructor(props,context){
    super(props,context);
  }

  render () {
      return (
        <div className="cc-current-profile">
          <AvatarWrapperStatus className={"cc-current-profile-avatar-status " + this.props.className} name={this.props.user.get("displayName")} src = {this.props.user.get("avatarUrl")} size={36} isOnline={true}/>
          <div className="cc-current-user-name">{this.props.user.get("displayName")}</div>
          <br/>
          {config.getRosterShowUserAvatarUpload() && <AvatarUploadEditor className="cc-current-profile-avatar-editor"/>}
        </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentProfile)
