import Immutable, {OrderedMap, Map} from 'immutable'
import {
  USER_LIST_SUCCESS,
} from 'state/action-types'


export const initialState = Map({
  roster: OrderedMap(),
  participants: OrderedMap()
})

export function userList (state = initialState, action) {
  let oldUser;
  switch (action.type) {
    case USER_LIST_SUCCESS:
      let channels =  OrderedMap()
      for(let i in action.userList) {
        action.userList[i]['avatarUrl'] = action.userList[i].avatarUploadUrl ===  '' ? action.userList[i].avatarUrl : action.userList[i].avatarUploadUrl;
        let g = OrderedMap(action.userList[i])
        oldUser = state.getIn([action.userList[i].id], Immutable.Map())
        g = g.merge(oldUser)
        channels = channels.setIn([action.userList[i].id], g)
      }

      let channelMap = channels;

      if(action.roster) {
        if(action.reset) {
          return state.set('roster', channelMap)
        }
        let oldChannels = state.get('roster')
        return state.set('roster', oldChannels.merge(channelMap))
      }
      else {
        let oldChannels = state.get('participants')
        return state.set('participants', oldChannels.merge(channelMap))
      }
      
    default:
      return state
  }
}
