import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/user/actions'
import * as blockListActions from 'state/blockList/actions'
import RosterLoader from 'containers/ChatApp/Components/RosterLoader'
import EmptyBlockList from 'containers/ChatApp/Components/EmptyBlockList'
import RosterBlockList from 'containers/ChatApp/Components/RosterBlockList'
import * as Debug from 'debug';
const debug = Debug('chatcamp:BlockList')

class BlockList extends Component {
  state = {
    
  }
  componentDidMount(){
      this.props.blockListActions.getBlockList()
  }
  
  checkLoadMore = (e) => {
    let node = document.getElementsByClassName("cc-block-list")[0];
    if(node.clientHeight === (node.scrollHeight - node.scrollTop)) {
        this.props.blockListActions.getBlockList(20, this.props.blockList.get('roster').last().get("id"))
    }
    return false
  }

  render () {
    let finalClass = "cc-block-list cc-user-settings"
    let final
    if(this.props.className !== undefined){
        finalClass = finalClass + " " + this.props.className
    }

    if(!this.props.ui.get("blockListLoading")){
        if(this.props.blockList.get('roster').size > 0){
            final = <RosterBlockList />
          }
          else{
            final = <EmptyBlockList />
          }
        }
        else{
          final = <RosterLoader />
        }


    return (
        <div onScroll={this.checkLoadMore.bind(this)} className={finalClass}>{final}</div>
    )
  }

}

function mapStateToProps(state) {
    return state
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        blockListActions: bindActionCreators(blockListActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(BlockList)
