import React, { Component } from 'react'
import ChatCampIcon from 'containers/ChatApp/Components/ChatCampIcon'
import {ICONS} from 'constants/icons'
import {isEqual} from 'lodash'
import * as Debug from 'debug';
const debug = Debug('chatcamp:OnlineStatus')

class OnlineStatus extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps);
  }

  render () {
    let source_status = <ChatCampIcon icon={ICONS.STATUS} height="16px" width="16px" viewBox="0 0 50 50"/>
    let customClass = this.props.className
    if(this.props.isOnline){
        customClass = "cc-online " + this.props.className
    }
    else {
        customClass = "cc-offline " + this.props.className
    }
    // let onlineStatus = <div className= {customClass}>{source_status}</div>
    return (
        <div className={customClass}>{source_status}</div>
    )
  }

}

export default OnlineStatus
