import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/groupChannels/actions'
import config from 'config'
import AvatarWrapperStatus from 'containers/ChatApp/Components/AvatarWrapperStatus'
import { Icon, Menu } from 'semantic-ui-react'
import UtilityTime from 'utility/UtilityTime'
import client from 'Client'
import Immutable from 'immutable';
import {includes} from 'lodash'


class ProfileCard extends Component {
  state = {

  }
  constructor(props,context){
    super(props,context);
  }

  onUserClick = (id) => {
    let currentId =  this.props.groupChannelsState.keySeq().toArray()[0]
    client.GroupChannel.create("New Group", [id, this.props.user.get("id")], true ,function(error, newGroupChannel) {
        if(newGroupChannel.id !== currentId){
            window.ChatCampUI.startChat(newGroupChannel.id)
        }
    })
}


  render () {
    let {userM} = this.props
    let showStatus = true
    if(userM.getIn(["data"]) === ""){
      showStatus =  false
    }
    return (
        <div className={"cc-user-profile-grid"} style={{width: "300px"}}>
          <AvatarWrapperStatus className={"cc-user-profile-avatar"} name={userM.getIn(["displayName"])} src = {userM.getIn(["avatarUrl"])} size={36} isOnline={userM.getIn(["isOnline"])}/>
          {!showStatus && <div className= "cc-profile-card-name">{userM.getIn(["displayName"])}</div>}
          {showStatus && <div className= "cc-profile-card-name">
              <div className= "cc-profile-card-name-inner">{userM.getIn(["displayName"])}</div>
              <div className= "cc-profile-card-name-status">{userM.getIn(["data"])}</div>
          </div>}
            {/*  show admin from metadata*/}
          <div className="cc-profile-card-data">
            { this.props.groupChannels.getIn([this.props.id, 'metadata'], Immutable.Map()) && this.props.groupChannels.getIn([this.props.id, 'metadata'], Immutable.Map()).admins &&  includes(JSON.parse(this.props.groupChannels.getIn([this.props.id, 'metadata'], Immutable.Map()).admins), userM.get("id")) && <Icon name="check circle outline" color="black"/>}
          </div>
          <div className= "cc-profile-card-time-text">
            {userM.get("isOnline", false)? config.getTranslation().online : <div>{userM.get("lastSeen") === 0? "Not Joined":UtilityTime.getTime('3',userM.get("lastSeen")*1000)}</div>}
          </div>
          {(userM.getIn(["id"]) !== this.props.user.get("id") ) && <Menu borderless vertical className={"cc-profile-card-options"}>
            {userM.getIn(["profileUrl"]) && <Menu.Item as="a" href={userM.getIn(['profileUrl'])}link>View Profile</Menu.Item>}
            <Menu.Item link onClick={() => this.onUserClick(userM.getIn(['id']))} >{config.getTranslation().sendMessageButton}</Menu.Item>
            {/* <Menu.Item link>Block User</Menu.Item> */}
          </Menu>}
        </div>
        
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch) //binds all the actions with dispatcher and returns them
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)
