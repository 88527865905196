import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as smartChatActions from 'state/smartChat/actions'
import ChatCampIcon from 'containers/ChatApp/Components/ChatCampIcon'
import config from 'config'
import {ICONS} from 'constants/icons'

class WelcomeBubble extends Component {

  state = {
    notificationCount: 0
  }
  startChat(){
    this.props.smartChatActions.smartChatOpen()
  }

  render () {
    let source =  <ChatCampIcon icon={ICONS.CHAT} height="40px" width="40px" viewBox="0 0 60 60"/>
    let notificationCount = this.props.user.get("unreadMessageCount")
    if(this.props.auth.get("connectionState") === "CONNECTED"){
      notificationCount = this.props.user.get("unreadMessageCount")
    }
    else{
      notificationCount = 0
    }
    
    let themeClass = "chatcamp-welcome-bubble"
    if (this.props.app.get("type") === "sideChat"){
      themeClass = themeClass + " cc-side-button"
    }

    if(config.getChatPosition() === "LEFT"){
      themeClass = themeClass + " cc-chat-position-left"
    }
    else{
      themeClass = themeClass + " cc-chat-position-right"
    }
    
    return (
      <div onClick={()=>(this.startChat())}className={themeClass}>
        {/* <div className="chatcamp-wb-left">
          <div className="chatcamp-wb-left-text">How can I help you today?</div>
        </div>
        <div className="chatcamp-wb-close">
          <Image className="chatcamp-wb-right-icon" src={source_close}/>
        </div> */}
        <div className="chatcamp-wb-right">
          {/* <Image className="chatcamp-wb-right-image" src={source}/> */}
          <div className="chatcamp-wb-right-image">{source}</div>
          {(notificationCount > 0) && <div className="chatcamp-wb-notification">{notificationCount}</div>}
        </div>
      </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    smartChatActions: bindActionCreators(smartChatActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeBubble)
