import {Map} from 'immutable'
import {
    USER_LOCAL_TITLE_ALERT_HIDE,
    USER_LOCAL_TITLE_ALERT_SHOW,
    USER_LOCAL_PUSH_NOTIFICATION_ENABLE,
    USER_LOCAL_PUSH_NOTIFICATION_DISABLE
} from 'state/action-types'


export const initialState = Map({
  isTitleAlertHidden: false,
  enablePushNotifications: true
})

export function userLocalData (state = initialState, action) {
  switch (action.type) {
    case USER_LOCAL_TITLE_ALERT_SHOW:
      return state.set('isTitleAlertHidden', false)
    case USER_LOCAL_TITLE_ALERT_HIDE:
      return state.set('isTitleAlertHidden', true)

    case USER_LOCAL_PUSH_NOTIFICATION_ENABLE:
      return state.set('enablePushNotifications', true)
    case USER_LOCAL_PUSH_NOTIFICATION_DISABLE:
      return state.set('enablePushNotifications', false)
    
    default:
      return state
  }
}
