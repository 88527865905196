import React, { Component } from 'react'
import * as Debug from 'debug';
const debug = Debug('chatcamp:EmptyBlockList')

class EmptyBlockList extends Component {

  render () {
    return (
        <div className={"cc-empty-block-list-users-top"}>
            <p className="cc-empty-block-list-users">
                <span className="cc-empty-block-list-users-header">No Users Blocked.</span>
                {/* <span className="cc-empty-block-list-users-description"> Contact your admin to create users.</span> */}
            </p>
        </div>
    )
  }

}

export default EmptyBlockList
