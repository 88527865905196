import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Debug from 'debug';
import { bindActionCreators } from 'redux'
import * as uiActions from 'state/ui/actions'
import { Button } from 'semantic-ui-react'
import config from 'config';
const debug = Debug('chatcamp:noOpenWindow')

class NoOpenWindow extends Component {
    buttonClick= () => {
        this.props.uiActions.groupChannelCreateOpen()
    }

    render () {
        return (
            <div className={"cc-no-open-window cc-window-container"}>
                <p className="cc-no-open-window-inner">
                    <span className="cc-no-open-window-header">No Open Chats.</span>
                    <br />
                    {config.getRosterShowStartNewChat() && <Button className="cc-no-open-window-create" onClick=        {this.buttonClick.bind()} content="Start Chat" size="tiny" />}
                </p>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return state
}
  
function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(NoOpenWindow)
