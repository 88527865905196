import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Icon, Modal, Button } from 'semantic-ui-react'
import * as actionsGroupChannels from 'state/groupChannels/actions'
import * as blockListActions from 'state/blockList/actions'
import * as uiActions from 'state/ui/actions'
import * as Debug from 'debug';
const debug = Debug('chatcamp:BlockAlert')

class BlockAlert extends Component {
  state = {
    
  }

  componentWillMount() {
    let modalMountNode = document.getElementsByClassName("cc-root")[0]
    this.setState({modalMountNode: modalMountNode})
  } 

  handleBlockModalClose = () => {
    this.setState({show: false})
    this.props.uiActions.userBlockAlertClose()
  }

  handleBlockModalUnblock = () => {
    let other = this.p2pOtherParticipant()
    this.props.blockListActions.unblockUser(other.id)
    this.props.uiActions.userBlockAlertClose()
    this.props.groupChannelsState.map((chatWindow, id) => {
        this.props.actionsGroupChannels.syncGroup(id)
    })
  }

  p2pOtherParticipant = () => {
    let localId
    if(this.props.ui.get("isUserBlockAlertOpen")){
        localId = this.props.ui.get("isUserBlockAlertOpen")
        let id = this.props.user.get("id")
        let participants = this.props.groupChannels.getIn([localId, 'participants'])
        for(let i in participants){
        if(participants[i].id !== id){
            return participants[i]
        }
        }
    }
    else{
        return false
    }
    
  }

  render () {
    
    return (
        <Modal mountNode= {this.state.modalMountNode} open={!!this.props.ui.get("isUserBlockAlertOpen")} className="cc-theme" closeIcon={false} size={"mini"}>          
        <Modal.Header>Unblock User</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                {"User has been blocked by you. Unblock user to continue chatting with them."}
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button.Group>
                <Button size={"small"} onClick={()=> {this.handleBlockModalClose()}}>
                    <Icon name='remove' /> Cancel
                </Button>
                <Button.Or/>
                <Button size={"small"} onClick={()=> {this.handleBlockModalUnblock()}} primary>
                    <Icon name='ban' /> Unblock
                </Button>
            </Button.Group>
        </Modal.Actions>
    </Modal>
    )
  }

}

function mapStateToProps(state) {
    return state
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        blockListActions: bindActionCreators(blockListActions, dispatch),
        uiActions: bindActionCreators(uiActions, dispatch),
        actionsGroupChannels: bindActionCreators(actionsGroupChannels, dispatch),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(BlockAlert)
