import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Menu, Input, Dropdown, Checkbox, Header, Divider, Button } from 'semantic-ui-react'
import CurrentProfile from 'containers/ChatApp/Components/CurrentProfile'
import timezoneOptions from './timezones'
import timeOptions from './time'
import * as actions from 'state/user/actions'
import * as userLocalDataActions from 'state/userLocalData/actions'
import * as uiActions from 'state/ui/actions'
import client from 'Client'
import * as Debug from 'debug';
const debug = Debug('chatcamp:UserSettings')

class UserSettings extends Component {
  state = {
    localStatus: this.props.user.get("data"),
    localDnd: this.props.user.get("dnd"),
    localStartDnd: this.props.user.get("startDnd"),
    localEndDnd: this.props.user.get("endDnd"),
    localTimezone: this.props.user.get("timezone"),
  }

  handleStatusChange = (e, d) => {
    this.setState({
        localStatus: e.target.value
    })
  }

  handleStatusSave = (e, d) => {
    this.props.actions.saveStatus(this.state.localStatus)
  }

  handleSoundChange = (e, d) => {
    this.props.actions.muteSoundToggle(d.checked)
  }

  handleTemplate = (e, d) => {
    this.props.actions.templateUpdate(d.value)
  }

  handleDndChange = (e, d) => {
    this.props.actions.dnd(d.checked, this.props.user.get("startDnd"), this.props.user.get("endDnd"), this.props.user.get("timezone"))
  }

  handleStartTime = (e, d) => {
      this.props.actions.dnd(this.props.user.get("dnd"), d.value, this.props.user.get("endDnd"), this.props.user.get("timezone"))
  }

  handleEndTime = (e, d) => {
      this.props.actions.dnd(this.props.user.get("dnd"), this.props.user.get("startDnd"), d.value, this.props.user.get("timezone"))
  }

  handleTimezone = (e, d) => {
    this.props.actions.dnd(this.props.user.get("dnd"), this.props.user.get("startDnd"), this.props.user.get("endDnd"), d.value)
  }

  handleSnooze = (e, d) => {
      let timeInMinutes = d.value*60
      this.props.actions.snoozeUpdate(timeInMinutes)
  }

  handleResume = () => {
    this.props.actions.snoozeUpdate(0)
  }

  handleTitleAlertChange = () => {
      if(this.props.userLocalData.get("isTitleAlertHidden")){
        this.props.userLocalDataActions.userLocalTitleAlertShow()
      }
      else{
        this.props.userLocalDataActions.userLocalTitleAlertHide()
      }

  }

  handleBlockList = () => {
    this.props.uiActions.userBlockListOpen()
  }

  handleLogout = () => {
    this.props.actions.userLogout().then(()=>{
       client.disconnect(function(error, result){
        })
    })
  }

  handleNotifcationsChange = () => {
    if(this.props.userLocalData.get("enablePushNotifications")){
        this.props.userLocalDataActions.userLocalPushNotificationsDisable()
      }
      else{
        this.props.userLocalDataActions.userLocalPushNotificationsEnable()
      }
  }

  render () {
    let finalClass = "cc-user-settings"

    let disableNotification = !this.props.userLocalData.get("enablePushNotifications")
    if(this.props.className !== undefined){
        finalClass = finalClass + " " + this.props.className
    }

    let templateOptions = [
        {
            text: 'Default',
            value: 'DEFAULT',
        },
        {
            text: 'Private',
            value: 'PRIVATE',
        }
      ]

    return (
        <Menu borderless vertical className={finalClass}>
            <Menu.Item >
                <CurrentProfile className="cc-user-settings-profile"/> 
                <Input action value={this.state.localStatus} className="cc-user-settings-status" placeholder='Share your status..' onChange={this.handleStatusChange.bind(this)}>
                    <input />
                    <Button onClick={this.handleStatusSave.bind(this)}>Save</Button>
                </Input>
                
            </Menu.Item>

            <Divider fitted/>
            <Header size="tiny" className="cc-user-profile-header" content="Notifications" />
            <Menu.Item>
                <Checkbox toggle checked={this.props.userLocalData.get("enablePushNotifications")} onChange={this.handleNotifcationsChange.bind(this)} label="Enable Push Notifications"/>
            </Menu.Item>

            <Divider fitted/>
            <Header size="tiny" className="cc-user-profile-header" content="Snooze Notifications" />

            { this.props.user.get('pushSnooze') > 0 && <Menu.Item>
                <div>{"You are muted for next " + this.props.user.get('pushSnooze') + " minutes." }</div>
            </Menu.Item>}

            { this.props.user.get('pushSnooze') > 0 && <Menu.Item>
                <Button onClick={this.handleResume.bind(this)} className="cc-user-settings-notification-resume">Resume Notifications</Button>
            </Menu.Item>}

            <Menu.Item>
                <Dropdown disabled={disableNotification} text='Mute Notifications' pointing='right' className='cc-user-profile-notification link item'>
                    <Dropdown.Menu>
                        <Dropdown.Item value={1} onClick={this.handleSnooze}>For 1 Hour</Dropdown.Item>
                        <Dropdown.Item value={3} onClick={this.handleSnooze}>For 3 Hours</Dropdown.Item>
                        <Dropdown.Item value={6} onClick={this.handleSnooze}>For 6 Hours</Dropdown.Item>
                        <Dropdown.Item value={9} onClick={this.handleSnooze}>For 9 Hours</Dropdown.Item>
                        <Dropdown.Item value={12} onClick={this.handleSnooze}>For 12 Hours</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                
            </Menu.Item>

            <Divider fitted/>
            <Header size="tiny" className="cc-user-profile-header" content="Do Not Disturb Schedule" />

            <Menu.Item>
                <Checkbox toggle disabled={disableNotification} checked={this.props.user.get("dnd")} onChange={this.handleDndChange.bind(this)} label="Automatically disable notification from: "/> 
            </Menu.Item>

            <Menu.Item>
                <Dropdown disabled={!this.props.user.get("dnd") || disableNotification} onChange={this.handleStartTime} className="cc-user-settings-time" placeholder='Start Time' fluid selection options={timeOptions} defaultValue={this.state.localStartDnd}/>
                <div className="cc-user-settings-to">TO</div>
                <Dropdown disabled={!this.props.user.get("dnd") || disableNotification} onChange={this.handleEndTime} className="cc-user-settings-time" placeholder='End Time' fluid selection options={timeOptions} defaultValue={this.state.localEndDnd}/>
            </Menu.Item>

            <Menu.Item>
                <Dropdown className="cc-user-settings-timezone" disabled={!this.props.user.get("dnd") || disableNotification} onChange={this.handleTimezone} placeholder='Select Time Zone' fluid selection options={timezoneOptions} defaultValue={this.state.localTimezone}/>
            </Menu.Item>

            <Menu.Item>
                <Checkbox toggle checked={(this.props.user.get("sound") === "OFF")} onChange={this.handleSoundChange.bind(this)} label="Mute sound for new messages"/>
            </Menu.Item>

            <Menu.Item>
                <Checkbox toggle checked={this.props.userLocalData.get("isTitleAlertHidden")} onChange={this.handleTitleAlertChange.bind(this)} label="Hide window title alerts"/>
            </Menu.Item>

            <Divider fitted/>
            <Header size="tiny" className="cc-user-profile-header" content="Notification Template" />

            <Menu.Item>
                <Dropdown disabled={disableNotification} onChange={this.handleTemplate} className="cc-user-settings-template" fluid selection options={templateOptions} defaultValue={this.props.user.get("pushTemplate")}/>
            </Menu.Item>

            <Divider />
            <Header size="tiny" className="cc-user-profile-header" content="Privacy" />
            
            <Menu.Item className="cc-user-settings-privacy" onClick={this.handleBlockList.bind(this)} link>Mange Blocked Users</Menu.Item>
            
            {!this.props.auth.get('isSSO') && <Menu.Item className="cc-user-settings-privacy" onClick={this.handleLogout.bind(this)} link>Logout</Menu.Item>}

            { this.props.auth.get('isSSO') && <Menu.Item className="cc-user-settings-privacy" onClick={this.handleLogout.bind(this)} link>Go Offline</Menu.Item>}
            


        </Menu>
    )
  }

}

function mapStateToProps(state) {
    return state
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        userLocalDataActions: bindActionCreators(userLocalDataActions, dispatch),
        uiActions: bindActionCreators(uiActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
