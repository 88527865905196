import React, { Component } from 'react'
import { connect } from 'react-redux'
import SmartChat from './Components/SmartChat'
import SideChat from './Components/SideChat'
import Inbox from './Components/Inbox'
import Embed from './Components/Embed'

class App extends Component {
  state = { }

  render() {

    return (
      <div id="ifc-app-container" className="themeClass">
        {this.props.app.get("type") === "smartChat" &&  <SmartChat/>}
        {this.props.app.get("type") === "sideChat" &&  <SideChat/>}
        {this.props.app.get("type") === "inbox" &&  <Inbox/>}
        {this.props.app.get("type") === "embed" &&  <Embed />}
      </div>

    )
  }

};

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
