import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/groupChannelsState/actions'
import Window from 'containers/ChatApp/Components/Window'
import LeftPanel from 'containers/ChatApp/Components/LeftPanel'
import WelcomeBubble from 'containers/ChatApp/Components/WelcomeBubble'
import HiddenChatWindow from 'containers/ChatApp/Components/HiddenChatWindow'
import Authenticating from 'containers/ChatApp/Components/Authenticating'
import Reconnecting from 'containers/ChatApp/Components/Reconnecting'
import LoginScreen from 'containers/ChatApp/Components/LoginScreen'
import LoginSsoScreen from 'containers/ChatApp/Components/LoginSsoScreen'
import Toast from 'containers/ChatApp/Components/Toast'
import BlockAlert from 'containers/ChatApp/Components/BlockAlert'
import Utility from 'utility/Utility';
import config from 'config'
import * as Debug from 'debug'

const debug = Debug('chatcamp:SmartChat')

class SmartChat extends Component {
  
  render () {
    let windows = [];
    let number = 0;
    let showHidden = false
    let windowWidth = window.innerWidth
    let that = this

    let mainClass = "cc-smartchat"
    if(config.getChatPosition() === "LEFT"){
      mainClass = mainClass + " cc-chat-position-left"
    }
    else{
      mainClass = mainClass + " cc-chat-position-right"
    }

    if(config.getListPanelShow() === true && this.props.smartChat.get("isSmartChatOpen") === true){
      
      number = 1
      if(this.props.auth.get('connectionState') === "INITIAL"){
        if(!this.props.auth.get('isSSO')){
          windows.push(<LoginScreen key="login-screen"/>)
        }
      }
      else if(this.props.auth.get('connectionState') === "DISCONNECTED"){
        if(!this.props.auth.get('isSSO')){
          windows.push(<LoginScreen key="login-screen"/>)
        }
        else{
          windows.push(<LoginSsoScreen key="login-sso-screen"/>)
        }
      }
      else if(this.props.auth.get('connectionState') === "CONNECTING"){
        windows.push(<Authenticating key="chat-authenticating"/>)
      }
      else{
        windows.push(<LeftPanel key={"window-left"}/>)
      }

      {this.props.auth.get('connectionState') === "RECONNECTING" && windows.push(<Reconnecting key="reconnecting"/>)}
    }



    if(this.props.auth.get("connectionState") === "CONNECTED" || this.props.auth.get('connectionState') === "RECONNECTING"){
      this.props.groupChannelsState.map((chatWindow, id) => {
        
          if(chatWindow.getIn(["state"]) !== "HIDDEN" && chatWindow.getIn(["state"]) !== undefined){
            if(number < Math.floor(windowWidth/368)){
            // if(number < 3){
              windows.push(<Window key={"window-" + id} id={id} type={chatWindow.getIn(["type"])} position = {number++} state={"open"}/>)
            }
            else if(Utility.mobileCheck()){
              windows.push(<Window key={"window-" + id} id={id} type={chatWindow.getIn(["type"])} position = {number++} state={"open"}/>)

            }
            else{
              showHidden = true
              if(chatWindow.getIn(["type"]) === "open"){
                that.props.actions.openChannelsHide(id)
              }
              else if(chatWindow.getIn(["type"]) === "group"){
                that.props.actions.groupChannelsHide(id)
              }
            }
          }
          else if(chatWindow.getIn(["state"]) === "HIDDEN"){
            showHidden = true
          }
      })
    }


    return (
      <div id="ifc-app" className={mainClass}>
 
        {(((this.props.smartChat.getIn(["isSmartChatOpen"])) && config.getListPanelShow() === true) || config.getListPanelShow() === false) && windows}

        {!(this.props.smartChat.getIn(["isSmartChatOpen"])) && config.getWelcomeBubbleShow() === true && <WelcomeBubble />}

        {(this.props.smartChat.getIn(["isSmartChatOpen"])) && showHidden && <HiddenChatWindow position={Math.floor(windowWidth/368)} />}
        <Toast />
        <BlockAlert />
      </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch) //binds all the actions with dispatcher and returns them
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartChat)
