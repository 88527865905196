import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AvatarEditor from 'react-avatar-editor'
import * as actions from 'state/user/actions'
import { Modal, Button, Icon } from 'semantic-ui-react'


class AvatarUploadEditor extends Component {
    state = {
        avatar: '',
        showEditor: false,
        modalMountNode: '',
        scale: 1.2
    }

    constructor(props,context){
        super(props,context);
    }

    componentWillMount() {
        let modalMountNode = document.getElementsByClassName("cc-root")[0]
        this.setState({modalMountNode: modalMountNode})
    } 

    uploadAvatarClick = () => {
        let inputAttachment = this.refs.avatarUploadField;
        inputAttachment.click()
    }
    
    handleFileUpload(e) {
        e.preventDefault();
        let file = e.target.files[0];
        this.setState({avatar: file})
        e.target.value = ''
        this.setState({showEditor: true})
    }
    
    handleScale(e){
        this.setState({scale: e.target.value})
    }

    handleMinusClick(){
        let oldValue = this.state.scale;
        if(Number(oldValue) > 1){
            this.setState({scale: (Number(oldValue) - 0.01)})
        }
    }

    handlePlusClick(){
        let oldValue = this.state.scale;
        if(Number(oldValue) < 2){
            this.setState({scale: (Number(oldValue) + 0.01)})
        }
        
    }

    onClickSave(){
        if (this.editor) {
        const canvas = this.editor.getImage();
        canvas.toBlob((blob) => {
            let finalFile = new File([blob], this.props.user.get("id") + ".jpg")
            this.props.actions.uploadAvatar(finalFile);
            this.setState({showEditor: false})
          }, "image/jpeg", 95)
        }
        
    }
    
    handleClose(){
        this.setState({showEditor: false})
    }
  
    setEditorRef = (editor) => this.editor = editor

    render () {
    
        return (
            <div className={this.props.className}>
                <div onClick={() => {this.uploadAvatarClick()}}>{"edit"}</div>
                
                <input ref="avatarUploadField" type="file" onChange={this.handleFileUpload.bind(this)} style={{visibility: "hidden", width: 0, height: 0, display: "none"}}/>
                
                <Modal mountNode= {this.state.modalMountNode} open={this.state.showEditor} className="cc-theme" closeIcon={false} size={"mini"}>
                    <Modal.Header>Update Profile Picture</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <AvatarEditor
                                ref={this.setEditorRef}
                                image={this.state.avatar}
                                width={250}
                                height={250}
                                border={30}
                                borderRadius={500}
                                color={[255, 255, 255, 0.6]} // RGBA
                                scale={Number(this.state.scale)}
                                rotate={0}
                            />
                            <button 
                                onClick={this.handleMinusClick.bind(this)} className="cc-avatar-upload-editor-minus"
                                >-</button>
                            
                            <input 
                                className="cc-avatar-upload-editor-input" 
                                onChange={this.handleScale.bind(this)} 
                                type="range" 
                                step={Number("0.01")} 
                                min={Number("1")} 
                                max={Number("2")} 
                                name="scale" 
                                value={Number(this.state.scale)} />
                            
                            <button 
                                onClick={this.handlePlusClick.bind(this)} className="cc-avatar-upload-editor-plus"
                                >+</button>

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button.Group>
                        <Button size={"small"} onClick={this.handleClose.bind(this)}>
                            <Icon name='remove' /> Cancel
                        </Button>
                        <Button.Or/>
                        <Button size={"small"} onClick={this.onClickSave.bind(this)} primary>
                            <Icon name='image' /> Save
                        </Button>
                        </Button.Group>
                    </Modal.Actions>
                </Modal>
            </div>
    )
  }

}

function mapStateToProps(state) {
    return state
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AvatarUploadEditor)
