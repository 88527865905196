import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/groupChannels/actions'
import { Icon, List, Image} from 'semantic-ui-react'
import config from 'config'
import {includes} from 'lodash'
import Immutable from 'immutable';
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import UtilityTime from 'utility/UtilityTime'
import ChatCampIcon from 'containers/ChatApp/Components/ChatCampIcon'
import {ICONS} from 'constants/icons'

class GroupParticipantsList extends Component {

  state = {

  }

  render () {

    let inlineListStyle = {
      width: "300px"
    }
    let inlineStyleDisplay ={
      display: "table-cell"
    }

    let inlineStyleHeight ={
      lineHeight: "36px"
    }
    let inlineStyleHeightName ={
      lineHeight: "36px",
      maxWidth: "140px",
      whiteSpace: "nowrap",
      overflowX: "hidden"
    }
    let inlineStyleHeightNameAdmin ={
      lineHeight: "36px",
      maxWidth: "20px",
      whiteSpace: "nowrap",
      overflowX: "hidden",
      fontSize:"17px"
    }
    let inlineStyleHeightImage ={
      lineHeight: "36px",
      height: "36px",
      position: "relative"
    }
    let source_status = <ChatCampIcon icon={ICONS.STATUS} height="16px" width="16px" viewBox="0 0 50 50"/>

    return (
     
      <List className="cc-group-participants-list">
        {this.props.groupChannels.getIn([this.props.id, 'participants'], Immutable.Map()).map((user, id) => {
          let onlineStatus
          if(user.isOnline){
            onlineStatus = <div className= "cc-group-participants-status cc-online">{source_status}</div>
          }
          else {
            onlineStatus = <div className= "cc-group-participants-status cc-offline">{source_status}</div>
          }
          return (
            <List.Item key={"participant-content-list-" + user.id}>
              <List.Content style={inlineStyleHeightImage} floated='left' verticalAlign='middle'>
                {<Image as={()=> <AvatarWrapper size={30} className="image" name={user.displayName} src={user.avatarUrl} />}/>}
                {onlineStatus}
              </List.Content>

              <List.Content style={inlineStyleHeightName} verticalAlign='middle' floated="left">
                <List.Description>{user.displayName}</List.Description>
              </List.Content>
              {/*  show admin from metadata*/}
              { this.props.groupChannels.getIn([this.props.id, 'metadata'], Immutable.Map()) && this.props.groupChannels.getIn([this.props.id, 'metadata'], Immutable.Map()).admins &&  includes(JSON.parse(this.props.groupChannels.getIn([this.props.id, 'metadata'], Immutable.Map()).admins), user.id) && <List.Content style={inlineStyleHeightNameAdmin} verticalAlign='middle' floated="left">
                <Icon name="check circle outline" color="black"/>
              </List.Content>}
              <List.Content className="cc-group-participants-time-text" style={inlineStyleHeight} floated='right' verticalAlign='middle'>
                {user.isOnline? config.getTranslation().online : <div>{user.lastSeen === 0? "Not Joined":UtilityTime.getTime('3', user.lastSeen*1000)}</div>}

              </List.Content>
            </List.Item>)
        })}
      </List>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch) //binds all the actions with dispatcher and returns them
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupParticipantsList)
