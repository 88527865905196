import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionsGroupChannels from 'state/groupChannels/actions'
import * as actionsGroupChannelsState from 'state/groupChannelsState/actions'
import { List, Image } from 'semantic-ui-react'
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import UtilityTime from 'utility/UtilityTime'
import * as Debug from 'debug';
const debug = Debug('chatcamp:RosterInbox')

class RosterInboxProduct extends Component {
    ifPopUp = () => {
        if (this.props.app.get("type") === "smartChat" || this.props.app.get("type") === "inbox" || this.props.app.get("type") === "sideChat"){
          return true
        }
        else{
          return false
        }
    }
    
    ifP2P = (id) => {
        if(this.props.groupChannels.getIn([id, 'participantsCount'], "0") === 2){
          return true
        }
        else{
          return false
        }
    }
    
    p2pOtherParticipant = (gid) => {
        let id = this.props.user.get("id")
        let participants = this.props.groupChannels.getIn([gid, 'participants'])
        if(participants){
            for(let i in participants){
            if(participants[i].id !== id){
                return participants[i]
            }
            }
        }
    }
    
    onInboxClick = (id) => {
        let currentId =  this.props.groupChannelsState.keySeq().toArray()[0]
        if(id !== currentId){
            this.props.actionsGroupChannels.syncGroup(id)
            window.ChatCampUIKit.startChat(id, "group")
        }
    }

    render () {
        let roster = []
        let inlineStyleDisplay ={
        display: "table-cell"
        }
        let inlineStyleHeight ={
            lineHeight: "30px",
        }
        let inlineStyleHeightImage ={
        lineHeight: "36px",
        height: "36px",
        position: "relative",
        marginTop: '5px'
        }
        let openId = this.props.groupChannelsState.keySeq().toArray()[0]
        let inboxValues = this.props.groupChannelsList.toArray()
        for(let i in inboxValues){
            let topClass = "list-inbox"
            let id = inboxValues[i]
            if(openId === id){
                topClass = "list-inbox list-inbox-open"
            }
            let rosterItem
            if(this.props.groupChannels){
                rosterItem = this.props.groupChannels.getIn([id])
            }
            //TODO: break this into separate function
            //TODO: Get user from local user db rather than online user list
            let user = {}
            user.name = "Name"
            user.status = "A"
            let groupChannelName
            let avatar
            if(rosterItem){
                let productName
                let productImage
                let productMetadata = rosterItem.getIn(['metadata'])
                if(productMetadata){
                    productName = productMetadata.product_name
                    productImage = productMetadata.product_image
                }

                groupChannelName = rosterItem.getIn(['name'])
                avatar = <Image as={()=> <AvatarWrapper style={inlineStyleDisplay} className="image" name={productName} src = {productImage} />}/>
                if(id && this.ifPopUp() && this.ifP2P(id)){
                    let other = this.p2pOtherParticipant(id)
                    if(other){
                        groupChannelName = other.displayName
                        // avatar = <Image as={()=> <AvatarWrapper style={inlineStyleDisplay} className="image" name={groupChannelName} src = {other.avatarUrl} />}/>
                    }
                }
                let lastMessageText = "";
                if(rosterItem.getIn(['lastMessage']) && rosterItem.getIn(['lastMessage']).type === "text"){
                    lastMessageText = rosterItem.getIn(['lastMessage']).user.displayName + ": " + rosterItem.getIn(['lastMessage']).text
                }
                else if(rosterItem.getIn(['lastMessage']) && rosterItem.getIn(['lastMessage']).type === "announcement"){
                    lastMessageText = rosterItem.getIn(['lastMessage']).text
                }
                else if(rosterItem.getIn(['lastMessage']) && rosterItem.getIn(['lastMessage']).type === "attachment"){
                    lastMessageText = rosterItem.getIn(['lastMessage']).user.displayName + ": attachment"
                }


                roster.push(
                    <List.Item className={topClass} key={"roster-key-" + rosterItem.getIn(['id']) } onClick={() => this.onInboxClick(rosterItem.getIn(['id']))}>
                    
                        <List.Content className={"list-item-time"} style={inlineStyleHeight} floated='right' verticalAlign='middle'>
                            {(rosterItem.getIn(['lastMessage']))? UtilityTime.getTime('3', rosterItem.getIn(['lastMessage']).insertedAt*1000) : ""}
                        </List.Content>

                        <List.Content style={inlineStyleHeightImage} floated='left' verticalAlign='middle'>
                            {avatar}
                        </List.Content>

                        <List.Content style={inlineStyleHeight} verticalAlign='middle'>
                            <List.Description className={"list-inbox-description"}>{groupChannelName}</List.Description>
                            <List.Description className={"list-inbox-description list-inbox-product"}>{productName}</List.Description>
                            <List.Description className={"list-inbox-description"}>
                                {lastMessageText}
                            </List.Description>
                            {(rosterItem.getIn(['unreadMessageCount']) > 0) &&  <List.Description className={"list-inbox-unread"}>
                                {(rosterItem.getIn(['unreadMessageCount']) > 9) ? "9+" : rosterItem.getIn(['unreadMessageCount'])}
                            </List.Description>}
                        </List.Content>
                    </List.Item>
                )
            }
        }
      

        return (
            <List selection verticalAlign='middle' relaxed="very" size="medium">
                {roster}
            </List>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actionsGroupChannels: bindActionCreators(actionsGroupChannels, dispatch),
    actionsGroupChannelsState: bindActionCreators(actionsGroupChannelsState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterInboxProduct)
