import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import ChatApp from 'containers/ChatApp'
import Utility from 'utility/Utility'
import {
  GROUP_CHANNELS_CLOSE, SMART_CHAT_CLOSE
} from 'state/action-types'
import config from 'config'
import * as Debug from 'debug';
const debug = Debug('chatcamp:Root')
export default class Root extends Component {

  render() {
    const {
      storePersistor
      // history,
      // routes
    } = this.props;
    let store = storePersistor.store
    let persistor = storePersistor.persistor

    const onBeforeLift = () => {
      // take some action before the gate lifts. store has hydrated but app is not loaded yet
      let appId = config.getAppId()

      let userId = config.getUserId()

      // debug("start user id"+ store.getState().user.get("id", false)+appId+userId)

      // if user id or app id doesnt match then delete the groupchannelsState
      if(store.getState().user.get("id", false)){
        if(userId !== store.getState().user.get("id") || appId !== store.getState().user.get("appId") || Utility.mobileCheck()){
          let storeChannels = store.getState().groupChannelsState.keySeq().toArray()
          for(let i in storeChannels)
          store.dispatch({
            type: GROUP_CHANNELS_CLOSE,
            groupChannelsId: storeChannels[i]
          })
          store.dispatch({
            type: SMART_CHAT_CLOSE
          })
        }
      }
      if(store.getState().app.get("type", false) === "embed") {
        let storeChannels = store.getState().groupChannelsState.keySeq().toArray()
          for(let i in storeChannels)
          store.dispatch({
            type: GROUP_CHANNELS_CLOSE,
            groupChannelsId: storeChannels[i]
          })
      }
      
    }

    return (
      <Provider key={Math.random()} store={store}>
        <PersistGate loading={null} onBeforeLift={onBeforeLift} persistor={persistor}>
          <ChatApp key={Math.random()} appId={"iFlyChatAppDiv"}/>
      </PersistGate>
      </Provider>
    );
  }
}
