import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductHead from 'containers/ChatApp/Components/ProductHead'
import config from 'config'


class WindowDescription extends Component {

  state = {
    
  }

  render () {
    let description;
    if(this.props.data){
      description = <div dangerouslySetInnerHTML={{ __html: this.props.data}}></div>
    }
    else if(this.props.type === "open"){
        description = this.props.openChannels.getIn([this.props.id, 'data'], false)
    }
    else if(this.props.type === "group"){
        description = this.props.groupChannels.getIn([this.props.id, 'data'], false)
    }
    if(config.getChannelShowProductMetadata() && this.props.type === "group"){
      return <ProductHead className="cc-window-description" id ={this.props.id} type = {this.props.type} />
    }
    else if(description){
        return (
            <div className="cc-window-description">{description}</div>
          )
    }
    else{
        return null
    }
    
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowDescription)
