import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/userList/actions'
import * as groupChannelsListactions from 'state/groupChannelsList/actions'
import * as openChannelsactions from 'state/openChannels/actions'
import * as uiActions from 'state/ui/actions'
import { Tab, Input } from 'semantic-ui-react'
import Roster from 'containers/ChatApp/Components/Roster'
import ListHeader from 'containers/ChatApp/Components/ListHeader'
import ProductTabs from 'containers/ChatApp/Components/ProductTabs'
import UserSettings from 'containers/ChatApp/Components/UserSettings'
import BlockList from 'containers/ChatApp/Components/BlockList'
import config from 'config'
import * as Debug from 'debug';
const debug = Debug('chatcamp:LeftPanel')

class LeftPanel extends Component {

  search = ""

  state = {
    fileRef: null,
    activeIndex:0,
    isHover: false
  }
  
  onUserSearch = (e, data) => {
    this.search = data.value
    this.props.uiActions.updateUserSearchValue(this.search)
    let options = {
      roster: true,
      isOnlineFilter: "ALL"
    };
    if(data.value !== '') {
      options.displayNameSearch = data.value;
    }
    else {
      options.isOnlineFilter = "ONLINE";
    }
    this.props.actions.getUserList(20, undefined, options)
  }

  checkHover = (e) => {
    this.setState({isHover: true});
  }

  checkHoverLeave = (e) => {
    this.setState({isHover: false});
  }

  checkLoadMore = (e) => {
    let node = document.getElementsByClassName("chatcamp-list-roster-horizontal-item")[0];
    if(node.clientHeight === (node.scrollHeight - node.scrollTop)) {
      if(this.indexMap[this.state.activeIndex] === "users"){
        this.props.actions.getUserList(20, this.props.userList.get('roster').last().get("id"), {
          roster: true,
          displayNameSearch: this.search,
          isOnlineFilter: this.props.userList.get('roster').last().get("isOnline") ? "ONLINE": "OFFLINE" 
        })
      }
      else if(this.indexMap[this.state.activeIndex] === "inbox"){
        this.props.groupChannelsListactions.getList(this.props.groupChannelsList.last(), [])
      }
      else if(this.indexMap[this.state.activeIndex] === "chatrooms"){
        debug("OC last", this.props.openChannels.last().get("id"))
        this.props.openChannelsactions.getList( this.props.openChannels.last().get("id"))
      }
    }
    return false
  }

  handleTabChange = (e, { activeIndex }) => {
    if(this.indexMap[activeIndex] === "users"){
      this.props.actions.getUserList(null, null, {
        roster: true,
        isOnlineFilter: "ONLINE"
      })
    }
    this.setState({ activeIndex })
  }

  handleBackButton = (e, d) => {
    if(this.props.ui.get('isUserBlockListOpen')){
      this.props.uiActions.userBlockListClose()
    }
    else if(this.props.ui.get('isUserSettingsOpen')){
      this.props.uiActions.userSettingsClose()
    }
  }

  componentDidMount() {
    if(this.indexMap[0] === "users"){
      this.props.actions.getUserList(null, null, {
        roster: true,
        isOnlineFilter: "ONLINE"
      })
    }
  }

  render () {
    let mainClass = "chatcamp-left-panel"

    if(config.getChatPosition() === "LEFT"){
      mainClass = mainClass + " cc-chat-position-left"
    }
    else{
      mainClass = mainClass + " cc-chat-position-right"
    }

    if(this.props.className){
      mainClass = mainClass + " cc-inbox-app-small"
    }
    this.indexMap = []
    let tabClasses = "chatcamp-list-roster-horizontal-item"
    if(!this.state.isHover){
      tabClasses = tabClasses + " cc-scroll-hover"
    }
    let finalRecent = config.getTranslation().recentChatsTab
    if(this.props.user.get("totalGroupChannel")){
      finalRecent = config.getTranslation().recentChatsTab + " (" + this.props.user.get("totalGroupChannel") + ")"
    }
    const panes = []
    
    if(config.getListPanelChatroomsShow() > 0){
      this.indexMap[config.getListPanelChatroomsShow() - 1] =  "chatrooms"
      panes[config.getListPanelChatroomsShow() - 1] =  {menuItem: config.getTranslation().roomsTab, render: () => <Tab.Pane onMouseEnter={this.checkHover.bind(this)} onMouseLeave={this.checkHoverLeave.bind(this)}  className={tabClasses}><Roster type="chatrooms"/></Tab.Pane>}
    }

    if(config.getListPanelUsersShow() > 0){
      this.indexMap[config.getListPanelUsersShow() - 1] =  "users"
      panes[config.getListPanelUsersShow() - 1] = { menuItem: config.getTranslation().usersTab, render: () => <Tab.Pane  onMouseEnter={this.checkHover.bind(this)} onMouseLeave={this.checkHoverLeave.bind(this)}  className={tabClasses}>{!this.props.ui.get("userListLoading")  && <Input 
          key="roster-user-search" 
          fluid={true}
          open={false}
          onChange={this.onUserSearch}
          placeholder={config.getTranslation().search}
          // icon='search'
          className="cc-list-roster-user-search"
        />}<Roster type="users"/></Tab.Pane> }
    }
    
    if(config.getListPanelInboxShow() > 0){
      this.indexMap[config.getListPanelInboxShow() - 1] =  "inbox"
      panes[config.getListPanelInboxShow() - 1] = {menuItem: finalRecent, render: () => <Tab.Pane className={"cc-pane-1"} onMouseEnter={this.checkHover.bind(this)} onMouseLeave={this.checkHoverLeave.bind(this)}  className={tabClasses}><Roster type="inbox"/></Tab.Pane> }
    }

    let widthClass = "chatcamp-list-roster-horizontal"
    if(panes.length > 0){
      widthClass = widthClass + " cc-list-roster-items-" + Math.floor(100/(panes.length))
    }

   return(
      <div className={mainClass}>

        <ListHeader/>

        {!((config.getListPanelInboxShow() > 0) && !(config.getListPanelChatroomsShow()>0) && !(config.getListPanelUsersShow() > 0)) && !this.props.ui.get('isUserSettingsOpen') && 
       <Tab ref={node => this.handleContextRef = node} onScroll={this.checkLoadMore.bind(this)} onTabChange={this.handleTabChange} panes={panes} className={widthClass} menu={{ secondary: true, pointing: true }} />} 


      { ((config.getListPanelInboxShow() > 0) && !(config.getListPanelChatroomsShow()>0) && !(config.getListPanelUsersShow() > 0)) && 
         !this.props.ui.get('isUserSettingsOpen') && <ProductTabs />} 

       
        
        
      { this.props.ui.get('isUserSettingsOpen') && !this.props.ui.get('isUserBlockListOpen') && <UserSettings className="cc-left-panel-user-settings"/>}

      {this.props.ui.get('isUserBlockListOpen') && <BlockList className="cc-left-panel-block-list"/>}

      {(this.props.ui.get('isUserSettingsOpen') ||  this.props.ui.get('isUserBlockListOpen'))&& <div onClick={this.handleBackButton.bind(this)} className="cc-left-panel-back">GO BACK</div>}

     </div>
   )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    groupChannelsListactions: bindActionCreators(groupChannelsListactions, dispatch),
    openChannelsactions: bindActionCreators(openChannelsactions, dispatch), 
    uiActions: bindActionCreators(uiActions, dispatch) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanel)
