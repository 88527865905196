import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as groupChannelsListactions from 'state/groupChannelsList/actions'
import * as uiActions from 'state/ui/actions'
import { List, Image } from 'semantic-ui-react'
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import * as Debug from 'debug';
const debug = Debug('chatcamp:ProductListings')

class ProductListing extends Component {

    onProductClick = (id) => {
        this.props.groupChannelsListactions.getList(null, [id])
        this.props.uiActions.productListClose(id)
    }

    render () {
        let topClass = "list-inbox"
        let inlineStyleDisplay = {
                display: "table-cell"
            }
        let inlineStyleHeight = {
                lineHeight: "36px",
            }
        let inlineStyleHeightImage ={
                lineHeight: "36px",
                height: "36px",
                position: "relative",
                marginTop: '13px'
            }
        let products = this.props.list
        let list = []
        for(let i in products){
            let productName = products[i].product_name
            let productDescription = products[i].product_desc
            list.push(
                <List.Item className={topClass} key={"roster-key-" + i } onClick={() => this.onProductClick(products[i].product_id)} >

                        <List.Content style={inlineStyleHeightImage} floated='left' verticalAlign='middle'>
                            {<Image as={()=> <AvatarWrapper style={inlineStyleDisplay} className="image" name={productName} /*src = {rosterItem.getIn(['avatarUrl'])}*/ />}/>}
                        </List.Content>

                        <List.Content style={inlineStyleHeight} verticalAlign='middle'>
                            <List.Header className={"list-inbox-header"}>
                                {productName}
                            </List.Header>
                            {<List.Description className={"list-inbox-chats"}>
                                {"3 Chats"}
                            </List.Description>}
                            <List.Description className={"list-inbox-description"}>
                                {productDescription}
                            </List.Description>
                        </List.Content>
                    </List.Item>
            )
        }

        return (
            <div>
                <List selection verticalAlign='middle' relaxed="very" size="medium">
                    {list}
                </List>
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    groupChannelsListactions: bindActionCreators(groupChannelsListactions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListing)
