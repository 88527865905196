import {combineReducers} from 'redux'
import {auth} from 'state/auth/reducer'
import {app} from 'state/app/reducer'
import {blockList} from 'state/blockList/reducer'
import {groupChannels} from 'state/groupChannels/reducer'
import {openChannels} from 'state/openChannels/reducer'
import {smartChat} from 'state/smartChat/reducer'
import {groupChannelsState} from 'state/groupChannelsState/reducer'
import {groupChannelsList} from 'state/groupChannelsList/reducer'
import {ui} from 'state/ui/reducer'
import {user} from 'state/user/reducer'
import {userList} from 'state/userList/reducer'
import {userLocalData} from 'state/userLocalData/reducer'

export const rootReducer = combineReducers({
  auth,
  app,
  blockList,
  groupChannels,
  openChannels,
  smartChat,
  groupChannelsState,
  groupChannelsList,
  ui,
  user,
  userList,
  userLocalData
})
