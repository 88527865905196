import React, { Component } from 'react'
import * as Debug from 'debug';
const debug = Debug('chatcamp:EmptyChatrooms')

class EmptyChatrooms extends Component {

  render () {
    return (
        <div className={"cc-empty-chatrooms-top"}>
            <p className="cc-empty-chatrooms">
                <span className="cc-empty-chatrooms-header">No Chatroom Created Yet</span>
                <span className="cc-empty-chatrooms-description"> Contact your admin to create chatrooms.</span>
            </p>
        </div>
    )
  }

}

export default EmptyChatrooms
