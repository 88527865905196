export default [
    {
        text: '12:00 AM',
        value: '0:0',
    },
    {
        text: '12:30 AM',
        value: '0:30',
    },
    {
        text: '1:00 AM',
        value: '1:0',
    },
    {
        text: '1:30 AM',
        value: '1:30',
    },
    {
        text: '2:00 AM',
        value: '2:0',
    },
    {
        text: '2:30 AM',
        value: '2:30',
    },
    {
        text: '3:00 AM',
        value: '3:0',
    },
    {
        text: '3:30 AM',
        value: '3:30',
    },
    {
        text: '4:00 AM',
        value: '4:0',
    },
    {
        text: '4:30 AM',
        value: '4:30',
    },
    {
        text: '5:00 AM',
        value: '5:0',
    },
    {
        text: '5:30 AM',
        value: '5:30',
    },
    {
        text: '6:00 AM',
        value: '6:0',
    },
    {
        text: '6:30 AM',
        value: '6:30',
    },
    {
        text: '7:00 AM',
        value: '7:0',
    },
    {
        text: '7:30 AM',
        value: '7:30',
    },
    {
        text: '8:00 AM',
        value: '8:0',
    },
    {
        text: '8:30 AM',
        value: '8:30',
    },
    {
        text: '9:00 AM',
        value: '9:0',
    },
    {
        text: '9:30 AM',
        value: '9:30',
    },
    {
        text: '10:00 AM',
        value: '10:0',
    },
    {
        text: '10:30 AM',
        value: '10:30',
    },
    {
        text: '11:00 AM',
        value: '11:0',
    },
    {
        text: '11:30 AM',
        value: '11:30',
    },
    {
        text: '12:00 PM',
        value: '12:0',
    },
    {
        text: '12:30 PM',
        value: '12:30',
    },
    {
        text: '1:00 PM',
        value: '13:0',
    },
    {
        text: '1:30 PM',
        value: '13:30',
    },
    {
        text: '2:00 PM',
        value: '14:0',
    },
    {
        text: '2:30 PM',
        value: '14:30',
    },
    {
        text: '3:00 PM',
        value: '15:0',
    },
    {
        text: '3:30 PM',
        value: '15:30',
    },
    {
        text: '4:00 PM',
        value: '16:0',
    },
    {
        text: '4:30 PM',
        value: '16:30',
    },
    {
        text: '5:00 PM',
        value: '17:0',
    },
    {
        text: '5:30 PM',
        value: '17:30',
    },
    {
        text: '6:00 PM',
        value: '18:0',
    },
    {
        text: '6:30 PM',
        value: '18:30',
    },
    {
        text: '7:00 PM',
        value: '19:0',
    },
    {
        text: '7:30 PM',
        value: '19:30',
    },
    {
        text: '8:00 PM',
        value: '20:0',
    },
    {
        text: '8:30 PM',
        value: '20:30',
    },
    {
        text: '9:00 PM',
        value: '21:0',
    },
    {
        text: '9:30 PM',
        value: '21:30',
    },
    {
        text: '10:00 PM',
        value: '22:0',
    },
    {
        text: '10:30 PM',
        value: '22:30',
    },
    {
        text: '11:00 PM',
        value: '23:0',
    },
    {
        text: '11:30 PM',
        value: '23:30',
    },
];