import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as uiActions from 'state/ui/actions'
import { Button } from 'semantic-ui-react'
import config from 'config';
import * as Debug from 'debug';
const debug = Debug('chatcamp:EmptyRecentChats')

class EmptyRecentChats extends Component {
    buttonClick= () => {
        this.props.uiActions.groupChannelCreateOpen()
    }
    render () {
        return (
            <div className={"cc-empty-recent-chats-top"}>
                <p className="cc-empty-recent-chats">
                    <span className="cc-empty-recent-chats-header">No Recent Chats Yet</span>
                    {/* <span className="cc-empty-recent-chats-description">Click on pencil icon to create group chats or go to users section to chat one to one. </span> */}
                    <br />
                    {config.getRosterShowStartNewChat() && <Button className="cc-empty-recent-chats-create" onClick={this.buttonClick.bind()} content="Start Chat" size="tiny" />}
                </p>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return state
}
  
function mapDispatchToProps(dispatch) {
    return {
        uiActions: bindActionCreators(uiActions, dispatch)
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EmptyRecentChats)
