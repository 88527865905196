import client from 'Client'
import {
  USER_LIST_SUCCESS
} from 'state/action-types'

export const getUserList = (limit, reference, options) => dispatch => {
  let userListQuery = client.createUserListQuery();
  if(options.isOnlineFilter) {
    userListQuery.isOnlineFilter = options.isOnlineFilter;
  }
  if(limit){
    userListQuery.limit = limit
  }
  if(reference){
    userListQuery.reference = reference
  }

  let roster = false
  if(options) {
    if(options.displayNameSearch) {
      userListQuery.displayNameSearch = options.displayNameSearch
    }
    if(options.roster) {
      roster = options.roster
    }
  }
  userListQuery.load(function(error, userList){
    if(error == null){
      dispatch({
        type: USER_LIST_SUCCESS,
        userList: userList,
        reset: reference ? false: true,
        roster: roster
      });
      if(userList.length < userListQuery.limit && userListQuery.isOnlineFilter === "ONLINE") {
      userListQuery.isOnlineFilter = "OFFLINE";
      userListQuery.reference = null;
      userListQuery.load(function(error, userList){
        if(error == null){
          dispatch({
            type: USER_LIST_SUCCESS,
            userList: userList,
            reset: false,
            roster: roster
          });
        }
      });
      } 
    }

  })
}
