import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/smartChat/actions'
import * as actionsUser from 'state/user/actions'
import * as uiActions from 'state/ui/actions'
import * as actionsUserList from 'state/userList/actions'
import CreateGroupChannel from 'containers/ChatApp/Components/CreateGroupChannel'
import ChatCampIcon from 'containers/ChatApp/Components/ChatCampIcon'
import {ICONS} from 'constants/icons'
import { Segment, Popup, Icon } from 'semantic-ui-react'
import config from 'config';
import * as Debug from 'debug'
const debug = Debug('chatcamp:ListHeader')
class ListHeader extends Component {

  state = {
  }

  

  ifPopUp = () => {
    if (this.props.app.get("type") === "smartChat" || this.props.app.get("type") === "sideChat"){
      return true
    }
    else{
      return false
    }
  }

  closeSmartChat = () => {
    this.props.actions.smartChatClose()
  }

  markReadAll = () => {
    this.props.actionsUser.markAsReadAll()
  }

  buttonClick= () => {
    this.props.uiActions.groupChannelCreateOpen()
  }

  handleSettingsClick = (e) => {
    if(this.props.ui.get("isUserSettingsOpen")){
      this.props.uiActions.userSettingsClose()
    }
    else{
      this.props.uiActions.userSettingsOpen()
    }
  }

  render () {
    let source =  <ChatCampIcon icon={ICONS.CREATE} height="20px" width="20px" viewBox="0 0 35 35"/>

    let source_close =  <ChatCampIcon icon={ICONS.CLOSE} height="24px" width="24px" viewBox="0 0 50 50"/>

   return(
     <Segment className="cc-list-header">
        <div className="cc-list-header-text">{config.getTranslation().rosterHeader}</div>

        { this.ifPopUp() && <div className={"cc-list-header-actions"}>
          <Popup className="headerSettings"
            trigger={<div onClick={() => {this.closeSmartChat()}} className= "cc-list-header-close">{source_close}</div>}
            hideOnScroll
            position='bottom right'
            on='hover' className="cc-theme cc-tooltips">
            <Popup.Content>
            {config.getTranslation().close}
            </Popup.Content>
          </Popup>
        </div>}

        

        <div onClick={this.handleSettingsClick.bind(this)} className={"cc-list-header-actions cc-list-header-settings"}>
          <Icon name="setting" />
        </div>

        <div className={"cc-list-header-actions cc-list-header-settings"}>
          <Popup className="headerSettings"
            trigger={<div onClick={() => {this.markReadAll()}} className= "cc-list-header-close">{<Icon name="check circle outline" />}</div>}
            hideOnScroll
            position='bottom right'
            on='hover' className="cc-theme cc-tooltips">
            <Popup.Content>
              {config.getTranslation().markAsReadAll}
            </Popup.Content>
          </Popup>
        </div>

        {config.getRosterShowStartNewChat() && <Popup 
            className="headerSettings"
            trigger= {<div onClick={this.buttonClick.bind()} className= "cc-list-header-image">{source}</div>}
            hideOnScroll
            position='bottom left'
            on='hover' className="cc-theme cc-tooltips">
            <Popup.Content>{config.getTranslation().startNewChat}</Popup.Content>
        </Popup>}
        
        {this.props.ui.get("isGroupChannelCreateOpen") && <CreateGroupChannel />}
        
     </Segment>
   )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    actionsUser: bindActionCreators(actionsUser, dispatch),
    actionsUserList: bindActionCreators(actionsUserList, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListHeader)
