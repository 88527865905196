import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Debug from 'debug'

const debug = Debug('chatcamp:Reconnecting')

class Reconnecting extends Component {

    render () {

        return (
            <div className="cc-reconnecting">
                Chat is trying to reconnect.. 
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reconnecting)
