import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actionsGroupChannelsState from 'state/groupChannelsState/actions'
import { List, Image } from 'semantic-ui-react'
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import * as Debug from 'debug';
const debug = Debug('chatcamp:RosterChatrooms')

class RosterChatrooms extends Component {

  onChatRoomClick = (id) => {
    window.ChatCampUIKit.startChat(id, "open")
  }

  render () {
    let roster = []
    let inlineStyleDisplay ={
      display: "table-cell"
    }
    let inlineStyleHeightImage ={
      lineHeight: "36px",
      height: "36px",
      position: "relative",
      marginTop: '13px'
    }


    this.props.openChannels.map((rosterItem) => {
        roster.push(
            <List.Item className={"list-chatroom"} key={"roster-key-" + rosterItem.getIn(['id']) } onClick={() => this.onChatRoomClick(rosterItem.getIn(['id']))}>
            <List.Content style={inlineStyleHeightImage} floated='left' verticalAlign='middle'>
                {<Image as={()=> <AvatarWrapper style={inlineStyleDisplay} className="image" name={"#"} src={rosterItem.getIn(['avatarUrl'])} />}/>}
            </List.Content>

            <List.Content className="cc-chatroom-list-name" verticalAlign='middle'>
                <List.Header>{rosterItem.getIn(['name'])}</List.Header>
            </List.Content>

            </List.Item>
        )
    })
      

    return (
      <List selection verticalAlign='middle' relaxed="very" size="medium">
        {roster}
      </List>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actionsGroupChannelsState: bindActionCreators(actionsGroupChannelsState, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterChatrooms)
