import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/user/actions'
import { ToastContainer } from 'react-toastify';
import * as Debug from 'debug';
const debug = Debug('chatcamp:Toast')

class Toast extends Component {
  state = {
    
  }


  render () {
   
    return (
        <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
    )
  }

}

function mapStateToProps(state) {
    return state
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Toast)
