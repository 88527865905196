import {Map} from 'immutable'
import {
  CHAT_CONNECTING,
  CHAT_CONNECT_SUCCESS,
  CHAT_DISCONNECT_SUCCESS,
  CHAT_RECONNECTING,
  CHAT_STATE_LOADED_SUCCESS,
  CHAT_SSO_TRUE,
  CHAT_SSO_FALSE
} from 'state/action-types'

export const initialState = Map({
  connectionState: "INITIAL",
  isConnected: false,
  isLoading: true,
  isSSO: true
})

export function auth (state = initialState, action) {
  switch (action.type) {
    case CHAT_CONNECTING:
      return state.set('connectionState', "CONNECTING")
    case CHAT_CONNECT_SUCCESS:
      return state.set('isConnected', true).set('isLoading', false).set('connectionState', "CONNECTED")
    case CHAT_STATE_LOADED_SUCCESS:
      return state.set('isLoading', false)
    case CHAT_RECONNECTING:
      return state.set('isConnected', false).set('isLoading', false).set('connectionState', "RECONNECTING")
    case CHAT_DISCONNECT_SUCCESS:
      return state.set('isConnected', false).set('isLoading', false).set('connectionState', "DISCONNECTED")
    case CHAT_SSO_TRUE:
      return state.set('isSSO', true)
    case CHAT_SSO_FALSE:
      return state.set('isSSO', false)
    default:
      return state
  }
}
