import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as groupChannelsListactions from 'state/groupChannelsList/actions'
import ProductListing from 'containers/ChatApp/Components/ProductListing'
import ProductInbox from 'containers/ChatApp/Components/ProductInbox'
import Roster from 'containers/ChatApp/Components/Roster'
import * as uiActions from 'state/ui/actions'
import config from 'config'
import { Tab } from 'semantic-ui-react'
import * as Debug from 'debug';
const debug = Debug('chatcamp:ProductTabs')

class ProductTabs extends Component {

    // handleTabChange = (e, { activeIndex }) => {
    //     if(this.indexMap[activeIndex] === "users"){
    //       this.props.actions.getUserList(null, null, {
    //         roster: true
    //       })
    //     }
    //     this.setState({ activeIndex })
    // }

    render () {
        let tabClasses = "chatcamp-list-roster-horizontal-item"
        let buyers = []
        let sellers = []
        if(this.props.user.get("metadata").buyer){
            buyers = JSON.parse(this.props.user.get("metadata").buyer)
            // sellers = JSON.parse(this.props.user.get("metadata").seller)
        }
        if(this.props.user.get("metadata").seller){
            sellers = JSON.parse(this.props.user.get("metadata").seller)
        }
        let products = sellers.concat(buyers)
        let widthClass = "chatcamp-list-roster-horizontal"
        let final;
        const panes = []
        if(this.props.ui.get("isProductListOpen") && config.getRosterShowProductList()){
            panes[0] =  {menuItem: "ALL", render: () => <Tab.Pane className={tabClasses}><ProductListing list= {products}/></Tab.Pane>}
            panes[1] =  {menuItem: "BUYING", render: () => <Tab.Pane className={tabClasses}><ProductListing list= {buyers}/></Tab.Pane>}
            panes[2] =  {menuItem: "SELLING", render: () => <Tab.Pane className={tabClasses}><ProductListing list= {sellers}/></Tab.Pane>}   
            
            if(panes.length > 0){
                widthClass = widthClass + " cc-list-roster-items-" + Math.floor(100/(panes.length))
            }

             final = <Tab /*onTabChange={this.handleTabChange}*/ panes={panes} className={widthClass} menu={{ secondary: true, pointing: true }} />     
        }
        else if(!this.props.ui.get("isProductListOpen") && config.getRosterShowProductList()){
            final =  <ProductInbox /> 
        }
        else if(!config.getRosterShowProductList()){
            final = <div className={widthClass}><Roster className="chatcamp-list-roster-horizontal-item cc-product-list" type="inbox"/></div>
        }
        
        
        
        
        // if(this.props.ui.get("isProductListOpen")){
            // final = <Tab /*onTabChange={this.handleTabChange}*/ panes={panes} className={widthClass} menu={{ secondary: true, pointing: true }} />
            
        // }
        // else {
        //    final =  <ProductInbox /> 
        // }

        return (
           final
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    groupChannelsListactions: bindActionCreators(groupChannelsListactions, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTabs)
