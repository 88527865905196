import {Map} from 'immutable'
import {
	USER_SETTINGS_OPEN,
  USER_SETTINGS_CLOSE,
  GROUP_CHANNELS_MY_LIST_SUCCESS,
  OPEN_CHANNELS_LIST_SUCCESS,
  USER_LIST_SUCCESS,
  USER_BLOCK_LIST_OPEN,
  USER_BLOCK_LIST_CLOSE,
  USER_BLOCK_LIST_SUCCESS,
  USER_BLOCK_ALERT_OPEN,
  USER_BLOCK_ALERT_CLOSE,
  CHAT_DISCONNECT_SUCCESS,
  GROUP_CHANNEL_CREATE_OPEN,
  GROUP_CHANNEL_CREATE_CLOSE,
  PRODUCT_LIST_CLOSE,
  PRODUCT_LIST_OPEN,
  GROUP_CHANNELS_LIST_RESET,
  UPDATE_USER_SEARCH_VALUE
} from 'state/action-types'


export const initialState = Map({
  isUserSettingsOpen: false,
  isUserBlockListOpen: false,
  recentChatLoading: true,
  openChannelListLoading: true,
  userListLoading: true,
  blockListLoading: true,
  isUserBlockAlertOpen: false,
  isGroupChannelCreateOpen: false,
  isProductListOpen: true,
  userSearchValue: "",
  productId: ""
})

export function ui (state = initialState, action) {
  switch (action.type) {
    case USER_SETTINGS_OPEN:
      return state.set('isUserSettingsOpen', true)
    case USER_SETTINGS_CLOSE:
      return state.set('isUserSettingsOpen', false)
    case USER_BLOCK_LIST_OPEN:
      return state.set('isUserBlockListOpen', true)
    case USER_BLOCK_LIST_CLOSE:
      return state.set('isUserBlockListOpen', false).set('blockListLoading', true)
    case GROUP_CHANNELS_MY_LIST_SUCCESS:
      return state.set('recentChatLoading', false)
    case OPEN_CHANNELS_LIST_SUCCESS:
      return state.set('openChannelListLoading', false)
    case USER_LIST_SUCCESS:
      return state.set('userListLoading', false)
    case USER_BLOCK_LIST_SUCCESS:
      return state.set('blockListLoading', false)
    case USER_BLOCK_ALERT_OPEN:
      return state.set('isUserBlockAlertOpen', action.id)
    case USER_BLOCK_ALERT_CLOSE:
      return state.set('isUserBlockAlertOpen', false)
    case CHAT_DISCONNECT_SUCCESS:
      return state.set('isUserSettingsOpen', false).set('isUserBlockListOpen', false).set('blockListLoading', true)
    case GROUP_CHANNEL_CREATE_OPEN:
      return state.set('isGroupChannelCreateOpen', true)
    case GROUP_CHANNEL_CREATE_CLOSE:
      return state.set('isGroupChannelCreateOpen', false)
    case PRODUCT_LIST_OPEN:
      return state.set('isProductListOpen', true).set('productId', "")
    case PRODUCT_LIST_CLOSE:
      return state.set('isProductListOpen', false).set('productId', action.productId)
    case GROUP_CHANNELS_LIST_RESET:
      return state.set('recentChatLoading', true)
    case UPDATE_USER_SEARCH_VALUE:
      return state.set('userSearchValue', action.value)


    default:
      return state
  }
}
