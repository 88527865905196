import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import * as actionsGroupChannels from 'state/groupChannels/actions'
import * as actionsUserList from 'state/userList/actions'
import * as uiActions from 'state/ui/actions'
import config from 'config';
import { Modal, Input, Form, Label, Dropdown, Button, Message, Icon } from 'semantic-ui-react'
import * as Debug from 'debug'
const debug = Debug('chatcamp:ListHeader')

class CreateGroupChannel extends Component {
  state = {
    groupName: "",
    groupParticipants: [],
    showGroupNameError: false,
    showGroupParticipantsError: false,
    modalMountNode: ""
  }
  constructor(props,context){
    super(props,context);
  }

  checkLoadMore = (e) => {
    let parentNode = ReactDOM.findDOMNode(this.handleContextRef);
    let node = parentNode.querySelector('.menu')
    if(node.clientHeight === (node.scrollHeight - node.scrollTop)) {
      let options = {
        roster: false,
        isOnlineFilter: "ALL"
      };
      this.props.actionsUserList.getUserList(20, this.props.userList.get('participants').last().get("id"), options)
    }
    return false
  }

  componentWillMount(){
    let options = {
      roster: false,
      isOnlineFilter: "ALL"
    };
    this.props.actionsUserList.getUserList(20, undefined, options)
    let node = document.getElementsByClassName("cc-root")[0]
    this.setState({modalMountNode: node})
  }

  handleCancel = () => {
    this.setState({groupName: "", groupParticipants: []})
    this.props.uiActions.groupChannelCreateClose()
  }

  handleGroupChange = (event) => {
    this.setState({showGroupNameError: false})
    this.setState({ [event.target.name]: event.target.value })
  }

  handleParticipantChange = (event, data) => {
    this.setState({showGroupParticipantsError: false})
    this.setState({ [data.name]: data.value })
  }

  handleSubmit = (e) => {
    let {groupName, groupParticipants} = this.state
    if(groupName !== ""){
      if(groupParticipants.length > 0){
        groupParticipants.push(this.props.user.getIn(['id']))
        this.props.actionsGroupChannels.createChannel({groupChannelName: groupName, groupParticipants: groupParticipants, isDistinct: false})
        this.setState({groupName: "", groupParticipants: []})
        this.props.uiActions.groupChannelCreateClose()
      }
      else{
        this.setState({showGroupParticipantsError: true})
      }
    }
    else{
      this.setState({showGroupNameError: true})
    }
  }

  render () {
    let {groupName, groupParticipants, showGroupParticipantsError, showGroupNameError} = this.state

    let nameError = <Message error size={"tiny"} header='Group Name can not be empty'/>

    let participantsError = <Message error size={"tiny"} header='Atleast one participant must be added to the group'/>

    let options = []
    
    this.props.userList.get('participants').map((rosterItem) => {
      if(rosterItem.getIn(['id']) !== this.props.user.getIn(['id'])){
          let id = rosterItem.getIn(['id'])
          let name = rosterItem.getIn(['displayName'])
          // let image = rosterItem.getIn(['avatarUrl'])
          // options.push({key: id, value: id, image: image, text: name  })
          options.push({key: id, value: id, text: name  })
      }
    })

    return (
        <Modal 
            mountNode= {this.state.modalMountNode} 
            className="cc-create-group-modal cc-theme" 
            open={this.props.ui.get("isGroupChannelCreateOpen")} 
            size="tiny"
        >
            <Modal.Header>{config.getTranslation().startNewChat}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {showGroupParticipantsError && participantsError}
                    {showGroupNameError && nameError}
                    <Form onSubmit={this.handleSubmit.bind(this)}>
                        <Form.Field>
                            <Label>Group Name</Label>
                            <Input placeholder='Type here' name= 'groupName' value={groupName} onChange={this.handleGroupChange.bind(this)} />
                        </Form.Field>
                        <Form.Field>
                            <Label>Participants</Label>
                            <Dropdown ref={node => this.handleContextRef = node} onScroll={this.checkLoadMore.bind(this)} placeholder='Add Participants' fluid multiple selection options={options} name= 'groupParticipants' value={groupParticipants} onChange={this.handleParticipantChange.bind(this)} />
                        </Form.Field>
                        <br/>
                        <Button.Group>
                            <Form.Button onClick={this.handleCancel.bind()}>
                                Cancel
                            </Form.Button>
                        <Button.Or />
                            <Form.Button primary onClick={this.handleSubmit.bind(this)}>
                                Create
                            </Form.Button>
                        </Button.Group>
                        <br/>
                        <br/>
                    </Form>
                </Modal.Description>
          </Modal.Content>
        </Modal>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actionsGroupChannels: bindActionCreators(actionsGroupChannels, dispatch),
    actionsUserList: bindActionCreators(actionsUserList, dispatch),
    uiActions: bindActionCreators(uiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupChannel)
