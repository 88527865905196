import {Map} from 'immutable'
import {
  CHAT_CONNECT_SUCCESS,
  USER_PUSH_NOTIFICATION_SOUND_UPDATE,
  USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE,
  USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE,
  USER_PUSH_NOTIFICATION_SNOOZE_UPDATE,
  USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE,
  USER_UPDATE,
  USER_LOGOUT,
  USER_TOTAL_GROUP_CHANNEL_UPDATE
} from 'state/action-types'


export const initialState = Map({
})

export function user (state = initialState, action) {
  switch (action.type) {
    case CHAT_CONNECT_SUCCESS:
      return state.set("appId", action.user.appId)
                  .set("id", action.user.id)
                  .set("displayName", action.user.displayName)
                  .set("avatarUrl", action.user.avatarUploadUrl ===  '' ? action.user.avatarUrl : action.user.avatarUploadUrl,)
                  .set("profileUrl", action.user.profileUrl)
                  .set("firstName", action.user.firstName)
                  .set("lastName", action.user.lastName)
                  .set("email", action.user.email)
                  .set("phoneNumber", action.user.phoneNumber)
                  .set("data", action.user.data)
                  .set("isLoggedIn", true)
                  .set("metadata", action.user.metadata)

    case USER_UPDATE:
      return state.set("id", action.user.id)
                  .set("displayName", action.user.displayName)
                  .set("avatarUrl", action.user.avatarUploadUrl ===  '' ? action.user.avatarUrl : action.user.avatarUploadUrl,)
                  .set("profileUrl", action.user.profileUrl)
                  .set("firstName", action.user.firstName)
                  .set("lastName", action.user.lastName)
                  .set("email", action.user.email)
                  .set("phoneNumber", action.user.phoneNumber)
                  .set("data", action.user.data)
                  .set("metadata", action.user.metadata)

    case USER_PUSH_NOTIFICATION_SOUND_UPDATE:
      return state.set("sound", action.sound)
    
    case USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE:
      return state.set("pushTemplate", action.template)
    
    case USER_PUSH_NOTIFICATION_SNOOZE_UPDATE:
      return state.set("pushSnooze", action.snooze)
      
    case USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE:
      if(action.startDndHour === undefined){
        action.startDndHour = 22
      }

      if(action.startDndMin === undefined){
        action.startDndMin = 0
      }

      if(action.endDndHour === undefined){
        action.endDndHour = 8
      }

      if(action.endDndMin === undefined){
        action.endDndMin = 0
      }
      if(action.timezone === undefined){
        action.timezone = ((new Date()).getTimezoneOffset())/-60
      }
      else{
        let localValue = action.timezone.substr(3)
        let localValueHour = localValue.split(":")[0]
        let localValueMin = localValue.split(":")[1]/6
        action.timezone = Number(localValueHour + "." + localValueMin)
      }
      return state.set('dnd', action.dnd)
            .set('startDnd', action.startDndHour + ":" + action.startDndMin)
            .set('endDnd', action.endDndHour + ":" + action.endDndMin)
            .set('timezone', action.timezone)
    case USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE:
      return state.set("unreadMessageCount", action.count)
    
    case USER_TOTAL_GROUP_CHANNEL_UPDATE: 
      return state.set("totalGroupChannel", action.count) 
      
    case USER_LOGOUT: 
      return state.set("isLoggedIn", false)

    default:
      return state
  }
}
