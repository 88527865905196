import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Immutable from 'immutable'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/smartChat/actions'
import * as groupChannelsactions from 'state/groupChannels/actions'
import {  } from 'semantic-ui-react'
import config from 'config'
import WindowHeader from 'containers/ChatApp/Components/WindowHeader'
import WindowContent from 'containers/ChatApp/Components/WindowContent'
import WindowFooter from 'containers/ChatApp/Components/WindowFooter'
import WindowDescription from 'containers/ChatApp/Components/WindowDescription'

class Window extends Component {

  state = {
    fileRef: null
  }

  handleFocus = () => {
    if(this.props.type === "group" && this.props.groupChannelsState.getIn([this.props.id, "state"]) === "OPEN"){
      let node = ReactDOM.findDOMNode(this);
      let contentNode = node.getElementsByClassName("cc-window-content")[0]
      // send read if scroll is at bottom
      if(contentNode.scrollTop + contentNode.clientHeight === contentNode.scrollHeight){
        this.props.groupChannelsactions.read(this.props.id)
      }
    }
  }

  render () {
    let popupRightAdjustment = "0";
    let customClass = "ifc-chat-frame-window"
    let visibleDescription = null
    let visibleContent = null;
    let visibleFooter = null;
    let mainClass = "cc-window-container";

    if(this.props.className){
      mainClass = mainClass + " cc-inbox-app-small"
    }

    //only for popup chat
    if(this.props.app.get("type") === "smartChat"){
      popupRightAdjustment = String(345*this.props.position + 20*(this.props.position + 1)) + "px"

      if(this.props.groupChannelsState.getIn([this.props.id, "state"]) === "MINIMIZE"){
        mainClass = "cc-window-container chatcamp-popup-minimize";

      }
    }

    if(this.props.app.get("type") === "sideChat"){
      popupRightAdjustment = String(0) + "px"
    }

    let styleHeader = {}
    if(config.getChatPosition() === "LEFT"){
      styleHeader = {
        left: popupRightAdjustment
      }
    }
    else{
      styleHeader = {
        right: popupRightAdjustment
      }
    }

    // custom window header
    let headerValue = false;
    if(typeof window.ChatCampUi.setChannelHeader === "function"){
      let id = this.props.id
      let channel = {}
      
      if(this.props.groupChannels.get(id) !== undefined){

        if(this.props.type === "group"){
          channel = this.props.groupChannels.get(id).toJS()
        }
        else if(this.props.type === "open"){
          channel = this.props.openChannels.get(id).toJS()
        }

        channel['type'] = this.props.type
        headerValue = window.ChatCampUi.setChannelHeader(channel)
      }
    }

    if(!this.props.loading) {
      if(this.props.groupChannelsState.getIn([this.props.id, "state"]) === "OPEN" ){
        visibleDescription = <WindowDescription id ={this.props.id} type = {this.props.type} data = {headerValue}/>
        visibleContent = <WindowContent id = {this.props.id} type = {this.props.type} state = {this.props.state} />
        visibleFooter = <WindowFooter id = {this.props.id} type = {this.props.type} setFileRef = {(ref) => {this.setState({fileRef: ref})}} />
      }
      if(this.props.state === "minimize"){
        customClass = customClass + " ifc-chat-frame-window-min"
      }

    }
    return (
      <div onMouseEnter={this.handleFocus} style={styleHeader} className={mainClass}>
        <WindowHeader id = {this.props.id} type = {this.props.type} state = {this.props.state} fileRef = {this.state.fileRef}/>
        {visibleDescription}
        {visibleContent}
        {visibleFooter}
      </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    groupChannelsactions: bindActionCreators(groupChannelsactions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Window)
