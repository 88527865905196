export const ICONS = {
  CLOSE: "M 14.40625 13 L 13 14.40625 L 23.625 25 L 13 35.59375 L 14.40625 37 L 25.0625 26.40625 L 35.6875 37 L 37.09375 35.59375 L 26.46875 25 L 37.09375 14.40625 L 35.6875 13 L 25.0625 23.59375 Z ",

  MINIMIZE: "M 5 11 L 5 13 L 19 13 L 19 11 Z ",

  HASHTAG: "M 18 9 L 17.25 17 L 10 17 L 9.8125 19 L 17.0625 19 L 15.84375 32 L 8.59375 32 L 8.40625 34 L 15.65625 34 L 15 41 L 17 41 L 17.65625 34 L 30.65625 34 L 30 41 L 32 41 L 32.65625 34 L 40.40625 34 L 40.59375 32 L 32.84375 32 L 34.0625 19 L 41.8125 19 L 42 17 L 34.25 17 L 35 9 L 33 9 L 32.25 17 L 19.25 17 L 20 9 Z M 19.0625 19 L 32.0625 19 L 30.84375 32 L 17.84375 32 Z ",

  MAXIMIZE: "M 13 6.171875 L 3.585938 15.585938 L 6.414063 18.414063 L 13 11.828125 L 19.585938 18.414063 L 22.414063 15.585938 Z ",

  CREATE: "M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z",

  ATTACH: "M 20.298828 3.0175781 C 18.825512 3.0175781 17.353643 3.60127 16.25 4.765625 L 8.2421875 13.212891 C 5.2480565 16.372538 5.2480565 21.451681 8.2421875 24.611328 A 1.0001 1.0001 0 0 0 8.5683594 24.839844 A 1.0001 1.0001 0 0 0 8.5742188 24.841797 C 11.590859 27.700431 16.296353 27.668482 19.193359 24.611328 L 24.826172 18.667969 A 1.0001 1.0001 0 1 0 23.375 17.292969 L 17.742188 23.236328 C 15.49041 25.612584 11.94709 25.612584 9.6953125 23.236328 C 7.4434435 20.859975 7.4434435 16.964243 9.6953125 14.587891 L 17.701172 6.140625 C 19.165887 4.5953351 21.433723 4.5953351 22.898438 6.140625 C 24.363005 7.6857612 24.363005 10.236114 22.898438 11.78125 L 15.611328 19.470703 C 14.932427 20.186459 13.941424 20.185929 13.263672 19.470703 C 12.585909 18.755466 12.585909 17.551174 13.263672 16.835938 L 19.125 10.650391 A 1.0001 1.0001 0 1 0 17.673828 9.2753906 L 11.810547 15.460938 C 10.39031 16.959701 10.39031 19.34694 11.810547 20.845703 A 1.0001 1.0001 0 0 0 12.146484 21.078125 C 13.587125 22.281634 15.739203 22.240836 17.0625 20.845703 L 24.349609 13.15625 C 26.465965 10.923472 26.496408 7.4292098 24.554688 5.0898438 A 1.0001 1.0001 0 0 0 24.349609 4.765625 C 23.245967 3.60127 21.772145 3.0175781 20.298828 3.0175781 z",

  SMILEY: "M 25 2 A 1.0001 1.0001 0 0 0 24.845703 2.015625 C 12.227069 2.100968 2 12.361472 2 25 C 2 37.691367 12.308633 48 25 48 C 37.691367 48 48 37.691367 48 25 C 48 12.362796 37.775013 2.1030941 25.158203 2.015625 A 1.0001 1.0001 0 0 0 25 2 z M 25 4 C 36.610633 4 46 13.389367 46 25 C 46 36.610633 36.610633 46 25 46 C 13.389367 46 4 36.610633 4 25 C 4 13.389367 13.389367 4 25 4 z M 17 18 A 3 3 0 0 0 14 21 A 3 3 0 0 0 17 24 A 3 3 0 0 0 20 21 A 3 3 0 0 0 17 18 z M 33 18 A 3 3 0 0 0 30 21 A 3 3 0 0 0 33 24 A 3 3 0 0 0 36 21 A 3 3 0 0 0 33 18 z M 11.957031 28.988281 A 1.0001 1.0001 0 0 0 11.185547 30.582031 C 11.185547 30.582031 16.416667 38 25 38 C 33.583333 38 38.814453 30.582031 38.814453 30.582031 A 1.0010463 1.0010463 0 0 0 37.185547 29.417969 C 37.185547 29.417969 32.416667 36 25 36 C 17.583333 36 12.814453 29.417969 12.814453 29.417969 A 1.0001 1.0001 0 0 0 11.957031 28.988281 z",

  SEND: "M 5 3 A 1 1 0 0 0 4 4 A 1 1 0 0 0 4.0371094 4.2675781 A 1 1 0 0 0 4.0390625 4.2734375 L 5 11 L 2 13 L 23 15 L 2 17 L 5 19 L 4.0390625 25.728516 A 1 1 0 0 0 4 26 A 1 1 0 0 0 5 27 A 1 1 0 0 0 5.3867188 26.919922 L 5.3886719 26.921875 L 5.4316406 26.900391 L 5.453125 26.890625 A 1 1 0 0 0 5.4707031 26.880859 L 27.390625 15.921875 L 27.392578 15.917969 A 1 1 0 0 0 28 15 A 1 1 0 0 0 27.390625 14.080078 A 1 1 0 0 0 27.390625 14.078125 L 27.388672 14.078125 L 5.4785156 3.1230469 A 1 1 0 0 0 5.3886719 3.078125 A 1 1 0 0 0 5 3 z",

  CHAT: "M 7 8 C 4.253906 8 2 10.253906 2 13 L 2 37 C 2 39.746094 4.253906 42 7 42 L 11.09375 42 C 11.230469 43.203125 11.214844 44.316406 10.90625 45.25 C 10.527344 46.398438 9.820313 47.363281 8.5 48.15625 C 8.128906 48.390625 7.957031 48.839844 8.070313 49.261719 C 8.183594 49.683594 8.5625 49.984375 9 50 C 13.242188 50 18.105469 47.785156 20.5625 42 L 43 42 C 45.746094 42 48 39.746094 48 37 L 48 13 C 48 10.253906 45.746094 8 43 8 Z M 7 10 L 43 10 C 44.65625 10 46 11.34375 46 13 L 46 37 C 46 38.65625 44.65625 40 43 40 L 20 40 C 19.582031 40 19.207031 40.261719 19.0625 40.65625 C 17.507813 44.898438 14.730469 46.917969 11.84375 47.65625 C 12.234375 47.097656 12.605469 46.507813 12.8125 45.875 C 13.332031 44.296875 13.289063 42.570313 12.96875 40.8125 C 12.878906 40.347656 12.476563 40.007813 12 40 L 7 40 C 5.34375 40 4 38.65625 4 37 L 4 13 C 4 11.34375 5.34375 10 7 10 Z M 15 22 C 13.355469 22 12 23.355469 12 25 C 12 26.644531 13.355469 28 15 28 C 16.644531 28 18 26.644531 18 25 C 18 23.355469 16.644531 22 15 22 Z M 25 22 C 23.355469 22 22 23.355469 22 25 C 22 26.644531 23.355469 28 25 28 C 26.644531 28 28 26.644531 28 25 C 28 23.355469 26.644531 22 25 22 Z M 35 22 C 33.355469 22 32 23.355469 32 25 C 32 26.644531 33.355469 28 35 28 C 36.644531 28 38 26.644531 38 25 C 38 23.355469 36.644531 22 35 22 Z M 15 24 C 15.5625 24 16 24.4375 16 25 C 16 25.5625 15.5625 26 15 26 C 14.4375 26 14 25.5625 14 25 C 14 24.4375 14.4375 24 15 24 Z M 25 24 C 25.5625 24 26 24.4375 26 25 C 26 25.5625 25.5625 26 25 26 C 24.4375 26 24 25.5625 24 25 C 24 24.4375 24.4375 24 25 24 Z M 35 24 C 35.5625 24 36 24.4375 36 25 C 36 25.5625 35.5625 26 35 26 C 34.4375 26 34 25.5625 34 25 C 34 24.4375 34.4375 24 35 24 Z ",

  STATUS:"M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
}
