export default [
    {
      key: 'Niue',
      text: '(UTC - 11:00) Pacific/Niue',
      value: -11,
    },
    {
      key: 'Pago Pago',
      text: '(UTC - 11:00) Pacific/Pago_Pago',
      value: -11,
    },
    {
      key: 'Hawaii Time',
      text: '(UTC - 10:00) Pacific/Honolulu',
      value: -10,
    },
    {
      key: 'Rarotonga',
      text: '(UTC - 10:00) Pacific/Rarotonga',
      value: -10,
    },
    {
      key: 'Tahiti',
      text: '(UTC - 10:00) Pacific/Tahiti',
      value: -10,
    },
    {
      key: 'Marquesas',
      text: '(UTC - 9:30) Pacific/Marquesas',
      value: -9.5,
    },
    {
      key: 'Gambier',
      text: '(UTC - 9:00) Pacific/Gambier',
      value: -9,
    },
    {
      key: 'Alaska Time',
      text: '(UTC - 8:00) America/Anchorage',
      value: -8,
    },
    {
      key: 'Pitcairn',
      text: '(UTC - 8:00) Pacific/Pitcairn',
      value: -8,
    },
    {
      key: 'Dawson',
      text: '(UTC - 7:00) America/Dawson',
      value: -7,
    },
    {
      key: 'Mountain Time - Arizona',
      text: '(UTC - 7:00) America/Phoenix',
      value: -7,
    },
    {
      key: 'Mountain Time - Dawson Creek',
      text: '(UTC - 7:00) America/Dawson_Creek',
      value: -7,
    },
    {
      key: 'Mountain Time - Hermosillo',
      text: '(UTC - 7:00) America/Hermosillo',
      value: -7,
    },
    {
      key: 'Pacific Time',
      text: '(UTC - 7:00) America/Los_Angeles',
      value: -7,
    },
    {
      key: 'Pacific Time - Tijuana',
      text: '(UTC - 7:00) America/Tijuana',
      value: -7,
    },
    {
      key: 'Pacific Time - Vancouver',
      text: '(UTC - 7:00) America/Vancouver',
      value: -7,
    },
    {
      key: 'Pacific Time - Whitehorse',
      text: '(UTC - 7:00) America/Whitehorse',
      value: -7,
    },
    {
      key: 'Belize',
      text: '(UTC - 6:00) America/Belize',
      value: -6,
    },
    {
      key: 'Boise',
      text: '(UTC - 6:00) America/Boise',
      value: -6,
    },
    {
      key: 'Central Time - Regina',
      text: '(UTC - 6:00) America/Regina',
      value: -6,
    },
    {
      key: 'Central Time - Tegucigalpa',
      text: '(UTC - 6:00) America/Tegucigalpa',
      value: -6,
    },
    {
      key: 'Costa Rica',
      text: '(UTC - 6:00) America/Costa_Rica',
      value: -6,
    },
    {
      key: 'Easter Island',
      text: '(UTC - 6:00) Pacific/Easter',
      value: -6,
    },
    {
      key: 'El Salvador',
      text: '(UTC - 6:00) America/El_Salvador',
      value: -6,
    },
    {
      key: 'Galapagos',
      text: '(UTC - 6:00) Pacific/Galapagos',
      value: -6,
    },
    {
      key: 'Guatemala',
      text: '(UTC - 6:00) America/Guatemala',
      value: -6,
    },
    {
      key: 'Managua',
      text: '(UTC - 6:00) America/Managua',
      value: -6,
    },
    {
      key: 'Mountain Time',
      text: '(UTC - 6:00) America/Denver',
      value: -6,
    },
    {
      key: 'Mountain Time - Chihuahua, Mazatlan',
      text: '(UTC - 6:00) America/Mazatlan',
      value: -6,
    },
    {
      key: 'Mountain Time - Edmonton',
      text: '(UTC - 6:00) America/Edmonton',
      value: -6,
    },
    {
      key: 'Mountain Time - Yellowknife',
      text: '(UTC - 6:00) America/Yellowknife',
      value: -6,
    },
    {
      key: 'America Cancun',
      text: '(UTC - 5:00) America/Cancun',
      value: -5,
    },
    {
      key: 'Bogota',
      text: '(UTC - 5:00) America/Bogota',
      value: -5,
    },
    {
      key: 'Central Time',
      text: '(UTC - 5:00) America/Chicago',
      value: -5,
    },
    {
      key: 'Central Time - Mexico City',
      text: '(UTC - 5:00) America/Mexico_City',
      value: -5,
    },
    {
      key: 'Central Time - Winnipeg',
      text: '(UTC - 5:00) America/Winnipeg',
      value: -5,
    },
    {
      key: 'Guayaquil',
      text: '(UTC - 5:00) America/Guayaquil',
      value: -5,
    },
    {
      key: 'Jamaica',
      text: '(UTC - 5:00) America/Jamaica',
      value: -5,
    },
    {
      key: 'Lima',
      text: '(UTC - 5:00) America/Lima',
      value: -5,
    },
    {
      key: 'Panama',
      text: '(UTC - 5:00) America/Panama',
      value: -5,
    },
    {
      key: 'Rio Branco',
      text: '(UTC - 5:00) America/Rio_Branco',
      value: -5,
    },
    {
      key: 'Asuncion',
      text: '(UTC - 4:00) America/Asuncion',
      value: -4,
    },
    {
      key: 'Barbados',
      text: '(UTC - 4:00) America/Barbados',
      value: -4,
    },
    {
      key: 'Boa Vista',
      text: '(UTC - 4:00) America/Boa_Vista',
      value: -4,
    },
    {
      key: 'Campo Grande',
      text: '(UTC - 4:00) America/Campo_Grande',
      value: -4,
    },
    {
      key: 'Caracas',
      text: '(UTC - 4:00) America/Caracas',
      value: -4,
    },
    {
      key: 'Cuiaba',
      text: '(UTC - 4:00) America/Cuiaba',
      value: -4,
    },
    {
      key: 'Curacao',
      text: '(UTC - 4:00) America/Curacao',
      value: -4,
    },
    {
      key: 'Detroit',
      text: '(UTC - 4:00) America/Detroit',
      value: -4,
    },
    {
      key: 'Eastern Time',
      text: '(UTC - 4:00) America/New_York',
      value: -4,
    },
    {
      key: 'Eastern Time - Iqaluit',
      text: '(UTC - 4:00) America/Iqaluit',
      value: -4,
    },
    {
      key: 'Eastern Time - Toronto',
      text: '(UTC - 4:00) America/Toronto',
      value: -4,
    },
    {
      key: 'Grand Turk',
      text: '(UTC - 4:00) America/Grand_Turk',
      value: -4,
    },
    {
      key: 'Guyana',
      text: '(UTC - 4:00) America/Guyana',
      value: -4,
    },
    {
      key: 'Havana',
      text: '(UTC - 4:00) America/Havana',
      value: -4,
    },
    {
      key: 'La Paz',
      text: '(UTC - 4:00) America/La_Paz',
      value: -4,
    },
    {
      key: 'Manaus',
      text: '(UTC - 4:00) America/Manaus',
      value: -4,
    },
    {
      key: 'Martinique',
      text: '(UTC - 4:00) America/Martinique',
      value: -4,
    },
    {
      key: 'Nassau',
      text: '(UTC - 4:00) America/Nassau',
      value: -4,
    },
    {
      key: 'Port of Spain',
      text: '(UTC - 4:00) America/Port_of_Spain',
      value: -4,
    },
    {
      key: 'Port-au-Prince',
      text: '(UTC - 4:00) America/Port-au-Prince',
      value: -4,
    },
    {
      key: 'Porto Velho',
      text: '(UTC - 4:00) America/Porto_Velho',
      value: -4,
    },
    {
      key: 'Puerto Rico',
      text: '(UTC - 4:00) America/Puerto_Rico',
      value: -4,
    },
    {
      key: 'Santiago',
      text: '(UTC - 4:00) America/Santiago',
      value: -4,
    },
    {
      key: 'Santo Domingo',
      text: '(UTC - 4:00) America/Santo_Domingo',
      value: -4,
    },
    {
      key: 'Araguaina',
      text: '(UTC - 3:00) America/Araguaina',
      value: -3,
    },
    {
      key: 'Atlantic Time - Halifax',
      text: '(UTC - 3:00) America/Halifax',
      value: -3,
    },
    {
      key: 'Belem',
      text: '(UTC - 3:00) America/Belem',
      value: -3,
    },
    {
      key: 'Bermuda',
      text: '(UTC - 3:00) Atlantic/Bermuda',
      value: -3,
    },
    {
      key: 'Buenos Aires',
      text: '(UTC - 3:00) America/Argentina/Buenos_Aires',
      value: -3,
    },
    {
      key: 'Cayenne',
      text: '(UTC - 3:00) America/Cayenne',
      value: -3,
    },
    {
      key: 'Cordoba',
      text: '(UTC - 3:00) America/Argentina/Cordoba',
      value: -3,
    },
    {
      key: 'Fortaleza',
      text: '(UTC - 3:00) America/Fortaleza',
      value: -3,
    },
    {
      key: 'Maceio',
      text: '(UTC - 3:00) America/Maceio',
      value: -3,
    },
    {
      key: 'Montevideo',
      text: '(UTC - 3:00) America/Montevideo',
      value: -3,
    },
    {
      key: 'Palmer',
      text: '(UTC - 3:00) Antarctica/Palmer',
      value: -3,
    },
    {
      key: 'Paramaribo',
      text: '(UTC - 3:00) America/Paramaribo',
      value: -3,
    },
    {
      key: 'Punta Arenas',
      text: '(UTC - 3:00) America/Punta_Arenas',
      value: -3,
    },
    {
      key: 'Recife',
      text: '(UTC - 3:00) America/Recife',
      value: -3,
    },
    {
      key: 'Rothera',
      text: '(UTC - 3:00) Antarctica/Rothera',
      value: -3,
    },
    {
      key: 'Salvador',
      text: '(UTC - 3:00) America/Bahia',
      value: -3,
    },
    {
      key: 'Sao Paulo',
      text: '(UTC - 3:00) America/Sao_Paulo',
      value: -3,
    },
    {
      key: 'Stanley',
      text: '(UTC - 3:00) Atlantic/Stanley',
      value: -3,
    },
    {
      key: 'Thule',
      text: '(UTC - 3:00) America/Thule',
      value: -3,
    },
    {
      key: 'Newfoundland Time - St. Johns',
      text: '(UTC - 2:30) America/St_Johns',
      value: -2.5,
    },
    {
      key: 'Godthab',
      text: '(UTC - 2:00) America/Godthab',
      value: -2,
    },
    {
      key: 'Miquelon',
      text: '(UTC - 2:00) America/Miquelon',
      value: -2,
    },
    {
      key: 'Noronha',
      text: '(UTC - 2:00) America/Noronha',
      value: -2,
    },
    {
      key: 'South Georgia',
      text: '(UTC - 2:00) Atlantic/South_Georgia',
      value: -2,
    },
    {
      key: 'Cape Verde',
      text: '(UTC - 1:00) Atlantic/Cape_Verde',
      value: -1,
    },
    {
      key: 'Abidjan',
      text: '(UTC) Africa/Abidjan',
      value: 0,
    },
    {
      key: 'Accra',
      text: '(UTC) Africa/Accra',
      value: 0,
    },
    {
      key: 'Azores',
      text: '(UTC) Atlantic/Azores',
      value: 0,
    },
    {
      key: 'Bissau',
      text: '(UTC) Africa/Bissau',
      value: 0,
    },
    {
      key: 'Casablanca',
      text: '(UTC) Africa/Casablanca',
      value: 0,
    },
    {
      key: 'Danmarkshavn',
      text: '(UTC) America/Danmarkshavn',
      value: 0,
    },
    {
      key: 'El Aaiun',
      text: '(UTC) Africa/El_Aaiun',
      value: 0,
    },
    {
      key: 'GMT (no daylight saving)',
      text: 'Etc/GMT',
      value: 0,
    },
    {
      key: 'Monrovia',
      text: '(UTC) Africa/Monrovia',
      value: 0,
    },
    {
      key: 'Reykjavik',
      text: '(UTC) Atlantic/Reykjavik',
      value: 0,
    },
    {
      key: 'Scoresbysund',
      text: '(UTC) America/Scoresbysund',
      value: 0,
    },
    {
      key: 'UTC',
      text: 'UTC',
      value: null,
    },
    {
      key: 'Algiers',
      text: '(UTC + 1:00) Africa/Algiers',
      value: 1,
    },
    {
      key: 'Canary Islands',
      text: '(UTC + 1:00) Atlantic/Canary',
      value: 1,
    },
    {
      key: 'Dublin',
      text: '(UTC + 1:00) Europe/Dublin',
      value: 1,
    },
    {
      key: 'Faeroe',
      text: '(UTC + 1:00) Atlantic/Faroe',
      value: 1,
    },
    {
      key: 'Lagos',
      text: '(UTC + 1:00) Africa/Lagos',
      value: 1,
    },
    {
      key: 'Lisbon',
      text: '(UTC + 1:00) Europe/Lisbon',
      value: 1,
    },
    {
      key: 'London',
      text: '(UTC + 1:00) Europe/London',
      value: 1,
    },
    {
      key: 'Ndjamena',
      text: '(UTC + 1:00) Africa/Ndjamena',
      value: 1,
    },
    {
      key: 'Sao Tome',
      text: '(UTC + 1:00) Africa/Sao_Tome',
      value: 1,
    },
    {
      key: 'Tunis',
      text: '(UTC + 1:00) Africa/Tunis',
      value: 1,
    },
    {
      key: 'Amsterdam',
      text: '(UTC + 2:00) Europe/Amsterdam',
      value: 2,
    },
    {
      key: 'Andorra',
      text: '(UTC + 2:00) Europe/Andorra',
      value: 2,
    },
    {
      key: 'Berlin',
      text: '(UTC + 2:00) Europe/Berlin',
      value: 2,
    },
    {
      key: 'Brussels',
      text: '(UTC + 2:00) Europe/Brussels',
      value: 2,
    },
    {
      key: 'Budapest',
      text: '(UTC + 2:00) Europe/Budapest',
      value: 2,
    },
    {
      key: 'Cairo',
      text: '(UTC + 2:00) Africa/Cairo',
      value: 2,
    },
    {
      key: 'Central European Time - Belgrade',
      text: '(UTC + 2:00) Europe/Belgrade',
      value: 2,
    },
    {
      key: 'Central European Time - Prague',
      text: '(UTC + 2:00) Europe/Prague',
      value: 2,
    },
    {
      key: 'Ceuta',
      text: '(UTC + 2:00) Africa/Ceuta',
      value: 2,
    },
    {
      key: 'Copenhagen',
      text: '(UTC + 2:00) Europe/Copenhagen',
      value: 2,
    },
    {
      key: 'Gibraltar',
      text: '(UTC + 2:00) Europe/Gibraltar',
      value: 2,
    },
    {
      key: 'Johannesburg',
      text: '(UTC + 2:00) Africa/Johannesburg',
      value: 2,
    },
    {
      key: 'Khartoum',
      text: '(UTC + 2:00) Africa/Khartoum',
      value: 2,
    },
    {
      key: 'Luxembourg',
      text: '(UTC + 2:00) Europe/Luxembourg',
      value: 2,
    },
    {
      key: 'Madrid',
      text: '(UTC + 2:00) Europe/Madrid',
      value: 2,
    },
    {
      key: 'Malta',
      text: '(UTC + 2:00) Europe/Malta',
      value: 2,
    },
    {
      key: 'Maputo',
      text: '(UTC + 2:00) Africa/Maputo',
      value: 2,
    },
    {
      key: 'Monaco',
      text: '(UTC + 2:00) Europe/Monaco',
      value: 2,
    },
    {
      key: 'Moscow-01 - Kaliningrad',
      text: '(UTC + 2:00) Europe/Kaliningrad',
      value: 2,
    },
    {
      key: 'Oslo',
      text: '(UTC + 2:00) Europe/Oslo',
      value: 2,
    },
    {
      key: 'Paris',
      text: '(UTC + 2:00) Europe/Paris',
      value: 2,
    },
    {
      key: 'Rome',
      text: '(UTC + 2:00) Europe/Rome',
      value: 2,
    },
    {
      key: 'Stockholm',
      text: '(UTC + 2:00) Europe/Stockholm',
      value: 2,
    },
    {
      key: 'Tirane',
      text: '(UTC + 2:00) Europe/Tirane',
      value: 2,
    },
    {
      key: 'Tripoli',
      text: '(UTC + 2:00) Africa/Tripoli',
      value: 2,
    },
    {
      key: 'Vienna',
      text: '(UTC + 2:00) Europe/Vienna',
      value: 2,
    },
    {
      key: 'Warsaw',
      text: '(UTC + 2:00) Europe/Warsaw',
      value: 2,
    },
    {
      key: 'Windhoek',
      text: '(UTC + 2:00) Africa/Windhoek',
      value: 2,
    },
    {
      key: 'Zurich',
      text: '(UTC + 2:00) Europe/Zurich',
      value: 2,
    },
    {
      key: 'Amman',
      text: '(UTC + 3:00) Asia/Amman',
      value: 3,
    },
    {
      key: 'Athens',
      text: '(UTC + 3:00) Europe/Athens',
      value: 3,
    },
    {
      key: 'Baghdad',
      text: '(UTC + 3:00) Asia/Baghdad',
      value: 3,
    },
    {
      key: 'Beirut',
      text: '(UTC + 3:00) Asia/Beirut',
      value: 3,
    },
    {
      key: 'Bucharest',
      text: '(UTC + 3:00) Europe/Bucharest',
      value: 3,
    },
    {
      key: 'Chisinau',
      text: '(UTC + 3:00) Europe/Chisinau',
      value: 3,
    },
    {
      key: 'Damascus',
      text: '(UTC + 3:00) Asia/Damascus',
      value: 3,
    },
    {
      key: 'Gaza',
      text: '(UTC + 3:00) Asia/Gaza',
      value: 3,
    },
    {
      key: 'Helsinki',
      text: '(UTC + 3:00) Europe/Helsinki',
      value: 3,
    },
    {
      key: 'Istanbul',
      text: '(UTC + 3:00) Europe/Istanbul',
      value: 3,
    },
    {
      key: 'Jerusalem',
      text: '(UTC + 3:00) Asia/Jerusalem',
      value: 3,
    },
    {
      key: 'Kiev',
      text: '(UTC + 3:00) Europe/Kiev',
      value: 3,
    },
    {
      key: 'Minsk',
      text: '(UTC + 3:00) Europe/Minsk',
      value: 3,
    },
    {
      key: 'Moscow+00 - Moscow',
      text: '(UTC + 3:00) Europe/Moscow',
      value: 3,
    },
    {
      key: 'Nairobi',
      text: '(UTC + 3:00) Africa/Nairobi',
      value: 3,
    },
    {
      key: 'Nicosia',
      text: '(UTC + 3:00) Asia/Nicosia',
      value: 3,
    },
    {
      key: 'Qatar',
      text: '(UTC + 3:00) Asia/Qatar',
      value: 3,
    },
    {
      key: 'Riga',
      text: '(UTC + 3:00) Europe/Riga',
      value: 3,
    },
    {
      key: 'Riyadh',
      text: '(UTC + 3:00) Asia/Riyadh',
      value: 3,
    },
    {
      key: 'Sofia',
      text: '(UTC + 3:00) Europe/Sofia',
      value: 3,
    },
    {
      key: 'Syowa',
      text: '(UTC + 3:00) Antarctica/Syowa',
      value: 3,
    },
    {
      key: 'Tallinn',
      text: '(UTC + 3:00) Europe/Tallinn',
      value: 3,
    },
    {
      key: 'Vilnius',
      text: '(UTC + 3:00) Europe/Vilnius',
      value: 3,
    },
    {
      key: 'Baku',
      text: '(UTC + 4:00) Asia/Baku',
      value: 4,
    },
    {
      key: 'Dubai',
      text: '(UTC + 4:00) Asia/Dubai',
      value: 4,
    },
    {
      key: 'Mahe',
      text: '(UTC + 4:00) Indian/Mahe',
      value: 4,
    },
    {
      key: 'Mauritius',
      text: '(UTC + 4:00) Indian/Mauritius',
      value: 4,
    },
    {
      key: 'Moscow+01 - Samara',
      text: '(UTC + 4:00) Europe/Samara',
      value: 4,
    },
    {
      key: 'Reunion',
      text: '(UTC + 4:00) Indian/Reunion',
      value: 4,
    },
    {
      key: 'Tbilisi',
      text: '(UTC + 4:00) Asia/Tbilisi',
      value: 4,
    },
    {
      key: 'Yerevan',
      text: '(UTC + 4:00) Asia/Yerevan',
      value: 4,
    },
    {
      key: 'Kabul',
      text: '(UTC + 4:30) Asia/Kabul',
      value: 4.5,
    },
    {
      key: 'Tehran',
      text: '(UTC + 4:30) Asia/Tehran',
      value: 4.5,
    },
    {
      key: 'Aqtau',
      text: '(UTC + 5:00) Asia/Aqtau',
      value: 5,
    },
    {
      key: 'Aqtobe',
      text: '(UTC + 5:00) Asia/Aqtobe',
      value: 5,
    },
    {
      key: 'Ashgabat',
      text: '(UTC + 5:00) Asia/Ashgabat',
      value: 5,
    },
    {
      key: 'Dushanbe',
      text: '(UTC + 5:00) Asia/Dushanbe',
      value: 5,
    },
    {
      key: 'Karachi',
      text: '(UTC + 5:00) Asia/Karachi',
      value: 5,
    },
    {
      key: 'Kerguelen',
      text: '(UTC + 5:00) Indian/Kerguelen',
      value: 5,
    },
    {
      key: 'Maldives',
      text: '(UTC + 5:00) Indian/Maldives',
      value: 5,
    },
    {
      key: 'Mawson',
      text: '(UTC + 5:00) Antarctica/Mawson',
      value: 5,
    },
    {
      key: 'Moscow+02 - Yekaterinburg',
      text: '(UTC + 5:00) Asia/Yekaterinburg',
      value: 5,
    },
    {
      key: 'Tashkent',
      text: '(UTC + 5:00) Asia/Tashkent',
      value: 5,
    },
    {
      key: 'Colombo',
      text: '(UTC + 5:30) Asia/Colombo',
      value: 5.5,
    },
    {
      key: 'India Standard Time',
      text: '(UTC + 5:30) Asia/Calcutta',
      value: 5.5,
    },
    {
      key: 'Katmandu',
      text: '(UTC + 5:45) Asia/Katmandu',
      value: 5.75,
    },
    {
      key: 'Almaty',
      text: '(UTC + 6:00) Asia/Almaty',
      value: 6,
    },
    {
      key: 'Bishkek',
      text: '(UTC + 6:00) Asia/Bishkek',
      value: 6,
    },
    {
      key: 'Chagos',
      text: '(UTC + 6:00) Indian/Chagos',
      value: 6,
    },
    {
      key: 'Dhaka',
      text: '(UTC + 6:00) Asia/Dhaka',
      value: 6,
    },
    {
      key: 'Moscow+03 - Omsk',
      text: '(UTC + 6:00) Asia/Omsk',
      value: 6,
    },
    {
      key: 'Thimphu',
      text: '(UTC + 6:00) Asia/Thimphu',
      value: 6,
    },
    {
      key: 'Vostok',
      text: '(UTC + 6:00) Antarctica/Vostok',
      value: 6,
    },
    {
      key: 'Cocos',
      text: '(UTC + 6:30) Indian/Cocos',
      value: 6.5,
    },
    {
      key: 'Rangoon',
      text: '(UTC + 6:30) Asia/Yangon',
      value: 6.5,
    },
    {
      key: 'Bangkok',
      text: '(UTC + 7:00) Asia/Bangkok',
      value: 7,
    },
    {
      key: 'Christmas',
      text: '(UTC + 7:00) Indian/Christmas',
      value: 7,
    },
    {
      key: 'Davis',
      text: '(UTC + 7:00) Antarctica/Davis',
      value: 7,
    },
    {
      key: 'Hanoi',
      text: '(UTC + 7:00) Asia/Saigon',
      value: 7,
    },
    {
      key: 'Hovd',
      text: '(UTC + 7:00) Asia/Hovd',
      value: 7,
    },
    {
      key: 'Jakarta',
      text: '(UTC + 7:00) Asia/Jakarta',
      value: 7,
    },
    {
      key: 'Moscow+04 - Krasnoyarsk',
      text: '(UTC + 7:00) Asia/Krasnoyarsk',
      value: 7,
    },
    {
      key: 'Brunei',
      text: '(UTC + 8:00) Asia/Brunei',
      value: 8,
    },
    {
      key: 'Casey',
      text: '(UTC + 8:00) Antarctica/Casey',
      value: 8,
    },
    {
      key: 'China Time - Beijing',
      text: '(UTC + 8:00) Asia/Shanghai',
      value: 8,
    },
    {
      key: 'Choibalsan',
      text: '(UTC + 8:00) Asia/Choibalsan',
      value: 8,
    },
    {
      key: 'Hong Kong',
      text: '(UTC + 8:00) Asia/Hong_Kong',
      value: 8,
    },
    {
      key: 'Kuala Lumpur',
      text: '(UTC + 8:00) Asia/Kuala_Lumpur',
      value: 8,
    },
    {
      key: 'Macau',
      text: '(UTC + 8:00) Asia/Macau',
      value: 8,
    },
    {
      key: 'Makassar',
      text: '(UTC + 8:00) Asia/Makassar',
      value: 8,
    },
    {
      key: 'Manila',
      text: '(UTC + 8:00) Asia/Manila',
      value: 8,
    },
    {
      key: 'Moscow+05 - Irkutsk',
      text: '(UTC + 8:00) Asia/Irkutsk',
      value: 8,
    },
    {
      key: 'Singapore',
      text: '(UTC + 8:00) Asia/Singapore',
      value: 8,
    },
    {
      key: 'Taipei',
      text: '(UTC + 8:00) Asia/Taipei',
      value: 8,
    },
    {
      key: 'Ulaanbaatar',
      text: '(UTC + 8:00) Asia/Ulaanbaatar',
      value: 8,
    },
    {
      key: 'Western Time - Perth',
      text: '(UTC + 8:00) Australia/Perth',
      value: 8,
    },
    {
      key: 'Dili',
      text: '(UTC + 9:00) Asia/Dili',
      value: 9,
    },
    {
      key: 'Jayapura',
      text: '(UTC + 9:00) Asia/Jayapura',
      value: 9,
    },
    {
      key: 'Moscow+06 - Yakutsk',
      text: '(UTC + 9:00) Asia/Yakutsk',
      value: 9,
    },
    {
      key: 'Palau',
      text: '(UTC + 9:00) Pacific/Palau',
      value: 9,
    },
    {
      key: 'Pyongyang',
      text: '(UTC + 9:00) Asia/Pyongyang',
      value: 9,
    },
    {
      key: 'Seoul',
      text: '(UTC + 9:00) Asia/Seoul',
      value: 9,
    },
    {
      key: 'Tokyo',
      text: '(UTC + 9:00) Asia/Tokyo',
      value: 9,
    },
    {
      key: 'Central Time - Adelaide',
      text: '(UTC + 9:30) Australia/Adelaide',
      value: 9.5,
    },
    {
      key: 'Central Time - Darwin',
      text: '(UTC + 9:30) Australia/Darwin',
      value: 9.5,
    },
    {
      key: "Dumont D'Urville",
      text: '(UTC + 10:00) Antarctica/DumontDUrville',
      value: 10,
    },
    {
      key: 'Eastern Time - Brisbane',
      text: '(UTC + 10:00) Australia/Brisbane',
      value: 10,
    },
    {
      key: 'Eastern Time - Hobart',
      text: '(UTC + 10:00) Australia/Hobart',
      value: 10,
    },
    {
      key: 'Eastern Time - Melbourne',
      text: '(UTC + 10:00) Australia/Melbourne',
      value: 10,
    },
    {
      key: 'Eastern Time - Melbourne, Sydney',
      text: '(UTC + 10:00) Australia/Sydney',
      value: 10,
    },
    {
      key: 'Guam',
      text: '(UTC + 10:00) Pacific/Guam',
      value: 10,
    },
    {
      key: 'Moscow+07 - Vladivostok',
      text: '(UTC + 10:00) Asia/Vladivostok',
      value: 10,
    },
    {
      key: 'Port Moresby',
      text: '(UTC + 10:00) Pacific/Port_Moresby',
      value: 10,
    },
    {
      key: 'Truk',
      text: '(UTC + 10:00) Pacific/Chuuk',
      value: 10,
    },
    {
      key: 'Efate',
      text: '(UTC + 11:00) Pacific/Efate',
      value: 11,
    },
    {
      key: 'Guadalcanal',
      text: '(UTC + 11:00) Pacific/Guadalcanal',
      value: 11,
    },
    {
      key: 'Kosrae',
      text: '(UTC + 11:00) Pacific/Kosrae',
      value: 11,
    },
    {
      key: 'Moscow+08 - Magadan',
      text: '(UTC + 11:00) Asia/Magadan',
      value: 11,
    },
    {
      key: 'Norfolk',
      text: '(UTC + 11:00) Pacific/Norfolk',
      value: 11,
    },
    {
      key: 'Noumea',
      text: '(UTC + 11:00) Pacific/Noumea',
      value: 11,
    },
    {
      key: 'Ponape',
      text: '(UTC + 11:00) Pacific/Pohnpei',
      value: 11,
    },
    {
      key: 'Auckland',
      text: '(UTC + 12:00) Pacific/Auckland',
      value: 12,
    },
    {
      key: 'Fiji',
      text: '(UTC + 12:00) Pacific/Fiji',
      value: 12,
    },
    {
      key: 'Funafuti',
      text: '(UTC + 12:00) Pacific/Funafuti',
      value: 12,
    },
    {
      key: 'Kwajalein',
      text: '(UTC + 12:00) Pacific/Kwajalein',
      value: 12,
    },
    {
      key: 'Majuro',
      text: '(UTC + 12:00) Pacific/Majuro',
      value: 12,
    },
    {
      key: 'Moscow+09 - Petropavlovsk-Kamchatskiy',
      text: '(UTC + 12:00) Asia/Kamchatka',
      value: 12,
    },
    {
      key: 'Nauru',
      text: '(UTC + 12:00) Pacific/Nauru',
      value: 12,
    },
    {
      key: 'Tarawa',
      text: '(UTC + 12:00) Pacific/Tarawa',
      value: 12,
    },
    {
      key: 'Wake',
      text: '(UTC + 12:00) Pacific/Wake',
      value: 12,
    },
    {
      key: 'Wallis',
      text: '(UTC + 12:00) Pacific/Wallis',
      value: 12,
    },
    {
      key: 'Apia',
      text: '(UTC + 13:00) Pacific/Apia',
      value: 13,
    },
    {
      key: 'Enderbury',
      text: '(UTC + 13:00) Pacific/Enderbury',
      value: 13,
    },
    {
      key: 'Fakaofo',
      text: '(UTC + 13:00) Pacific/Fakaofo',
      value: 13,
    },
    {
      key: 'Tongatapu',
      text: '(UTC + 13:00) Pacific/Tongatapu',
      value: 13,
    },
    {
      key: 'Kiritimati',
      text: '(UTC + 14:00) Pacific/Kiritimati',
      value: 14,
    },
  ];