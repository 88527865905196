import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/groupChannelsState/actions'
import Toast from 'containers/ChatApp/Components/Toast'
import Window from 'containers/ChatApp/Components/Window'


class Embed extends Component {

  render () {
    let windows = [];
    let number = 0;
    let id = this.props.groupChannelsState.keySeq().toArray()[0]
    let type = this.props.groupChannelsState.getIn([id, "type"])
    if(id !== undefined){
      windows.push(<Window key={"window-" + id} id={id} type={type} position = {number} state={"open"}/>)
    }
    else {
      id = this.props.app.get("channelId");
      type = this.props.app.get("channelType");
      windows.push(<Window key={"window-" + id} id={id} type={type} position = {number} state={"open"}/>)
    }

    return (
      <div id="ifc-app" className="cc-embed">
        {windows}
        <Toast />
      </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Embed)