import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import RosterChatrooms from 'containers/ChatApp/Components/RosterChatrooms'
import RosterInbox from 'containers/ChatApp/Components/RosterInbox'
import RosterInboxProduct from 'containers/ChatApp/Components/RosterInboxProduct'
import RosterUsers from 'containers/ChatApp/Components/RosterUsers'
import EmptyRecentChats from 'containers/ChatApp/Components/EmptyRecentChats'
import EmptyChatrooms from 'containers/ChatApp/Components/EmptyChatrooms'
import EmptyUsers from 'containers/ChatApp/Components/EmptyUsers'
import RosterLoader from 'containers/ChatApp/Components/RosterLoader'
import config from 'config'
import * as groupChannelsListactions from 'state/groupChannelsList/actions'
import * as Debug from 'debug';
const debug = Debug('chatcamp:Roster')

class Roster extends Component { 

  componentDidMount(){
    if(this.props.type === "users"){

    }
  }
  render () {
    let final
    if(this.props.type === "users"){
      if(!this.props.ui.get("userListLoading")){
        let value = this.props.ui.get("userSearchValue")
        if(this.props.userList.get('roster').size >= 1 && value === ""){
          final = <RosterUsers />
        }
        else if(this.props.userList.get('roster').size > 0 && value !== ""){
          final = <RosterUsers />
        }
        else if(this.props.userList.get('roster').size === 0 && value !== ""){
          final = <EmptyUsers header="User not found." body="Please check your search criteria."/>
        }
        else if(this.props.userList.get('roster').size === 0 && value === ""){
          final = <EmptyUsers header="No User Created Yet" body="Contact your admin to create users."/>
        }
      }
      else{
        final = <RosterLoader />
      }
    }

    if(this.props.type === "chatrooms"){
      if(!this.props.ui.get("openChannelListLoading")){
        if(this.props.openChannels.size > 0){
          final = <RosterChatrooms />
        }
        else{
          final = <EmptyChatrooms />
        }
      }
      else{
        final = <RosterLoader />
      }
    }

    let inboxValues = this.props.groupChannelsList.toArray()
    if(this.props.type === "inbox"){
      if(!this.props.ui.get("recentChatLoading")){
        if(inboxValues.length > 0){
          let classLocal
          if(this.props.className){
            classLocal = this.props.className
          }
          if(config.getRosterShowProductMetadata()){
            final = <div className= {classLocal}><RosterInboxProduct/></div>
          }
          else{
            final = <div className= {classLocal}><RosterInbox/></div>
          }
          
        }
        else{
          final = <EmptyRecentChats />
        }
    }
      else{
        final = <RosterLoader />
      }
    }

    return (
      final
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    groupChannelsListactions: bindActionCreators(groupChannelsListactions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Roster)
