// @flow
class Config {
  appId: string
  userId: string
  action: boolean
  listPanelShow: boolean
  listPanelOpenDefault: boolean
  welcomeBubbleShow: boolean
  chatPosition: boolean
  host: string
  port: string

  constructor() {
    this.action = false
    this.listPanelShow = true
    this.listPanelOpenDefault = true
    this.welcomeBubbleShow = true
    this.chatposition = "RIGHT"
    this.listPanelUsersShow = 0
    this.listPanelChatroomsShow = 0
    this.listPanelInboxShow = 0
    this.roster = {
      showStartNewChat: true,
      showMarkAllAsRead: true,
      showSearch: true,
      showProductList: false,
      showProductMetadata: false,
      showUserAvatarUpload: true,
      settings: {

      }
    }
    this.channel = {
      showProductMetadata: false, // use metadata 
      showAttachFile: true,
      showVideoCall: true,
      showVoiceRecording: true
    }
    this.translation = {
      authenticating: "Authenticating",
      rosterHeader: "My Chats",
      startNewChat: "Create New Group Chat",
      markAsReadAll: "Mark All Channels as Read",
      close: "Close",
      recentChatsTab: "RECENT",
      usersTab: "USERS",
      roomsTab: "ROOM",
      search: "Search...",
      sendMessageInputBox: "Send Message...",
      attachFile: "Attach a File",
      startRecording: "Start Recording",
      stopRecording: "Stop Recording",
      minimize: "Minimize",
      maximize: "Maximize",
      leaveChat: "Leave Chat",
      blockUser: "Block User",
      unblockUser: "Unblock User",
      addParticipants: "Add Participants",
      participantsListHeader: "Participants",
      sendMessageButton: "Send Message",
      online: "Online",
      yesterday: "Yesterday",
      today: "Today",
      justNow: "Just Now",
      minutesShort: "mins ago",
      aMinuteAgo: "a minute ago",
      minutesAgo: "Minutes Ago",
      anHourAgo: "an hour ago",
      hoursAgo: "Hours Ago",
      aDayAgo: "a Day Ago",
      daysAgo: "Days Ago",
      aMonthAgo: "a Month Ago",
      monthsAgo: "Months Ago",
      anYearAgo: "an Year Ago",
      yearsAgo: "Years Ago",
    }
    this.host = "api.chatcamp.io";
    // this.host = "localhost";
    this.port = "443";
    // this.port = "9080";
  }

  getAppId(): string {
    return this.appId
  }

  setAppId(appId: string) {
    this.appId = appId
  }

  getUserId(): string {
    return this.userId
  }

  setUserId(userId: string) {
    this.userId = userId
  }

  getAction(): boolean {
    return this.action
  }

  setAction(action: boolean) {
    this.action = action
  }

  getListPanelShow(): boolean {
    return this.listPanelShow
  }

  setListPanelShow(listPanelShow: boolean) {
    this.listPanelShow = listPanelShow
  }

  getListPanelInboxShow(): integer {
    return this.listPanelInboxShow
  }

  setListPanelInboxShow(listPanelInboxShow: integer) {
    this.listPanelInboxShow = listPanelInboxShow
  }

  getListPanelUsersShow(): integer {
    return this.listPanelUsersShow
  }

  setListPanelUsersShow(listPanelUsersShow: integer) {
    this.listPanelUsersShow = listPanelUsersShow
  }

  getListPanelChatroomsShow(): integer {
    return this.listPanelChatroomsShow
  }

  setListPanelChatroomsShow(listPanelChatroomsShow: integer) {
    this.listPanelChatroomsShow = listPanelChatroomsShow
  }

  getListPanelOpenDefault(): boolean {
    return this.listPanelOpenDefault
  }

  setListPanelOpenDefault(listPanelOpenDefault: boolean) {
    this.listPanelOpenDefault = listPanelOpenDefault
  }

  getWelcomeBubbleShow(): boolean {
    return this.welcomeBubbleShow
  }

  setWelcomeBubbleShow(welcomeBubbleShow: boolean) {
    this.welcomeBubbleShow = welcomeBubbleShow
  }

  getChatPosition(): boolean {
    return this.chatPosition
  }

  setChatPosition(chatPosition: boolean) {
    if(chatPosition === "LEFT" || chatPosition === "RIGHT"){
      this.chatPosition = chatPosition
    }
    else{
      this.chatPosition = "RIGHT"
    }
    
  }

  getRosterShowStartNewChat() {
    return this.roster.showStartNewChat;
  }

  setRosterShowStartNewChat(showStartNewChat) {
    this.roster.showStartNewChat = showStartNewChat;
  }

  getRosterShowMarkAllAsRead() {
    return this.roster.showMarkAllAsRead;
  }

  setRosterShowMarkAllAsRead(showMarkAllAsRead) {
    this.roster.showMarkAllAsRead = showMarkAllAsRead;
  }

  getRosterShowSearch() {
    return this.roster.showSearch;
  }

  setRosterShowSearch(showSearch) {
    this.roster.showSearch = showSearch;
  }

  getRosterShowUserAvatarUpload() {
    return this.roster.showUserAvatarUpload;
  }

  setRosterShowUserAvatarUpload(showUserAvatarUpload) {
    this.roster.showUserAvatarUpload = showUserAvatarUpload;
  }

  getRosterShowProductMetadata() {
    return this.roster.showProductMetadata;
  }

  setRosterShowProductMetadata(showProductMetadata) {
    this.roster.showProductMetadata = showProductMetadata;
  }

  getRosterShowProductList() {
    return this.roster.showProductList;
  }

  setRosterShowProductList(showProductList) {
    this.roster.showProductList = showProductList;
  }

  getChannelShowProductMetadata() {
    return this.channel.showProductMetadata;
  }

  setChannelShowProductMetadata(showProductMetadata) {
    this.channel.showProductMetadata = showProductMetadata;
  }

  getChannelShowAttachFile() {
    return this.channel.showAttachFile;
  }

  setChannelShowAttachFile(showAttachFile) {
    this.channel.showAttachFile = showAttachFile;
  }

  getChannelShowVoiceRecording() {
    return this.channel.showVoiceRecording;
  }

  setChannelShowVoiceRecording(showVoiceRecording) {
    this.channel.showVoiceRecording = showVoiceRecording;
  }

  getChannelShowVideoCall() {
    return this.channel.showVideoCall;
  }

  setChannelShowVideoCall(showVideoCall) {
    this.channel.showVideoCall = showVideoCall;
  }

  getTranslation(){
    return this.translation;
  }

  setTranslation(translation){
    if(translation.authenticating !==undefined){
      this.translation.authenticating = translation.authenticating
    }
    if(translation.rosterHeader !==undefined){
      this.translation.rosterHeader = translation.rosterHeader
    }
    if(translation.startNewChat !==undefined){
      this.translation.startNewChat = translation.startNewChat
    }
    if(translation.markAsReadAll !==undefined){
      this.translation.markAsReadAll = translation.markAsReadAll
    }
    if(translation.close !==undefined){
      this.translation.close = translation.close
    }
    if(translation.recentChatsTab !==undefined){
      this.translation.recentChatsTab = translation.recentChatsTab
    }
    if(translation.usersTab !==undefined){
      this.translation.usersTab = translation.usersTab
    }
    if(translation.roomsTab !==undefined){
      this.translation.roomsTab = translation.roomsTab
    }
    if(translation.search !==undefined){
      this.translation.search = translation.search
    }
    if(translation.sendMessageInputBox !==undefined){
      this.translation.sendMessageInputBox = translation.sendMessageInputBox
    }
    if(translation.attachFile !==undefined){
      this.translation.attachFile = translation.attachFile
    }
    if(translation.startRecording !==undefined){
      this.translation.startRecording = translation.startRecording
    }
    if(translation.stopRecording !==undefined){
      this.translation.stopRecording = translation.stopRecording
    }
    if(translation.minimize !==undefined){
      this.translation.minimize = translation.minimize
    }
    if(translation.maximize !==undefined){
      this.translation.maximize = translation.maximize
    }
    if(translation.leaveChat !==undefined){
      this.translation.leaveChat = translation.leaveChat
    }
    if(translation.blockUser !==undefined){
      this.translation.blockUser = translation.blockUser
    }
    if(translation.unblockUser !==undefined){
      this.translation.unblockUser = translation.unblockUser
    }
    if(translation.addParticipants !==undefined){
      this.translation.addParticipants = translation.addParticipants
    }
    if(translation.participantsListHeader !==undefined){
      this.translation.participantsListHeader = translation.participantsListHeader
    }
    if(translation.sendMessageButton !==undefined){
      this.translation.sendMessageButton = translation.sendMessageButton
    }
    if(translation.online !==undefined){
      this.translation.online = translation.online
    }
    if(translation.yesterday !==undefined){
      this.translation.yesterday = translation.yesterday
    }
    if(translation.today !==undefined){
      this.translation.today = translation.today
    }
    if(translation.justNow !==undefined){
      this.translation.justNow = translation.justNow
    }
    if(translation.minutesShort !==undefined){
      this.translation.minutesShort = translation.minutesShort
    }
    if(translation.aMinuteAgo !==undefined){
      this.translation.aMinuteAgo = translation.aMinuteAgo
    }
    if(translation.minutesAgo !==undefined){
      this.translation.minutesAgo = translation.minutesAgo
    }
    if(translation.anHourAgo !==undefined){
      this.translation.anHourAgo = translation.anHourAgo
    }
    if(translation.hoursAgo !==undefined){
      this.translation.hoursAgo = translation.hoursAgo
    }
    if(translation.aDayAgo !==undefined){
      this.translation.aDayAgo = translation.aDayAgo
    }
    if(translation.daysAgo !==undefined){
      this.translation.daysAgo = translation.daysAgo
    }
    if(translation.aMonthAgo !==undefined){
      this.translation.aMonthAgo = translation.aMonthAgo
    }
    if(translation.monthsAgo !==undefined){
      this.translation.monthsAgo = translation.monthsAgo
    }
    if(translation.anYearAgo !==undefined){
      this.translation.anYearAgo = translation.anYearAgo
    }
    if(translation.yearsAgo !==undefined){
      this.translation.yearsAgo = translation.yearsAgo
    }
    
  }



}

export default new Config()