import client from 'Client'
import { toast } from 'react-toastify'
import {
  USER_BLOCK_LIST_SUCCESS,
  USER_UNBLOCK_SUCCESS

} from 'state/action-types'

export const getBlockList = (limit, reference) => dispatch => {
    let blockListQuery = client.createBlockedUserListQuery()
    blockListQuery.limit = limit
    blockListQuery.reference = reference
    blockListQuery.load(function(e, list){
        if(!e){
            dispatch({
                type: USER_BLOCK_LIST_SUCCESS,
                blockList: list
            });
        }
    })
}

export const blockUser = (block_id) => dispatch => {
    client.blockUser(block_id, function(error){
      if(!error){
        dispatch(getBlockList())
        toast.dismiss()
        toast('User has been successfully blocked.')
      }
      else{
        toast.dismiss()
        toast('Error in blocking user.')
      }
    })
}

export const unblockUser = (unblock_id) => dispatch => {
  client.unblockUser(unblock_id, function(error){
    if(!error){
        dispatch({
            type: USER_UNBLOCK_SUCCESS,
            unblockId: unblock_id
        });
        toast.dismiss()
        toast('User has been successfully unblocked.')
    }
    else{
        toast.dismiss()
        toast('Error in unblocking user.')
    }
  })
}