import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { List, Button } from 'semantic-ui-react'
import * as actions from 'state/blockList/actions'
import * as actionsGroupChannels from 'state/groupChannels/actions'
import AvatarWrapperStatus from 'containers/ChatApp/Components/AvatarWrapperStatus'
import client from 'Client'
import * as Debug from 'debug';
const debug = Debug('chatcamp:RosterUsers')

class RosterBlockList extends Component {

    onUserClick = (id) => {
        this.props.actions.unblockUser(id)
        this.props.groupChannelsState.map((chatWindow, id) => {
            this.props.actionsGroupChannels.syncGroup(id)
        })
    }

    render () {
        let roster = []
        let inlineStyleHeightImage ={
            lineHeight: "36px",
            height: "36px",
            position: "relative"
        }
    
        this.props.blockList.get('roster').map((rosterItem) => {
            if(rosterItem.getIn(['id']) !== this.props.user.getIn(['id'])){
                roster.push(
                    <List.Item className={"cc-block-list-users"} key={"roster-key-" + rosterItem.getIn(['id']) }>
                        <List.Content floated='right'>
                            <Button size="tiny" onClick={() => this.onUserClick(rosterItem.getIn(['id']))}>Unblock</Button>
                        </List.Content>
                        <List.Content style={inlineStyleHeightImage} floated='left' verticalAlign='middle'>
                            <AvatarWrapperStatus className={"cc-current-profile-avatar-status"} name={rosterItem.getIn(['displayName'])} src = {rosterItem.getIn(['avatarUploadUrl']) || rosterItem.getIn(['avatarUrl'])} size={36} isOnline={rosterItem.getIn(['isOnline'])}/>
                        </List.Content>
                        <List.Content className="cc-block-list-name" verticalAlign='middle'>
                            <List.Header>{rosterItem.getIn(['displayName'])}</List.Header>
                        </List.Content>
                    </List.Item>
                )
            }
        })

        return (
            <List verticalAlign='middle' relaxed="very" size="small">
                <div className="cc-block-list-header">Blocked Users</div>
                {roster}
            </List>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    actionsGroupChannels: bindActionCreators(actionsGroupChannels, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterBlockList)
