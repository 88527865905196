import React, { Component } from 'react'
import * as Debug from 'debug';
const debug = Debug('chatcamp:EmptyUsers')

class EmptyUsers extends Component {

  render () {
    return (
        <div className={"cc-empty-users-top"}>
            <p className="cc-empty-users">
                <span className="cc-empty-users-header">{this.props.header}</span>
                <span className="cc-empty-users-description"> {this.props.body}</span>
            </p>
        </div>
    )
  }

}

export default EmptyUsers
