import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Form } from 'semantic-ui-react'
import * as actions from 'state/auth/actions'
import client from 'Client'
import config from 'config'
import * as Debug from 'debug'

const debug = Debug('chatcamp:LoginScreen')

class LoginScreen extends Component {
    state = { userId: '', displayName: ''}

    handleChange = (e, { name, value }) => this.setState({ [name]: value })
  
    handleSubmit = () => {
      const { userId, displayName } = this.state
      if(userId){
        this.props.actions.chatConnecting()
        client.connect(userId, config.host, config.port, function(error, user) {
            debug("connected", error, user)
            if(displayName) {
              client.updateUserDisplayName(displayName, function(e, u){
                debug("name updated", e, u)
              })
            }
          })
      }
      
    }

    render () {
        // let finalClass = "cc-login-screen cc-user-settings"
        // let final
        // if(this.props.className !== undefined){
        //     finalClass = finalClass + " " + this.props.className
        // }
        const { userId, displayName } = this.state

        return (
            <div className="cc-login-screen">
                <Form onSubmit={this.handleSubmit}>
                    <Form.Field>
                        <Form.Input label = "User ID" name='userId' onChange={this.handleChange} value={userId} placeholder='User ID' />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input label = "Display Name" name='displayName' onChange={this.handleChange} value={displayName} placeholder='Display Name' />
                    </Form.Field>
                    
                    <Form.Button primary type='submit'>Login</Form.Button>
                </Form>
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
