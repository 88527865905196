
import { toast } from 'react-toastify'
import {
    USER_LOCAL_TITLE_ALERT_HIDE,
    USER_LOCAL_TITLE_ALERT_SHOW,
    USER_LOCAL_PUSH_NOTIFICATION_ENABLE,
    USER_LOCAL_PUSH_NOTIFICATION_DISABLE
  
  } from 'state/action-types'
  
  export const userLocalTitleAlertHide = () => dispatch => {
    dispatch({
      type: USER_LOCAL_TITLE_ALERT_HIDE
    })
    toast.dismiss()
    toast('Settings successfully saved.')
  }
  
  export const userLocalTitleAlertShow = () => dispatch => {
    dispatch({
      type: USER_LOCAL_TITLE_ALERT_SHOW
    })
    toast.dismiss()
    toast('Settings successfully saved.')
  }

  export const userLocalPushNotificationsDisable = () => dispatch => {
    dispatch({
      type: USER_LOCAL_PUSH_NOTIFICATION_DISABLE
    })
    toast.dismiss()
    toast('Settings successfully saved.')
  }

  export const userLocalPushNotificationsEnable = () => dispatch => {
    dispatch({
      type: USER_LOCAL_PUSH_NOTIFICATION_ENABLE
    })
    toast.dismiss()
    toast('Settings successfully saved.')
  }


  
  
  