
import {
    USER_SETTINGS_OPEN,
    USER_SETTINGS_CLOSE,
    USER_BLOCK_LIST_OPEN,
    USER_BLOCK_LIST_CLOSE,
    USER_BLOCK_ALERT_OPEN,
    USER_BLOCK_ALERT_CLOSE,
    GROUP_CHANNEL_CREATE_OPEN,
    GROUP_CHANNEL_CREATE_CLOSE,
    PRODUCT_LIST_OPEN,
    PRODUCT_LIST_CLOSE,
    UPDATE_USER_SEARCH_VALUE
  
  } from 'state/action-types'
  
  export const userSettingsOpen = () => dispatch => {
    dispatch({
      type: USER_SETTINGS_OPEN
    })
  }
  
  export const userSettingsClose = () => dispatch => {
    dispatch({
      type: USER_SETTINGS_CLOSE
    })
  }

  export const userBlockListOpen = () => dispatch => {
    dispatch({
      type: USER_BLOCK_LIST_OPEN
    })
  }
  
  export const userBlockListClose = () => dispatch => {
    dispatch({
      type: USER_BLOCK_LIST_CLOSE
    })
  }

  export const userBlockAlertOpen = (id) => dispatch => {
    dispatch({
      type: USER_BLOCK_ALERT_OPEN,
      id: id
    })
  }
  
  export const userBlockAlertClose = () => dispatch => {
    dispatch({
      type: USER_BLOCK_ALERT_CLOSE
    })
  }

  export const groupChannelCreateOpen = () => dispatch => {
    dispatch({
      type: GROUP_CHANNEL_CREATE_OPEN
    })
  }
  
  export const groupChannelCreateClose = () => dispatch => {
    dispatch({
      type: GROUP_CHANNEL_CREATE_CLOSE
    })
  }

  export const productListOpen = () => dispatch => {
    dispatch({
      type: PRODUCT_LIST_OPEN
    })
  }
  
  export const productListClose = (id) => dispatch => {
    dispatch({
      type: PRODUCT_LIST_CLOSE,
      productId: id
    })
  }

  export const updateUserSearchValue = (value) => dispatch => {
    dispatch({
      type: UPDATE_USER_SEARCH_VALUE,
      value: value
    })
  }
  
  