import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { List, Image } from 'semantic-ui-react'
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import * as uiActions from 'state/ui/actions'
import * as Debug from 'debug';
const debug = Debug('chatcamp:ProductHead')

class ProductHead extends Component {

    render () {
        let productMetadata
        let productName = ""
        let productImage = ""
        let productPrice = ""
        let productDesc = ""
        
        let currentProduct = {}
        let currentProductId
        if(this.props.id && this.props.type === "group"){
            productMetadata = this.props.groupChannels.getIn([this.props.id, 'metadata'])
            if(productMetadata){
                productName = productMetadata.product_name
                productImage = productMetadata.product_image
                productPrice = productMetadata.product_price
                productDesc = productMetadata.product_desc
            }
        }
        else if(this.props.ui.get("productId") !== ""){
            let buyers = JSON.parse(this.props.user.get("metadata").buyer)
            let sellers = JSON.parse(this.props.user.get("metadata").seller)
            let products = sellers.concat(buyers)
            currentProductId = this.props.ui.get("productId")
            for(let i in products){
                if(currentProductId === products[i].product_id){
                    currentProduct =  products[i]
                    productName = currentProduct.product_name
                    productImage = currentProduct.product_image
                    productPrice = currentProduct.product_price
                    productDesc = currentProduct.product_desc
                }
            }
        }
        
        let finalClass = "cc-product-list-header"
        if(this.props.className){
            finalClass = finalClass + " " + this.props.className
        }

        return (
            <div className= {finalClass}>
                <List verticalAlign='middle' size="small">
                    <List.Item key={"roster-key-header"} >
                        <List.Content floated='left' verticalAlign='middle'>
                            {<Image as={()=> <AvatarWrapper className="image" name={ productName} src = {productImage} />}/>}
                        </List.Content>
                        <List.Content verticalAlign='middle'>
                            <List.Header className={"list-inbox-header"}>
                                {productName}
                            </List.Header>
                            {<List.Description className={"list-inbox-chats"}>
                                {productPrice}
                            </List.Description>}
                            <List.Description className={"list-inbox-description"}>
                                {productDesc}
                            </List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    uiActions: bindActionCreators(uiActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductHead)
