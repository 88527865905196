import client from 'Client'
import {
  CHAT_CONNECT_SUCCESS,
  GROUP_CHANNELS_GET_ERROR,
  GROUP_CHANNELS_GET_SUCCESS,
  GROUP_CHANNELS_MESSAGE_RECEIVED_SUCCESS,
  GROUP_CHANNELS_OPEN,
  GROUP_CHANNELS_CLOSE,
  GROUP_CHANNELS_CREATE,
  GROUP_CHANNELS_HIDE,
  GROUP_CHANNELS_LIST_SUCCESS,
  GROUP_CHANNELS_MY_LIST_SUCCESS,
  OPEN_CHANNELS_LIST_SUCCESS,
  GROUP_CHANNELS_LEAVE_SUCCESS,
  OPEN_CHANNELS_CREATE,
  OPEN_CHANNELS_GET_SUCCESS,
  OPEN_CHANNELS_GET_HISTORY_SUCCESS,
  OPEN_CHANNELS_MESSAGE_RECEIVED_SUCCESS,
  OPEN_CHANNELS_CLOSE,
  OPEN_CHANNELS_REMOVE_HISTORY,
  SMART_CHAT_OPEN,
  SMART_CHAT_CLOSE,
  USER_LIST_SUCCESS,
  USER_PUSH_NOTIFICATION_SOUND_UPDATE,
  USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE,
  USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE,
  USER_PUSH_NOTIFICATION_SNOOZE_UPDATE,
  USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE,
  CHAT_DISCONNECT_SUCCESS,
  CHAT_RECONNECTING,
  USER_TOTAL_GROUP_CHANNEL_UPDATE
} from 'state/action-types'

import Utility from 'utility/Utility'
import config from 'config'
import * as Debug from 'debug';
const debug = Debug('chatcamp:middleware')



export const iFlyMiddleWare = store => {
  window.ChatCampUI = {}
  window.ChatCampUIKit = {}
  
  let userId;
  if(window.ChatCampData && window.ChatCampData.userId){
    userId = window.ChatCampData.userId
  }

  if(Utility.getUrlQueryParams(window.location.href)['userId'] && Utility.getUrlQueryParams(window.location.href)['userId'][0]) {
    userId = Utility.getUrlQueryParams(window.location.href)['userId'][0]
  }

  let accessToken;
  if(window.ChatCampData && window.ChatCampData.accessToken){
    accessToken = window.ChatCampData.accessToken
  }
  if(Utility.getUrlQueryParams(window.location.href)['accessToken'] && Utility.getUrlQueryParams(window.location.href)['accessToken'][0]) {
    accessToken = Utility.getUrlQueryParams(window.location.href)['accessToken'][0]
  }

  // to expose startchat to other platforms
  let startChat = (groupChannelId) => {
    if(Utility.mobileCheck()){
      var el = document.getElementById('cc-app-inner');
      if(el) {
        el.className = 'cc-app-mobile'
      }
    }
    _startGroupChannel(groupChannelId)
  }

  let startChannelChat = (channelId, channelType) => {
    if(Utility.mobileCheck()){
      var el = document.getElementById('cc-app-inner');
      if(el) {
        el.className = 'cc-app-mobile'
      }
    }
    if(channelType === "group"){
      startChat(channelId)
    }
    else if(channelType === "open"){
      _startOpenChannel(channelId)
    }
    else if(channelType === "roster"){
      store.dispatch({
        type: SMART_CHAT_OPEN
      })
    }
  }

  window.ChatCampUI.startChat = startChat
  window.ChatCampUIKit.startChat = startChannelChat

  let channelListener = new client.ChannelListener();
  channelListener.onGroupChannelMessageReceived = function(groupChannel, message) {
    debug("Listener", groupChannel, message)
    // automatic opening of chat in case of new message
    let state = store.getState().groupChannelsState.getIn([groupChannel.id, "state"])
    let groupChannelsState = store.getState().groupChannelsState
    let groupChannels = store.getState().groupChannels
      let count = 0
      let first = false;
      let max = Math.floor(window.innerWidth/368)
      groupChannels.map((window, id) => {
        if(groupChannelsState.getIn([window.get('id'), "state"]) === "HIDDEN"){
            count++
            first = true
        }
      })
    if(state !== "OPEN" && store.getState().app.getIn(["type"]) === "smartChat" && !Utility.mobileCheck()){
      // _startGroupChannel(groupChannel.id)
      if(first){
        store.dispatch({
          type: GROUP_CHANNELS_HIDE,
          groupChannelsId: groupChannel.id
        })
      }
      else if(count === max){
        store.dispatch({
          type: GROUP_CHANNELS_HIDE,
          groupChannelsId: groupChannel.id
        })
      }
      else{
        if(!Utility.mobileCheck()){
          store.dispatch({
            type: GROUP_CHANNELS_OPEN,
            groupChannelsId: groupChannel.id
          })
        }
      }

    }
    store.dispatch({
      type: GROUP_CHANNELS_MESSAGE_RECEIVED_SUCCESS,
      groupChannel: groupChannel,
      message: message
    });
    store.dispatch({
      type: GROUP_CHANNELS_GET_SUCCESS,
      groupChannel: groupChannel
    });
  }

  channelListener.onGroupChannelParticipantJoined = function(groupChannel, user) {
    debug("groupchannel joined", groupChannel, user)
    store.dispatch({
      type: GROUP_CHANNELS_GET_SUCCESS,
      groupChannel: groupChannel
    });
  }

  channelListener.onGroupChannelParticipantLeft = function(groupChannel, user) {
    debug("groupchannel left", groupChannel, user)
    if(user.id === userId){
      store.dispatch({
        type: GROUP_CHANNELS_LEAVE_SUCCESS,
        groupChannel: groupChannel
      });
    }
    store.dispatch({
      type: GROUP_CHANNELS_GET_SUCCESS,
      groupChannel: groupChannel
    });
  }

  channelListener.onOpenChannelMessageReceived = function(openChannel, message) {
    store.dispatch({
      type: OPEN_CHANNELS_MESSAGE_RECEIVED_SUCCESS,
      openChannel: openChannel,
      message: message
    });
  }

  channelListener.onOpenChannelParticipantJoined = function(openChannel, user) {
    debug("openchannel joined", openChannel, user)
    store.dispatch({
      type: OPEN_CHANNELS_GET_SUCCESS,
      openChannel: openChannel
    });

    if(user.id === userId){
      let previousMessageListQuery = openChannel.createPreviousMessageListQuery();
      previousMessageListQuery.setLimit(20)
      previousMessageListQuery.setReference(null)
      previousMessageListQuery.load(function(previousMessageListQueryError, messages) {
        store.dispatch({
          type: OPEN_CHANNELS_GET_HISTORY_SUCCESS,
          openChannel: openChannel,
          messages: messages
        });
      })
    }

  }

  channelListener.onOpenChannelParticipantLeft = function(openChannel, user) {
    debug("openchannel left", openChannel, user)
    store.dispatch({
      type: OPEN_CHANNELS_GET_SUCCESS,
      openChannel: openChannel
    });
    if(user.id === userId){
      store.dispatch({
        type: OPEN_CHANNELS_CLOSE,
        openChannelsId: openChannel.id
      })
      store.dispatch({
        type: OPEN_CHANNELS_REMOVE_HISTORY,
        openChannelsId: openChannel.id
      })
    }
  }

  channelListener.onGroupChannelTypingStatusChanged = function(groupChannel) {
    debug("Typing Status", groupChannel, groupChannel.getTypingParticipants())
    store.dispatch({
      type: GROUP_CHANNELS_GET_SUCCESS,
      groupChannel: groupChannel
    });
  }

  channelListener.onGroupChannelReadStatusUpdated = function(groupChannel) {
    debug("Read Status Update", groupChannel)
    store.dispatch({
      type: GROUP_CHANNELS_GET_SUCCESS,
      groupChannel: groupChannel
    });
  }

  channelListener.onTotalGroupChannelCount = function(count, filterParams) {
    store.dispatch({
      type: USER_TOTAL_GROUP_CHANNEL_UPDATE,
      count: count
    });
  };

  client.addChannelListener("reactApp" + Utility.generateRandomId(), channelListener)

  let firstRun = true

  let connectionListener = new client.ConnectionListener()
  debug("running...")
  connectionListener.onConnectionChanged = function(isConnected) {
    if(firstRun && isConnected) {
      firstRun = false

      let user = client.user
        
        let groupChannelId1
        var allGroupChannels = []
        if(Utility.getUrlQueryParams(window.location.href)['groupChannelId'] && Utility.getUrlQueryParams(window.location.href)['groupChannelId'][0]) {
          groupChannelId1 = Utility.getUrlQueryParams(window.location.href)['groupChannelId'][0]
          allGroupChannels[0] = groupChannelId1
        }

        user["appId"] = client.app.id
        let allOpenChannels = [];

        store.getState().groupChannelsState.map((rosterItem, index) => {
          if(rosterItem.getIn(["type"]) === "open"){
            allOpenChannels.push(index)
          }
          else if(rosterItem.getIn(["type"]) === "group"){
            allGroupChannels.push(index)
          }
        })

        let storeUserId = store.getState().user.get("id")
        debug("smartChat Store", storeUserId)
        if(storeUserId === false && !Utility.mobileCheck())
        if(config.getListPanelOpenDefault() === true){
          store.dispatch({
            type: SMART_CHAT_OPEN
          })
        }
        else if(config.getListPanelOpenDefault() === false){
          store.dispatch({
            type: SMART_CHAT_CLOSE
          })
        }
        store.dispatch({
          type: CHAT_CONNECT_SUCCESS,
          user: user
        });

      if(store.getState().app.get("type") === "embed"){
        window.ChatCampUIKit.startChat(store.getState().app.get("channelId"), store.getState().app.get("channelType"))
      }
        pollGroupChannelList()
    } 
    else if(!isConnected){
      firstRun = true
      if(store.getState().user.get("isLoggedIn")){
        store.dispatch({
          type: CHAT_RECONNECTING
        });
      }
      else{
        store.dispatch({
          type: CHAT_DISCONNECT_SUCCESS
        });
      }
     
    }
  }

  client.addConnectionListener("reactApp" + Utility.generateRandomId(), connectionListener)

  let userListener = new client.UserListener();

  userListener.onTotalUnreadGroupChannelCount = function(count, filterParams) {
    store.dispatch({
      type: USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE,
      count: count
    });
  }

  client.addUserListener("reactApp" + Utility.generateRandomId(), userListener);

  function pollGroupChannelList() {
    var groupChannelListQuery = client.GroupChannel.createGroupChannelListQuery();
    groupChannelListQuery.load(function(error, groupChannelList){
        if(error == null){
          if(!config.getRosterShowProductList()){
            store.dispatch({
              type: GROUP_CHANNELS_MY_LIST_SUCCESS,
              groupChannels: groupChannelList
            });

            store.dispatch({
              type: GROUP_CHANNELS_LIST_SUCCESS,
              groupChannels: groupChannelList
            });
          }
          //default room open in inbox
          if(store.getState().app.get("type") === "inbox"){
            let id = store.getState().groupChannelsState.keySeq().toArray()[0]
            if(id === undefined && groupChannelList.length > 0){
              window.ChatCampUIKit.startChat(groupChannelList[0].id, "group")
            }
          }

          for(let i in groupChannelList){
            if(groupChannelList[i].participantsCount === 2){
              client.GroupChannel.get(groupChannelList[i].id, function(error, groupChannel) {
                if(!error){
                  if(!config.getRosterShowProductList()){
                    store.dispatch({
                      type: GROUP_CHANNELS_GET_SUCCESS,
                      groupChannel: groupChannel
                    });
                  }
                }
              })
            }
          }
        }
        client.getTotalUnreadGroupChannelCount(function(error, count){
          if(!error){
            store.dispatch({
              type: USER_TOTAL_UNREAD_MESSAGE_COUNT_UPDATE,
              count: count
            });
          }
          client.GroupChannel.getTotalGroupChannelCount(function(error, count){
            if(!error){
              store.dispatch({
                type: USER_TOTAL_GROUP_CHANNEL_UPDATE,
                count: count
              });
            }
            var openChannelListQuery = client.OpenChannel.createOpenChannelListQuery();
            openChannelListQuery.load(function(error, openChannelList){
              if(error == null){
                  debug("My Open Channels List Retreived", openChannelList)
                  store.dispatch({
                    type: OPEN_CHANNELS_LIST_SUCCESS,
                    openChannels: openChannelList
                  });
              }
              
              // var userListQuery = client.createUserListQuery();
              // userListQuery.isOnlineFilter = "ONLINE";
              // // userListQuery.isOnlineFilter = client.UserListQueryFilter.IS_ONLINE_FILTER_ONLINE;
              //     userListQuery.load(function(error, userList){
              //         if(error == null){
              //           store.dispatch({
              //             type: USER_LIST_SUCCESS,
              //             userList: userList,
              //             roster: true
              //           });
              //         }
                      client.getPushNotificationSound(function(error, value){
                        if(!error){
                          store.dispatch({
                            type: USER_PUSH_NOTIFICATION_SOUND_UPDATE,
                            sound: value
                          });
                        }
              
                        client.getPushNotificationTemplate(function(error, value){
                          if(!error){
                            store.dispatch({
                              type: USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE,
                              template: value
                            });
                          }
                          client.getPushNotificationSnooze(function(error, value){
                            if(!error){
                              store.dispatch({
                                type: USER_PUSH_NOTIFICATION_SNOOZE_UPDATE,
                                snooze: value
                              });
                            }
                            client.getPushNotificationPreference(function(error, dnd, start_dnd_hour, start_dnd_min, end_dnd_hour, end_dnd_min, timezone){
                              if(!error){
                                store.dispatch({
                                  type: USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE,
                                  dnd: dnd,
                                  startDndHour: start_dnd_hour,
                                  startDndMin: start_dnd_min,
                                  endDndHour: end_dnd_hour,
                                  endDndMin: end_dnd_min,
                                  timezone: timezone
                                });
                              }
                            })
                          })
                        })
                      })
              
                  // })
            })
          })
        })
    })
  }




  let _startGroupChannel = (groupChannelId) => {
    client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
      if(error==null) {
        store.dispatch({
          type: GROUP_CHANNELS_GET_SUCCESS,
          groupChannel: groupChannel
        });
        let state = store.getState().groupChannelsState.getIn([groupChannel.id, "state"])
          if(state === "HIDDEN"){
            store.dispatch({
              type: GROUP_CHANNELS_CLOSE,
              groupChannelsId: groupChannel.id
            })
          }
          let allOpen = store.getState().groupChannelsState.keySeq().toArray()

          if((store.getState().app.get("type") === "inbox" || store.getState().app.get("type") === "embed") && allOpen.length > 0){
            for(let i in allOpen){
              let currentId = allOpen[i];
              if(store.getState().groupChannelsState.getIn([currentId, "type"]) === "group"){
                store.dispatch({
                  type: GROUP_CHANNELS_CLOSE,
                  groupChannelsId: currentId
                })
              }
              else if(store.getState().groupChannelsState.getIn([currentId, "type"]) === "open"){
                store.dispatch({
                  type: OPEN_CHANNELS_CLOSE,
                  openChannelsId: currentId
                })
              }
            }
            
          }
          if(store.getState().app.get("type") === "smartChat" && !Utility.mobileCheck()){
            store.dispatch({
              type: SMART_CHAT_OPEN
            })
          }
          store.dispatch({
            type: GROUP_CHANNELS_CREATE,
            groupChannelsId: groupChannel.id
          })
      }
      else {
        store.dispatch({
          type: GROUP_CHANNELS_CLOSE,
          groupChannelsId: groupChannelId
        })
        store.dispatch({
          type: GROUP_CHANNELS_GET_ERROR,
          error: error,
          groupChannelId: groupChannelId
        });
      }
    });
  }

  let _startOpenChannel = (openChannelId) => {
    client.OpenChannel.get(openChannelId, function(error, openChannel) {
      if(error==null) {
          openChannel.join(function(error, message) {
            let messages = store.getState().openChannels.getIn([openChannel.id, "messages"])
            if(!messages){
              let previousMessageListQuery = openChannel.createPreviousMessageListQuery();
              previousMessageListQuery.setLimit(20)
              previousMessageListQuery.setReference(null)
              previousMessageListQuery.load(function(previousMessageListQueryError, messages) {
                store.dispatch({
                  type: OPEN_CHANNELS_GET_HISTORY_SUCCESS,
                  openChannel: openChannel,
                  messages: messages
                });
              })
            }
          })
        store.dispatch({
          type: OPEN_CHANNELS_GET_SUCCESS,
          openChannel: openChannel
        });
        let state = store.getState().groupChannelsState.getIn([openChannel.id, "state"])
        if(state === "HIDDEN"){
          store.dispatch({
            type: OPEN_CHANNELS_CLOSE,
            openChannelsId: openChannel.id
          })
        }
          let allOpen = store.getState().groupChannelsState.keySeq().toArray()
          store.dispatch({
            type: OPEN_CHANNELS_CREATE,
            openChannelsId: openChannel.id
          })

          if(store.getState().app.get("type") === "smartChat" && !Utility.mobileCheck()){
            store.dispatch({
              type: SMART_CHAT_OPEN
            })
          }
          
          if(store.getState().app.get("type") === "inbox" && allOpen.length > 0){
            for(let i in allOpen){
              let currentId = allOpen[i];
              if(store.getState().app.get("type") === "inbox"){
                if(store.getState().groupChannelsState.getIn([currentId, "type"]) === "group"){
                  store.dispatch({
                    type: GROUP_CHANNELS_CLOSE,
                    groupChannelsId: currentId
                  })
                }
                else{
                  store.dispatch({
                    type: OPEN_CHANNELS_CLOSE,
                    openChannelsId: currentId
                  })
                }
              }
            }
          }
      }
    });
  }


  return next => action => {
    next(action);
  }
}
