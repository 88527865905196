import React, { Component } from 'react'
import AvatarWrapper from 'containers/ChatApp/Components/AvatarWrapper'
import OnlineStatus from 'containers/ChatApp/Components/OnlineStatus'
import {isEqual} from 'lodash'

class AvatarWrapperStatus extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps);
  }

  render () {
    return (
      <div className={"cc-avatar-wrapper-status " + this.props.className}>
        <AvatarWrapper name={this.props.name} src = {this.props.src} size={this.props.size} className={"cc-avatar-wrapper-status-avatar"}/>
        <OnlineStatus className={"cc-avatar-wrapper-status-status"} isOnline={this.props.isOnline}/>
      </div>
    )
  }

}

export default AvatarWrapperStatus
