import ReactDOM from 'react-dom'
import React from 'react'
import Immutable from 'immutable'
import { configureStore, configureRootComponent, configureChatComponent } from 'common'
// import {history} from 'routing'
// import en from 'react-intl/locale-data/en'
// import Translations from 'utility/Translations'
import registerServiceWorker from './registerServiceWorker';
// import {addLocaleData} from 'react-intl'
import './style.css'
import {
  CHAT_STATE_LOADED_SUCCESS,
  CHAT_SSO_FALSE,
  SET_APP_TYPE,
  CHAT_CONNECTING
} from 'state/action-types'
// import ChatAppInit from 'utility/ChatAppInit'
import Utility from 'utility/Utility'
import config from 'config'
// Styles
// import 'semantic/dist/semantic.min.css';
// import 'styles/index.css';

// addLocaleData(en);
import client, {updateAppId} from 'Client'
import * as Debug from 'debug';
const debug = Debug('chatcamp:index')

// window.BASE_API = 'http://localhost:4000/api/v1';
if (process.env.NODE_ENV !== 'production') {
  window.BASE_API = 'http://localhost:9080';
}
else {
  window.BASE_API = 'https://api.iflychat.com:443';
}

if (process.env.NODE_ENV !== 'production') {
  const {whyDidYouUpdate} = require('why-did-you-update')
    let createClass = React.createClass;
    Object.defineProperty(React, 'createClass', {
      set: (nextCreateClass) => {
        createClass = nextCreateClass;
      }
    });
  localStorage.setItem('debug', 'chatcamp:*')

  // whyDidYouUpdate(React)
}

let initialState = {
  user: Immutable.fromJS({
    name: "User Name",
    id: false,
    avatarUrl: "//cdn.iflychat.com/img/default_avatar.png",
    profileUrl: "javascript:void(0)",
    role: Immutable.Map({}),
    userColor: "#0000FF"
  }),
  groupChannels: Immutable.fromJS({

  }),
};

// let popup = ChatAppInit.addPopUpChatToState(initialState);
// let embedRoom = ChatAppInit.addEmbedRoomsToState(initialState);
// let embedApp = ChatAppInit.addEmbedAppsToState(initialState);
// let widget = ChatAppInit.addWidgetAppToState(initialState);
// let widgetOnlineUserCount = ChatAppInit.addWidgetOnlineUserCountToState(initialState);
// let widgetOnlineUserList = ChatAppInit.addWidgetOnlineUserListToState(initialState);
// let oldEmbedRoom = ChatAppInit.addOldEmbedRoomsToState(initialState);
//
// initialState.apps = Immutable.fromJS(initialState.apps);
// debug(initialState.apps)
// if(popup === true || embedRoom === true || embedApp === true || widget === true || widgetOnlineUserCount === true || widgetOnlineUserList === true || oldEmbedRoom === true){
//   // iFlyChatChatSdk.chatConnect();
// }



// if(history.location.pathname === '/chat') {
//   const ChatComponent = configureChatComponent(store);
//   let lang = 'en';
//
//   //iFlyChat 3.0 entry point //TODO
//   ReactDOM.render(ChatComponent, document.getElementsByClassName("iflychat-popup")[0]);
// } else {
  // const RootComponent = configureRootComponent(store);



function ChatCampRenderInit(options) {
  
  let accessToken = "";
  if(options.user && options.user.accessToken) {
    accessToken = options.user.accessToken
  }
  updateAppId(options.appId)
  config.setAppId(options.appId)
  
  if(options.user && options.user.id){
    config.setUserId(options.user.id)
    debug("before connect", new Date())

    client.connect(options.user.id, accessToken, config.host, config.port, function(error, user) {
      debug("connected", error, user, new Date())
      if(options.user.displayName) {
        client.updateUserDisplayName(options.user.displayName, function(e, u){
          debug("name updated", e, u)
        })
      }
      if(options.user.avatarUrl) {
        client.updateUserAvatarUrl(options.user.avatarUrl, function(e, u) {
          debug("avatar updated", e, u)
        })
      }
    })
  }
  else{
    
  }
  

  processIMApps(options);
  
  processInboxApps(options);

  processRoomApps(options);

  processSideChatApps(options)
  
}

let firstRun = true

if(window) {
  if(!window.ChatCampUi) {
    window.ChatCampUi = {}
  }
  window.ChatCampUi.init = function(options) {
    if(firstRun && options.appId /*&& options.user && options.user.id*/) {
      let appId = options.appId
      // add semantic
      var head = document.head;
      // var link = document.createElement("link");
      // link.type = "text/css";
      // link.rel = "stylesheet";
      // link.href = "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.13/semantic.min.css";
      // head.appendChild(link);

      //set config
      if(options.ui && options.ui.theme){
        if(options.ui.theme.position){
          config.setChatPosition(options.ui.theme.position)
        }
      }
      if(options.ui && options.ui.roster) {
        if(options.ui.roster.render===false) {
          config.setListPanelShow(false)
          config.setListPanelOpenDefault(false)
          config.setWelcomeBubbleShow(false)
        }
        else if(options.ui.roster.defaultMode && options.ui.roster.defaultMode==='open') {
          config.setListPanelShow(true)
          config.setListPanelOpenDefault(true)
          config.setWelcomeBubbleShow(true)
        }
        else if(options.ui.roster.defaultMode && options.ui.roster.defaultMode==='minimize') {
          config.setListPanelShow(true)
          config.setListPanelOpenDefault(false)
          config.setWelcomeBubbleShow(true)
        }
        else if(options.ui.roster.defaultMode && options.ui.roster.defaultMode==='hidden') {
          config.setListPanelShow(true)
          config.setListPanelOpenDefault(false)
          config.setWelcomeBubbleShow(false)
        }

        if(options.ui.roster.tabs && options.ui.roster.render===true) {
          if(options.ui.roster.tabs.length > 0){
            let values = options.ui.roster.tabs
            for(let i in values) {
              if(!(values[i] === "recent" || values[i] === "rooms" || values[i] === "users")){
                values.splice(i, 1)
              }
            }
            if(values.indexOf("recent") !== -1){
              config.setListPanelInboxShow(values.indexOf("recent") + 1)
            }
            if(values.indexOf("users") !== -1){
              config.setListPanelUsersShow(values.indexOf("users") + 1)
            }
            if(values.indexOf("rooms") !== -1){
              config.setListPanelChatroomsShow(values.indexOf("rooms") + 1)
            }
          }
          else{
            console.log("tabs can't be blank")
          }
        }

        if(typeof(options.ui.roster.showStartNewChat) === 'boolean') {
          config.setRosterShowStartNewChat(options.ui.roster.showStartNewChat);
        }
        if(typeof(options.ui.roster.showMarkAllAsRead) === 'boolean') {
          config.setRosterShowMarkAllAsRead(options.ui.roster.showMarkAllAsRead);
        }
        if(typeof(options.ui.roster.showSearch) === 'boolean') {
          config.setRosterShowSearch(options.ui.roster.showSearch);
        }
        if(typeof(options.ui.roster.showUserAvatarUpload) === 'boolean') {
          config.setRosterShowUserAvatarUpload(options.ui.roster.showUserAvatarUpload);
        }
        if(typeof(options.ui.roster.showProductMetadata) === 'boolean') {
          config.setRosterShowProductMetadata(options.ui.roster.showProductMetadata);
        }
        if(typeof(options.ui.roster.showProductList) === 'boolean') {
          config.setRosterShowProductList(options.ui.roster.showProductList);
        }

      }

      if(options.ui && options.ui.channel) {
        if(typeof(options.ui.channel.showProductMetadata) === 'boolean') {
          config.setChannelShowProductMetadata(options.ui.channel.showProductMetadata);
        }
        if(typeof(options.ui.channel.showAttachFile) === 'boolean') {
          config.setChannelShowAttachFile(options.ui.channel.showAttachFile);
        }
        if(typeof(options.ui.channel.showVideoCall) === 'boolean') {
          config.setChannelShowVideoCall(options.ui.channel.showVideoCall);
        }
        if(typeof(options.ui.channel.showVoiceRecording) === 'boolean') {
          config.setChannelShowVoiceRecording(options.ui.channel.showVoiceRecording);
        }
      }

      if(options.ui && options.ui.translation){
        config.setTranslation(options.ui.translation)
      }

      if (process.env.NODE_ENV === 'production') {
        //add chatcamp css
        let link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        let theme = {}
        if(options.ui && options.ui.theme) {
          theme = options.ui.theme
        }
        let queryString = '?' + Object.keys(theme).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(theme[key])
        }).join('&');
        if(config.host === "api-staging.chatcamp.io") {
          link.href = "https://demo-staging.chatcamp.io/widget-example/chatcamp-ui.min.css"+queryString;
        }
        else {
          link.href = "https://cdn.chatcamp.io/css/chatcamp.min.css"+queryString;
        }
        document.head.appendChild(link);
        let cssMain = document.head.querySelector('link[href*="'+link.href+'"]');
        // let cssLoaded = cssMain ? cssMain.sheet.cssRules.length : false;
        cssMain.addEventListener('load', function () {
          // cssLoaded = true;
          ChatCampRenderInit(options)
        });
      }
      else {
        ChatCampRenderInit(options)
      }

      

      firstRun = false
    }

    else {
      console.error("Can't init more than once")
    }

  }
    
}
  
  


  
  // ReactDOM.render(<IFlyProvider client={client}>{RootComponent}</IFlyProvider>, document.getElementById('app'));

// }
// registerServiceWorker();

function renderApp(el, RootComponent) {
  let mobileClass = Utility.mobileCheck() ? 'cc-app-mobile' : 'cc-app-desktop';

  let innerApp = <div className={mobileClass}>{RootComponent}</div>

  ReactDOM.render(innerApp, el);

  window.addEventListener('resize', function(){
    let mobileClass = Utility.mobileCheck() ? 'cc-app-mobile' : 'cc-app-desktop';
    let innerApp = <div className={mobileClass}>{RootComponent}</div>
    // alert("heya" + mobileClass);
    ReactDOM.render(innerApp, el);
  })
}

function processInboxApps(options) {
  let embedAppDoms = document.querySelectorAll('[class="cc-inbox-app"]');

  if(embedAppDoms.length > 0){
    let embedAppDomId = 'cc-' + Utility.generateRandomId();
    initialState.smartChat = Immutable.Map({
      isSmartChatOpen: false,
      isSmartChatListOpen: false,
      // type: "inbox"
    })
  }
  for(let i = 0; i < embedAppDoms.length; i++) {
    let embedAppDomId = 'cc-' + Utility.generateRandomId();
    let embedAppHeight = embedAppDoms[i].getAttribute('data-height');
    let embedAppWidth = embedAppDoms[i].getAttribute('data-width');
    if(!embedAppHeight) {
      embedAppHeight = '500px'
    }
    if(!embedAppWidth) {
      embedAppWidth = '100%'
    }
    
    embedAppDoms[i].style.height = embedAppHeight;
    embedAppDoms[i].style.width = embedAppWidth;
    embedAppDoms[i].setAttribute("id", embedAppDomId);
    embedAppDoms[i].setAttribute("class", "cc-inbox-app cc-root");

    let store = configureStore(initialState);
    let RootComponent = configureRootComponent(store);
    
    if((options.user && options.user.id)){
      store.store.dispatch({
        type: CHAT_CONNECTING
      });
    }
    else{
      store.store.dispatch({
        type: CHAT_SSO_FALSE
      });
    }
    store.store.dispatch({
      type: SET_APP_TYPE,
      data: {
        type: "inbox"
      }
    });

    renderApp(embedAppDoms[i], RootComponent);
    
  }
}

function processRoomApps(options) {
  let embedAppDoms = document.querySelectorAll('[class="cc-live-discussion-app"]');
  for(let i = 0; i < embedAppDoms.length; i++) {
    let embedAppDomId = 'cc-' + Utility.generateRandomId();
    let embedAppHeight = embedAppDoms[i].getAttribute('data-height');
    let embedAppWidth = embedAppDoms[i].getAttribute('data-width');
    let embedAppChannelId = embedAppDoms[i].getAttribute('data-channel-id');
    let embedAppChannelType = embedAppDoms[i].getAttribute('data-channel-type');
    if(!embedAppHeight) {
      embedAppHeight = '500px'
    }
    if(!embedAppWidth) {
      embedAppWidth = '100%'
    }
    if(!embedAppChannelType) {
      embedAppChannelType = "group";
    }
    if(!embedAppChannelId) {
      embedAppChannelId = "";
    }
    
    embedAppDoms[i].style.height = embedAppHeight;
    embedAppDoms[i].style.width = embedAppWidth;
    embedAppDoms[i].setAttribute("id", embedAppDomId);
    embedAppDoms[i].setAttribute("class", "cc-room-app cc-root");

    let store = configureStore(initialState);
    let RootComponent = configureRootComponent(store);
    
    
    if((options.user && options.user.id)){
      store.store.dispatch({
        type: CHAT_CONNECTING
      });
    }
    else{
      store.store.dispatch({
        type: CHAT_SSO_FALSE
      });
    }

    store.store.dispatch({
      type: SET_APP_TYPE,
      data: {
        type: "embed",
        channelId: embedAppChannelId,
        channelType: embedAppChannelType
      }
    });

    renderApp(embedAppDoms[i], RootComponent);
    
  }
}

function processIMApps(options) {
  var el = document.getElementById('cc-app');
  if(el) {
    let store = configureStore(initialState);
    let RootComponent = configureRootComponent(store);
    el.className = 'cc-root'
    if(!(options.user && options.user.id)) {
      store.store.dispatch({
        type: CHAT_STATE_LOADED_SUCCESS
      });
      store.store.dispatch({
        type: CHAT_SSO_FALSE
      });
    }
    else{
      store.store.dispatch({
        type: CHAT_CONNECTING
      });
    }
    
    store.store.dispatch({
      type: SET_APP_TYPE,
      data: {
        type: "smartChat"
      }
    });
    
    renderApp(el, RootComponent);
  }
}

function processSideChatApps(options) {
  let sideChatAppDoms = document.querySelectorAll('[class="cc-side-chat-app"]');

  if(sideChatAppDoms.length > 0){
    // let sideChatAppDomId = 'cc-' + Utility.generateRandomId();
    initialState.smartChat = Immutable.Map({
      isSmartChatOpen: false,
      isSmartChatListOpen: false,
      // type: "inbox"
    })
  }
  for(let i = 0; i < sideChatAppDoms.length; i++) {
    let sideChatAppDomId = 'cc-' + Utility.generateRandomId();
    // let sideChatAppHeight = sideChatAppDoms[i].getAttribute('data-height');
    let sideChatAppWidth = sideChatAppDoms[i].getAttribute('data-width');
    // if(!sideChatAppHeight) {
    //   sideChatAppHeight = '500px'
    // }
    if(!sideChatAppWidth) {
      sideChatAppWidth = '370px'
    }
    
    // sideChatAppDoms[i].style.height = sideChatAppHeight;
    sideChatAppDoms[i].style.width = sideChatAppWidth;
    sideChatAppDoms[i].setAttribute("id", sideChatAppDomId);
    sideChatAppDoms[i].setAttribute("class", "cc-side-chat-app cc-root");

    let store = configureStore(initialState);
    let RootComponent = configureRootComponent(store);
    
    if((options.user && options.user.id)){
      store.store.dispatch({
        type: CHAT_CONNECTING
      });
    }
    else{
      store.store.dispatch({
        type: CHAT_SSO_FALSE
      });
    }
    store.store.dispatch({
      type: SET_APP_TYPE,
      data: {
        type: "sideChat"
      }
    });

    renderApp(sideChatAppDoms[i], RootComponent);
    
  }
}
