import { toast } from 'react-toastify'
import {
  GROUP_CHANNELS_ATTACHMENT_PROGRESS,
  GROUP_CHANNELS_ATTACHMENT_RESET,
	GROUP_CHANNELS_GET_SUCCESS,
  GROUP_CHANNELS_GET_HISTORY_SUCCESS,
  GROUP_CHANNELS_OPEN,
  GROUP_CHANNELS_CLOSE,
  GROUP_CHANNELS_GET_ERROR,
  GROUP_CHANNELS_INVITE_ACCEPTANCE_REQUIRED,
  GROUP_CHANNELS_INVALID_PARTICIPANT
} from 'state/action-types'

import client from 'Client'
import * as Debug from 'debug';
const debug = Debug('chatcamp:state:groupChannels')
export const getChannel = (groupChannelId) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error) {
      dispatch({
        type: GROUP_CHANNELS_GET_SUCCESS,
        groupChannel: groupChannel
      });
    }
  });
}

export const createChannel = (object) => dispatch => {
  client.GroupChannel.create(object.groupChannelName, object.groupParticipants, object.isDistinct, function(error, groupChannel) {
    if(!error) {
      dispatch({
        type: GROUP_CHANNELS_GET_SUCCESS,
        groupChannel: groupChannel
      });
      dispatch({
        type: GROUP_CHANNELS_OPEN,
        groupChannelsId: groupChannel.id
      })
      dispatch({
        type: GROUP_CHANNELS_GET_HISTORY_SUCCESS,
        groupChannel: groupChannel,
        messages: []
      });
    }
  });
}

export const userMessage = (groupChannelId, message) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){  
      groupChannel.stopTyping()
      groupChannel.sendMessage(message, function(error, message) {
        if(error){
          console.log(error)
          toast.dismiss()
          toast(error.message)
        }
        else{
          groupChannel.markAsRead()
        }
      })
    }
  })
}

export const actionMessage = (groupChannelId, message, product) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){  
      groupChannel.sendMessage(message, {product: JSON.stringify(product)}, "action_link", function(error, message) {
        if(error){
          toast.dismiss()
          toast(error.message)
        }
        else{
          groupChannel.markAsRead()
        }
      })
    }
  })
}

export const attachmentMessage = (groupChannelId, attachment) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){
      groupChannel.sendAttachment(attachment, function(bSent, bTotal) {
        let progress = Math.ceil((bSent * 100) / bTotal)
        dispatch({
          type: GROUP_CHANNELS_ATTACHMENT_PROGRESS,
          groupChannelId: groupChannelId,
          progress: progress
        });
      },function(error, message) {
        if(error){
          toast.dismiss()
          toast(error.message)
        }
        else{
          groupChannel.markAsRead()
        }
          dispatch({
            type: GROUP_CHANNELS_ATTACHMENT_RESET,
            groupChannelId: groupChannelId,
          });
      })
    }
  })
}

export const startTyping = (groupChannelId) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){
      groupChannel.startTyping()
    }
  })
}

export const read = (groupChannelId) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){
      groupChannel.markAsRead()
    }
  })
}

export const getHistory = (groupChannelId, messageId) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){
      let previousMessageListQuery = groupChannel.createPreviousMessageListQuery();
      previousMessageListQuery.setLimit(20)
      previousMessageListQuery.setReference(messageId)
      previousMessageListQuery.load(function(previousMessageListQueryError, messages) {
        dispatch({
          type: GROUP_CHANNELS_GET_HISTORY_SUCCESS,
          groupChannel: groupChannel,
          messages: messages
        });
      })
    }
  })
}

export const acceptInvitation = (groupChannelId) => dispatch => {
  client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
    if(!error){
      groupChannel.acceptInvitation(function(error) {
        if(!error) {
          window.location.reload()
          client.GroupChannel.get(groupChannelId, function(error, groupChannel) {
            if(!error){
              dispatch({
                type: GROUP_CHANNELS_GET_SUCCESS,
                groupChannel: groupChannel
              });
              dispatch(getHistory(groupChannelId, null))
            }
          });
        }
      })
    }
  })
}

export const addParticipants = (group) => dispatch => {
  client.GroupChannel.get(group.id, function(error, groupChannel) {
    groupChannel.inviteParticipants(group.groupParticipants, function(error) {
      if(!error) {
        client.GroupChannel.get(group.id, function(error, groupChannel) {
          dispatch({
            type: GROUP_CHANNELS_GET_SUCCESS,
            groupChannel: groupChannel
          });
        });
      }
    })
  })
}

export const leaveParticipant = (group) => dispatch => {
  client.GroupChannel.get(group.id, function(error, groupChannel) {
    if(!error){
      groupChannel.leave( function(error) {
        debug("errors leave",error)
        if(!error) {
          debug("not errors leave",error)
          dispatch({
            type: GROUP_CHANNELS_CLOSE,
            groupChannelsId: group.id
          })
        }
      })
    }
  })
}

export const syncGroup = (groupId) => dispatch => {
  client.GroupChannel.get(groupId, function(error, channel){
    if(!error){
      channel.sync(function(error, final){
        dispatch({
          type: GROUP_CHANNELS_GET_SUCCESS,
          groupChannel: final
        });
      })
    }
  })
}

export const groupChannelError = (id, error) => dispatch => {
    dispatch({
      type: GROUP_CHANNELS_GET_ERROR,
      error: error,
      groupChannelId: id
    });
}

export const groupChannelInviteAcceptanceRequired = (groupChannel) => dispatch => {
  dispatch({
    type: GROUP_CHANNELS_INVITE_ACCEPTANCE_REQUIRED,
    groupChannel: groupChannel
  });
}

export const groupChannelsInvalidParticipant = (groupChannel) => dispatch => {
  dispatch({
    type: GROUP_CHANNELS_INVALID_PARTICIPANT,
    groupChannel: groupChannel
  });
}
