import client from 'Client'
import { toast } from 'react-toastify'
import {
  USER_PUSH_NOTIFICATION_SOUND_UPDATE,
  USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE,
  USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE,
  USER_PUSH_NOTIFICATION_SNOOZE_UPDATE,
  USER_UPDATE,
  USER_LOGOUT
} from 'state/action-types'

export const uploadAvatar = (avatar) => dispatch => {
  client.uploadUserAvatar(avatar, (sent, total) => {
    console.log("uploading avqtar", sent, total, sent / total * 100);
  }, (error, user) => {
    if(!error) {
      dispatch({
        type: USER_UPDATE,
        user: user
      });
    }
  });
}

export const saveStatus = (status) => dispatch => {
  client.updateUserData(status, function(error, result){
    if(!error){
      dispatch({
        type: USER_UPDATE,
        user: result
      });
      toast.dismiss()
      toast('Settings successfully saved.')
    }
    else{
      toast.dismiss()
      toast('Error in saving settings.')
    }
  })
}

export const muteSoundToggle = (toggle) => dispatch => {
  let value 
  if(toggle){
    value = "OFF"
  }
  else{
    value = "DEFAULT"
  }
  client.updatePushNotificationSound(value, function(error){
    console.log(error)
    if(!error){
      dispatch({
        type: USER_PUSH_NOTIFICATION_SOUND_UPDATE,
        sound: value
      });
      toast.dismiss()
      toast('Settings successfully saved.')
    }
    else{
      toast.dismiss()
      toast('Error in saving settings.')
    }
  })
}

export const templateUpdate = (value) => dispatch => {
  client.updatePushNotificationTemplate(value, function(error){
    console.log(error)
    if(!error){
      dispatch({
        type: USER_PUSH_NOTIFICATION_TEMPLATE_UPDATE,
        template: value
      });
      toast.dismiss()
      toast('Settings successfully saved.')
    }
    else{
      toast.dismiss()
      toast('Error in saving settings.')
    }
  })
}

export const snoozeUpdate = (value) => dispatch => {
  client.updatePushNotificationSnooze(value, function(error){
    console.log(error)
    if(!error){
      dispatch({
        type: USER_PUSH_NOTIFICATION_SNOOZE_UPDATE,
        snooze: value
      });
      toast.dismiss()
      toast('Settings successfully saved.')
    }
    else{
      toast.dismiss()
      toast('Error in saving settings.')
    }
  })
}

export const dnd = (dnd, startDnd, endDnd, timezone) => dispatch => {
  let startDndHour =  Number(startDnd.split(":")[0])
  let startDndMin =  Number(startDnd.split(":")[1])
  let endDndHour = Number(endDnd.split(":")[0])
  let endDndMin = Number(endDnd.split(":")[1])
  let timezoneHour = (String(timezone)).split(".")[0]
  let temp = (String(timezone)).split(".")[1]
  let timezoneFinal
  if(temp !== undefined){
    let timezoneMin = Number((String(timezone)).split(".")[1]) * 6
    if(timezoneHour >= 0){
      timezoneFinal = "GMT+" + timezoneHour + ":" + timezoneMin
    }
    else{
      timezoneFinal = "GMT" + timezoneHour + ":" + timezoneMin
    }
    
  }
  else {
    if(timezoneHour >= 0){
      timezoneFinal = "GMT+" + timezoneHour + ":00" 
    }
    else{
      timezoneFinal = "GMT" + timezoneHour + ":00"
    }
  }
  
  client.updatePushNotificationPreference(dnd, startDndHour, startDndMin, endDndHour, endDndMin, timezoneFinal,function(error){
    if(!error){
      dispatch({
        type: USER_PUSH_NOTIFICATION_PREFERNCE_UPDATE,
        dnd: dnd,
        startDndHour: startDndHour,
        startDndMin: startDndMin,
        endDndHour: endDndHour,
        endDndMin: endDndMin,
        timezone: timezoneFinal
      });
      toast.dismiss()
      toast('Settings successfully saved.')
    }
    else{
      toast.dismiss()
      toast('Error in saving settings.')
    }
  })
}

export const markAsReadAll = () => dispatch => {
  client.markAsReadAll(function(error){
    if(!error){
      toast.dismiss()
      toast('All conversations marked as read.')
    }
    else{
      toast.dismiss()
      toast('Something went wrong.')
    }
  })
}

export const userLogout = () => dispatch => {
  dispatch({
      type: USER_LOGOUT
  })
  return Promise.resolve()
}
