import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from 'state/groupChannelsState/actions'
import Toast from 'containers/ChatApp/Components/Toast'
import Window from 'containers/ChatApp/Components/Window'
import LeftPanel from 'containers/ChatApp/Components/LeftPanel'
import NoOpenWindow from 'containers/ChatApp/Components/NoOpenWindow'
import Authenticating from 'containers/ChatApp/Components/Authenticating'
import Reconnecting from 'containers/ChatApp/Components/Reconnecting'
import LoginScreen from 'containers/ChatApp/Components/LoginScreen'
import LoginSsoScreen from 'containers/ChatApp/Components/LoginSsoScreen'
import BlockAlert from 'containers/ChatApp/Components/BlockAlert'


class Inbox extends Component {

  render () {
    let windows = [];
    let number = 0;
    let currentNode = ReactDOM.findDOMNode(this)
    let inboxWidth = 0
    if(currentNode){
      inboxWidth = currentNode.clientWidth
    }
    
    if(this.props.auth.get('connectionState') === "INITIAL"){
      if(!this.props.auth.get('isSSO')){
        windows.push(<LoginScreen key="login-screen"/>)
      }
    }
    else if(this.props.auth.get('connectionState') === "DISCONNECTED"){
      if(!this.props.auth.get('isSSO')){
        windows.push(<LoginScreen key="login-screen"/>)
      }
      else{
        windows.push(<LoginSsoScreen key="login-sso-screen"/>)
      }
    }
    else if(this.props.auth.get('connectionState') === "CONNECTING"){
      windows.push(<Authenticating key="chat-authenticating"/>)
    }
    else{
      let id = this.props.groupChannelsState.keySeq().toArray()[0]
      let type = this.props.groupChannelsState.getIn([id, "type"])

      if(inboxWidth >= 2*370){
        windows.push(<LeftPanel key={"window-left"}/>)
        if(id !== undefined ){
          windows.push(<Window key={"window-" + id} id={id} type={type} position = {number} state={"open"}/>)
        }
        else{
          windows.push(<NoOpenWindow key={"window-empty"}/>)
        }
      }
      else if(inboxWidth < 2*370){
        if(id === undefined ){
          windows.push(<LeftPanel className={"cc-inbox-app-small"} key={"window-left"}/>)
        }
        else{
          windows.push(<Window className={"cc-inbox-app-small"} key={"window-" + id} id={id} type={type} position = {number} state={"open"}/>)
        }
      }

      // if(id === undefined ){
      //   windows.push(<LeftPanel key={"window-left"}/>)
      // }
      // if(id !== undefined ){
      //   if(inboxWidth > 2*370){
      //     windows.push(<Window key={"window-" + id} id={id} type={type} position = {number} state={"open"}/>)
      //   }
      // }
      // else{
      //   windows.push(<NoOpenWindow key={"window-empty"}/>)
      // }
    }

    {this.props.auth.get('connectionState') === "RECONNECTING" && windows.push(<Reconnecting key="reconnecting"/>)}
    

    return (
      <div id="ifc-app" className="cc-inbox">
        {windows}
        <Toast />
        <BlockAlert />
      </div>
    )
  }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch) //binds all the actions with dispatcher and returns them
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inbox)