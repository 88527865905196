import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from 'config'
import {Loader} from 'react-loaders'
import * as Debug from 'debug'

const debug = Debug('chatcamp:LeftAuthenticating')

class Authenticating extends Component {

    render () {

        return (
            <div className="cc-authenticating">
                <div className="cc-authenticating-inner">
                    <span className="cc-authenticating-header">{config.getTranslation().authenticating}</span>
                    <br />
                    <Loader className="cc-authenticating-loader" type="ball-beat" color="#222" size="sm" />
                </div>
            
            </div>
        )
    }

}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticating)
